import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AD_VIEW, CLIENT_ID, ASSETS, CONCEPT_ID } from 'utils/routingUtils';
import { ModalContext, ModalContextProps } from 'contextProviders/ModalProvider';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { deCapitalizeFirstLetter, getObjectUrlParams } from 'utils/stringUtils';
import { useTrackedSelector } from 'components/app/store';
import { AdSearchCriteria, OrderBy, SearchPaging, Sorting } from 'types/inputTypes';
import {
  Ad,
  AdCommentor,
  AdMedia,
  AdMediaMarker,
  Ads,
  AdState,
  Brief,
  AdConcept,
  AdConceptAndBrief,
} from 'types/businessTypes.d';
import {
  getAdCommentors,
  getAdConceptsAndBriefs,
  getAdContentAsBlob,
  getAds,
  getApprovedAdsContentAsBlob,
} from 'graphql/businessQueries';
import CreateAdConceptModal from './createProjectModal';
import UpdateAdConceptModal from './updateProjectModal';
import CreateAdModal from './createAd/createAdModal';
import { toast } from 'react-toastify';
import { Client, Clients, ClientTeamMember, PgTeamMember, ProjectViewLayout, SecurityRole, User } from 'types/types.d';
import { getAllUsers, getClients, getClientTeamMembers, getPgTeamMembers } from 'graphql/queries';
import { userHasAnyRole, userHasClientUserRole, userHasPgUserRole } from 'utils/userUtils';
import { buildBriefUrl } from '../brief/BriefProvider';
import { baseErrorNotification, baseInfoNotification } from 'utils/notificationUtils';
import logger from 'utils/logger/logger';
import { useTranslation } from 'react-i18next';
import { downloadAd } from 'utils/businessUtils';
import { base64ToUrl, createAndDownloadBlobFile } from 'utils/fileUtils';
import { ContentType } from 'types/contentTypes';
import { addAdVideoMarker, moveAllApprovedAdsToLaunched, updateAd } from 'graphql/businessMutations';
import DeleteAdConceptModal from 'components/features/private/dashboard/deleteProjectModal';
import ToasterInfo from 'components/common/toasterInfo';
import { getDomain, reloadPage } from 'utils/windowUtils';
import orderBy from 'lodash/orderBy';
import { getContactUsMessage } from 'utils/i18nUtils';
import CreateAdModeSelectionModal from 'components/features/private/dashboard/createAd/createAdModeSelectionModal/CreateAdModeSelectionModal';
import CreateAdModalWithSizes from 'components/features/private/dashboard/createAd/createAdWithSizesModal';
import useKeydownListener from 'hooks/useKeydownListener';
import { updateProjectViewLayout } from 'graphql/mutations';
import { projectViewLayoutSet } from 'slices/authSlice';
import { useDispatch } from 'react-redux';

export const DashboardContext = createContext({});

export const filtersInitialState = {
  [SearchParams.CLIENT_ID]: '',
  [SearchParams.CONCEPT_ID]: '',
  [SearchParams.FILTER]: '',
  [SearchParams.INTERNAL]: '',
};

export const buildDashboardUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.CONCEPT_ID]?: string | null;
  [SearchParams.FILTER]?: string | null;
  [SearchParams.INTERNAL]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.CONCEPT_ID]: input[SearchParams.CONCEPT_ID] || filtersInitialState[SearchParams.CONCEPT_ID],
    [SearchParams.FILTER]: input[SearchParams.FILTER] || filtersInitialState[SearchParams.FILTER],
    [SearchParams.INTERNAL]: input[SearchParams.INTERNAL] || filtersInitialState[SearchParams.INTERNAL],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${ASSETS}?${urlParams}`;
};

export const buildAdViewUrl = (
  adId: string,
  input: {
    [SearchParams.CLIENT_ID]?: string | null;
    [SearchParams.CONCEPT_ID]?: string | null;
  },
): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.CONCEPT_ID]: input[SearchParams.CONCEPT_ID] || filtersInitialState[SearchParams.CONCEPT_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${AD_VIEW}/${adId}?${urlParams}`;
};

export interface DashboardContextProps {
  loading: boolean;
  launchingAd: boolean;
  error: boolean;

  clients: Client[];
  adConcepts: AdConcept[];

  adConcept: AdConcept | null;
  brief: Brief | null;
  ads: Ad[];
  deletedAds: Ad[];
  adCommentors: AdCommentor[];

  users: User[];

  selectedClientId: string;
  onClientSelected: (clientId: string, fromEditor?: boolean) => void;

  selectedAdConceptId: string;
  onAdConceptSelected: (adConceptId: string, clientId: string, fromEditor?: boolean) => void;

  selectedAdState: AdState | null;
  setSelectedAdState: (value: AdState | null) => void;

  projectViewLayout: ProjectViewLayout;
  onProjectViewLayoutChange: (value: ProjectViewLayout) => void;

  onCreateAdConcept: () => void;
  onUpdateAdConcept: () => void;
  onDeleteAdConcept: () => void;

  onCreateBrief: () => void;
  onOpenBrief: () => void;

  onCreateAd: () => void;
  fetchAds: () => void;
  fetchDeletedAds: () => void;
  fetchAdCommentors: () => void;
  onDownloadAd: (ad: Ad) => void;
  onDownloadApprovedAds: () => void;
  onMoveAllToLaunched: () => void;
  onMarkAsLaunched: (adId: string, pathToAd: string) => void;

  adsOrderBy: OrderBy;
  setAdsOrderBy: (value: OrderBy) => void;

  internal: boolean | null;
  setInternal: (value: boolean | null) => void;

  setCurrentAd: (value: Ad | null) => void;
  currentAd: Ad | null;
  setVideoMarkers: (value: AdMediaMarker[]) => void;
  videoMarkers: AdMediaMarker[];
  newVideoMarker: AdMediaMarker | null;
  setNewVideoMarker: (value: AdMediaMarker | null) => void;
  onAddVideoMarker: (ad: Ad, media: AdMedia, marker: AdMediaMarker) => void;
  onAddingAdComment: (value: boolean) => void;
  clientTeamMembers: ClientTeamMember[];
  pgTeamMembers: ClientTeamMember[];
}

const DashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    auth: { user, projectViewLayout: projectViewLayoutFromRedux, whitelabel },
  } = useTrackedSelector();

  const {
    searchParams: {
      [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID],
      [SearchParams.CONCEPT_ID]: _conceptId = filtersInitialState[SearchParams.CONCEPT_ID],
      [SearchParams.FILTER]: _filter = filtersInitialState[SearchParams.FILTER],
      [SearchParams.INTERNAL]: _internal = filtersInitialState[SearchParams.INTERNAL],
    },
  } = useCustomLocation();

  // id of an Ad
  const { id } = useParams();

  const [
    doFetchAdConceptsAndBriefs,
    { data: conceptsAndBriefsData, loading: conceptsAndBriefsLoading, error: conceptsAndBriefsError },
  ] = useLazyQuery<{
    getAdConceptsAndBriefs: AdConceptAndBrief[];
  }>(getAdConceptsAndBriefs);
  const [doFetchAds, { data: adsData, loading: adsLoading, error: adsError }] = useLazyQuery<{
    getAds: Ads;
  }>(getAds);
  const [doFetchDeletedAds, { data: deletedAdsData }] = useLazyQuery<{
    getAds: Ads;
  }>(getAds);
  const [doFetchClients, { data: clientsData, loading: clientsLoading, error: clientsError }] = useLazyQuery<{
    getClients: Clients;
  }>(getClients);
  const [doGetClientTeamMembers] = useLazyQuery<{ getClientTeamMembers: ClientTeamMember[] }>(getClientTeamMembers);
  const [doGetPgTeamMembers] = useLazyQuery<{ getPgTeamMembers: PgTeamMember[] }>(getPgTeamMembers);
  const [doFetchAdCommentors, { data: adCommentorsData }] = useLazyQuery<{
    getAdCommentors: AdCommentor[];
  }>(getAdCommentors);
  const [doDownloadAd] = useLazyQuery<{ getAdContentAsBlob?: string }>(getAdContentAsBlob);
  const [doDownloadApprovedAds] = useLazyQuery<{ getApprovedAdsContentAsBlob?: string }>(getApprovedAdsContentAsBlob);
  const [doAddVideoMarker] = useMutation<{ addAdVideoMarker?: Ad }>(addAdVideoMarker);
  const [doMoveAllApprovedAdsToLaunched] = useMutation<{ moveAllApprovedAdsToLaunched: number }>(
    moveAllApprovedAdsToLaunched,
  );
  const [doUpdateAd] = useMutation<{ updateAd?: Ad }>(updateAd);
  const [doUpdateProjectViewLayout] = useMutation<{ updateProjectViewLayout?: User }>(updateProjectViewLayout);
  const [doFetchUsers, { data: usersData }] = useLazyQuery<{ getAllUsers?: User[] }>(getAllUsers);

  const { openModal, toggleModal } = useContext(ModalContext) as ModalContextProps;

  const [initialLoading, setInitialLoading] = useState(true);
  const [launchingAd, setLaunchingAd] = useState(false);
  const [selectedAdConceptId, setSelectedAdConceptId] = useState(_conceptId || '');
  const [selectedClientId, setSelectedClientId] = useState<string>(_clientId || user?.clientId || '');
  const [selectedAdState, setSelectedAdState] = useState<AdState | null>(null);
  const [adsOrderBy, setAdsOrderBy] = useState(OrderBy.TitleAsc);
  const [internal, setInternal] = useState<boolean | null>(null);

  const [currentAd, setCurrentAd] = useState<Ad | null>(null);
  const [videoMarkers, setVideoMarkers] = useState<AdMediaMarker[]>([]);
  const [newVideoMarker, setNewVideoMarker] = useState<AdMediaMarker | null>(null);
  const [addingAdComment, setAddingAdComment] = useState(false);

  const [clientTeamMembers, setClientTeamMembers] = useState<ClientTeamMember[]>([]);
  const [pgTeamMembers, setPgTeamMembers] = useState<PgTeamMember[]>([]);

  const [projectViewLayout, setProjectViewLayout] = useState(projectViewLayoutFromRedux);

  const users = useMemo(() => usersData?.getAllUsers || [], [usersData?.getAllUsers]);
  const finalWhitelabel = useMemo(() => whitelabel?.id, [whitelabel?.id]);

  const isPgUser = useMemo(() => userHasPgUserRole(user), [user]);
  const isPgUserOrCreator = useMemo(() => userHasAnyRole(user, [SecurityRole.PG_USER, SecurityRole.CREATOR]), [user]);
  const isClientUser = useMemo(() => userHasClientUserRole(user), [user]);

  const { adConcepts, briefsByConcept } = useMemo(() => {
    const adConcepts =
      conceptsAndBriefsData?.getAdConceptsAndBriefs
        ?.map((i) => i.adConcept)
        .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1)) || [];
    const briefs: Brief[] = [];
    conceptsAndBriefsData?.getAdConceptsAndBriefs?.forEach((i) => {
      if (i.brief) briefs.push(i.brief);
    });
    if (conceptsAndBriefsData?.getAdConceptsAndBriefs?.length === 0) setInitialLoading(false);

    return {
      adConcepts,
      briefsByConcept: new Map(briefs.map((i) => [i.projectId, i])),
    };
  }, [conceptsAndBriefsData?.getAdConceptsAndBriefs]);

  const adConcept = useMemo(() => {
    const selectedConcept = adConcepts.find(({ id }) => selectedAdConceptId === id);
    if (selectedConcept) {
      return selectedConcept;
    } else if (!!adConcepts.length) {
      setSelectedAdConceptId(adConcepts[0].id);
    }
  }, [adConcepts, selectedAdConceptId]);
  const brief = useMemo(() => briefsByConcept.get(selectedAdConceptId) || null, [briefsByConcept, selectedAdConceptId]);
  const ads = useMemo(() => {
    const orderValue = deCapitalizeFirstLetter(adsOrderBy.replace('Asc', '').replace('Desc', '')) || '';
    const direction = adsOrderBy.endsWith('Asc') ? 'asc' : 'desc';
    const items = orderBy(adsData?.getAds?.items || [], orderValue, direction);

    return isClientUser || (isPgUser && !!finalWhitelabel?.length)
      ? items
          // show only ads that
          // 1) are existing ones w/o any history or
          // 2) were at least ones in the external state
          // 3) have external state now
          .filter(
            ({ history, internalState }) =>
              !history.length || !!history.find((event) => event.internalState == false) || internalState === false,
          )
          // show all ads that are "work in progress" as "edits requested"
          .map((i) => {
            if (i.internalState == true) i.state = AdState.EDIT_REQUESTED;
            return i;
          })
      : items.filter((i) => {
          // additional filtering for internal state because not all ads may have been initialised with it
          if (internal === null) return true; // all
          if (!internal) return i.internalState !== true; // only external
          if (internal) return i.internalState == true; // only internal
        });
  }, [adsData?.getAds?.items, adsOrderBy, finalWhitelabel?.length, internal, isClientUser, isPgUser]);

  const deletedAds = useMemo(() => {
    const orderValue = deCapitalizeFirstLetter(adsOrderBy.replace('Asc', '').replace('Desc', '')) || '';
    const direction = adsOrderBy.endsWith('Asc') ? 'asc' : 'desc';
    const items = orderBy(deletedAdsData?.getAds?.items || [], orderValue, direction);

    return isClientUser || (isPgUser && !!finalWhitelabel?.length)
      ? items
          // show only ads that
          // 1) are existing ones w/o any history or
          // 2) were at least ones in the external state
          // 3) have external state now
          .filter(
            ({ history, internalState }) =>
              !history.length || !!history.find((event) => event.internalState == false) || internalState === false,
          )
          // show all ads that are "work in progress" as "edits requested"
          .map((i) => {
            if (i.internalState == true) i.state = AdState.EDIT_REQUESTED;
            return i;
          })
      : items.filter((i) => {
          // additional filtering for internal state because not all ads may have been initialised with it
          if (internal === null) return true; // all
          if (!internal) return i.internalState !== true; // only external
          if (internal) return i.internalState == true; // only internal
        });
  }, [adsOrderBy, deletedAdsData?.getAds?.items, finalWhitelabel?.length, internal, isClientUser, isPgUser]);

  const adCommentors = useMemo(() => adCommentorsData?.getAdCommentors || [], [adCommentorsData?.getAdCommentors]);

  const clients = useMemo(() => {
    const items = clientsData?.getClients?.items || [];
    if (!selectedClientId && !!items.length) setSelectedClientId(items[0].id);
    return items;
  }, [clientsData?.getClients?.items, selectedClientId]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: selectedClientId || _clientId || filtersInitialState[SearchParams.CLIENT_ID],
        [SearchParams.CONCEPT_ID]: selectedAdConceptId || _conceptId || filtersInitialState[SearchParams.CONCEPT_ID],
        [SearchParams.FILTER]: _filter || filtersInitialState[SearchParams.FILTER],
        [SearchParams.INTERNAL]: _internal || filtersInitialState[SearchParams.INTERNAL],
      }),
    [_clientId, _filter, _internal, _conceptId, selectedClientId, selectedAdConceptId],
  );

  const showInfoNotification = useCallback((message: string) => {
    toast.info(<ToasterInfo type="info" description={message} />, { ...baseInfoNotification });
  }, []);
  const showErrorNotification = useCallback(() => {
    const message = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const onHide = useCallback(() => toggleModal(false), [toggleModal]);

  const fetchConceptsAndBriefs = useCallback(
    (clientId?: string) => {
      if (user) {
        doFetchAdConceptsAndBriefs({
          variables: {
            clientId: clientId || user?.clientId || selectedClientId,
          },
        });
      }
    },
    [doFetchAdConceptsAndBriefs, selectedClientId, user],
  );

  const fetchAds = useCallback(() => {
    if (user) {
      const adConcept = adConcepts.find(({ id }) => id === selectedAdConceptId);
      if (adConcept) {
        doFetchAds({
          variables: {
            sorting: { orderBy: OrderBy.TitleAsc } as Sorting,
            paging: { page: 0, perPage: 300 } as SearchPaging,
            searchCriteria: {
              projectId: adConcept.id,
              internalState: internal,
            } as AdSearchCriteria,
          },
        });
        setInitialLoading(false);
      }
    }
  }, [doFetchAds, internal, adConcepts, selectedAdConceptId, user]);

  const fetchDeletedAds = useCallback(() => {
    if (user) {
      const adConcept = adConcepts.find(({ id }) => id === selectedAdConceptId);
      if (adConcept) {
        doFetchDeletedAds({
          variables: {
            sorting: { orderBy: OrderBy.TitleAsc } as Sorting,
            paging: { page: 0, perPage: 300 } as SearchPaging,
            searchCriteria: {
              projectId: adConcept.id,
              internalState: internal,
              deleted: true,
            } as AdSearchCriteria,
          },
        });
      }
    }
  }, [doFetchDeletedAds, internal, adConcepts, selectedAdConceptId, user]);

  const fetchAdCommentors = useCallback(() => {
    if (id) {
      doFetchAdCommentors({ variables: { id } });
    }
  }, [doFetchAdCommentors, id]);

  const onCreateBrief = useCallback(
    (newConcept?: AdConcept) => {
      const id = newConcept?.id || adConcept?.id;
      const ugc = newConcept?.ugc || adConcept?.ugc;
      const paymentModel = newConcept?.paymentModel || adConcept?.paymentModel;
      if (id) {
        navigate(buildBriefUrl({ [SearchParams.CLIENT_ID]: selectedClientId, [SearchParams.CONCEPT_ID]: id }), {
          state: { paymentModel, ugc },
        });
      }
    },
    [adConcept?.id, adConcept?.ugc, adConcept?.paymentModel, navigate, selectedClientId],
  );

  const onOpenBrief = useCallback(() => {
    if (!adConcept) return;
    navigate(
      buildBriefUrl({
        [SearchParams.CLIENT_ID]: selectedClientId,
        [SearchParams.CONCEPT_ID]: adConcept.id,
        [SearchParams.BRIEF_ID]: adConcept.briefId,
      }),
    );
  }, [navigate, adConcept, selectedClientId]);

  const onAdConceptSelected = useCallback(
    (adConceptId: string, clientId: string, fromEditor = false) => {
      if (selectedAdConceptId === adConceptId) return;

      setSelectedClientId(clientId);
      setSelectedAdConceptId(adConceptId);
      if (!fromEditor)
        navigate(
          buildDashboardUrl({
            [SearchParams.CLIENT_ID]: clientId,
            [SearchParams.CONCEPT_ID]: adConceptId,
            [SearchParams.FILTER]: '',
            [SearchParams.INTERNAL]: '',
          }),
        );
    },
    [navigate, selectedAdConceptId],
  );

  const onProjectViewLayoutChange = useCallback(
    (layout: ProjectViewLayout) => {
      setProjectViewLayout(layout);

      if (projectViewLayoutFromRedux !== layout) {
        dispatch(projectViewLayoutSet(layout));
        doUpdateProjectViewLayout({ variables: { layout } })
          .then((result) => {
            if (!result?.data?.updateProjectViewLayout) {
              logger.error(`Error updating layout`);
            }
          })
          .catch((e) => {
            logger.error(`Error updating layout: ${e}`);
          });
      }
    },
    [dispatch, doUpdateProjectViewLayout, projectViewLayoutFromRedux],
  );

  const onCreateAdConcept = useCallback(() => {
    openModal({
      modalContent: (
        <CreateAdConceptModal
          adConcepts={adConcepts}
          onHide={onHide}
          onDone={(adConcept: AdConcept) => {
            fetchConceptsAndBriefs();
            setSelectedAdConceptId(adConcept.id);
            onHide();
          }}
          onBriefRequired={(adConcept: AdConcept) => {
            onCreateBrief(adConcept);
            onHide();
          }}
          client={clients.find(({ id }) => id === selectedClientId)}
        />
      ),
      onHide,
    });
  }, [openModal, adConcepts, onHide, clients, fetchConceptsAndBriefs, onCreateBrief, selectedClientId]);

  const onDeleteAdConcept = useCallback(() => {
    if (!adConcept) return;

    openModal({
      modalContent: (
        <DeleteAdConceptModal
          adConcept={adConcept}
          onHide={onHide}
          onDone={() => {
            if (!!adConcepts.length && !!selectedAdConceptId?.length) {
              const anotherAdConcept = adConcepts.find(({ id }) => id !== adConcept.id);
              if (anotherAdConcept) onAdConceptSelected(anotherAdConcept.id, anotherAdConcept.clientId);
              reloadPage();
            }
            onHide();
          }}
        />
      ),
      onHide,
    });
  }, [adConcept, openModal, onHide, adConcepts, selectedAdConceptId?.length, onAdConceptSelected]);

  const onUpdateAdConcept = useCallback(() => {
    if (!adConcept) return;

    openModal({
      modalContent: (
        <UpdateAdConceptModal
          adConcepts={adConcepts}
          adConcept={adConcept}
          onHide={onHide}
          onDone={() => {
            fetchConceptsAndBriefs();
            onHide();
          }}
          onDelete={() => onDeleteAdConcept()}
        />
      ),
      onHide,
    });
  }, [adConcept, openModal, adConcepts, onHide, fetchConceptsAndBriefs, onDeleteAdConcept]);

  const onCreateAdWithoutSizes = useCallback(() => {
    if (!adConcept || !_clientId) return;

    openModal({
      modalContent: (
        <CreateAdModal
          clientId={_clientId}
          projectId={adConcept.id}
          onHide={onHide}
          onDone={() => {
            fetchAds();
            onHide();
          }}
        />
      ),
      onHide,
    });
  }, [adConcept, _clientId, openModal, onHide, fetchAds]);

  const onCreateAdWithSizes = useCallback(() => {
    if (!adConcept || !_clientId) return;

    openModal({
      modalContent: (
        <CreateAdModalWithSizes
          clientId={_clientId}
          adConceptId={adConcept.id}
          onHide={onHide}
          onDone={() => {
            fetchAds();
            onHide();
          }}
        />
      ),
      onHide,
    });
  }, [adConcept, _clientId, openModal, onHide, fetchAds]);

  const onCreateAd = useCallback(() => {
    if (!adConcept || !_clientId) return;

    openModal({
      modalContent: (
        <CreateAdModeSelectionModal
          onHide={onHide}
          onWithSizes={onCreateAdWithSizes}
          onWithoutSizes={onCreateAdWithoutSizes}
        />
      ),
      onHide,
    });
  }, [adConcept, _clientId, openModal, onHide, onCreateAdWithSizes, onCreateAdWithoutSizes]);

  const onClientSelected = useCallback(
    (clientId: string, fromEditor = false) => {
      setSelectedClientId(clientId);
      if (!fromEditor) {
        // setSelectedClientId('');
        setSelectedAdConceptId('');
        navigate(
          buildDashboardUrl({
            [SearchParams.CLIENT_ID]: clientId,
            [SearchParams.CONCEPT_ID]: '',
            [SearchParams.FILTER]: '',
            [SearchParams.INTERNAL]: '',
          }),
        );
      }
    },
    [navigate],
  );

  const onDownloadAd = useCallback(
    (ad: Ad) => {
      doDownloadAd({ variables: { id: ad.id } })
        .then((result) => {
          if (!!result?.data?.getAdContentAsBlob?.length) {
            downloadAd(ad, result.data.getAdContentAsBlob);
          } else {
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error downloading ad: ${e}`);
          showErrorNotification();
        });
    },
    [doDownloadAd, showErrorNotification],
  );

  const onDownloadApprovedAds = useCallback(() => {
    showInfoNotification(t('PRIVATE.USER.DASHBOARD.CONCEPT_VIEW.DOWNLOADING_ADS'));

    doDownloadApprovedAds({ variables: { projectId: _conceptId } })
      .then((result) => {
        if (!!result?.data?.getApprovedAdsContentAsBlob?.length) {
          createAndDownloadBlobFile(
            false,
            base64ToUrl(result?.data?.getApprovedAdsContentAsBlob, ContentType.APPLICATION_ZIP),
            `${adConcept?.title} - approved ads.zip`,
          );
        } else {
          showErrorNotification();
        }
      })
      .catch((e) => {
        logger.error(`Error downloading approved ads: ${e}`);
        showErrorNotification();
      });
  }, [_conceptId, doDownloadApprovedAds, adConcept?.title, showErrorNotification, showInfoNotification, t]);

  const onMoveAllToLaunched = useCallback(() => {
    doMoveAllApprovedAdsToLaunched({ variables: { projectId: selectedAdConceptId, domain: getDomain() } })
      .then((result) => {
        showInfoNotification(
          t('PRIVATE.USER.DASHBOARD.CONCEPT_VIEW.MOVED_ALL_APPROVED_TO_LAUNCHED', {
            count: result?.data?.moveAllApprovedAdsToLaunched || 0,
          }),
        );
        if (!!result?.data?.moveAllApprovedAdsToLaunched) {
          fetchAds();
        }
      })
      .catch((e) => {
        logger.error(`Error downloading approved ads: ${e}`);
        showErrorNotification();
      });
  }, [doMoveAllApprovedAdsToLaunched, fetchAds, selectedAdConceptId, showErrorNotification, showInfoNotification, t]);

  const onMarkAsLaunched = useCallback(
    (adId: string, pathToAd: string) => {
      setLaunchingAd(true);
      doUpdateAd({ variables: { id: adId, state: AdState.LAUNCHED, link: `${getDomain()}/#${pathToAd}` } })
        .then((result) => {
          if (result?.data?.updateAd) {
            fetchAds();
          }
        })
        .catch((e) => {
          logger.error(`Error downloading approved ads: ${e}`);
          showErrorNotification();
        })
        .finally(() => {
          setLaunchingAd(false);
        });
    },
    [doUpdateAd, fetchAds, showErrorNotification],
  );

  const onAddVideoMarker = useCallback(
    (ad: Ad, media: AdMedia, marker: AdMediaMarker) => {
      doAddVideoMarker({
        variables: {
          adId: ad.id,
          versionId: ad.versions[0].id,
          imageKitName: media.imageKitName,
          marker: marker,
        },
      }).then((result) => {
        const ad = result?.data?.addAdVideoMarker;
        if (ad) {
          setVideoMarkers(
            ad.versions[0].medias.find(({ imageKitName }) => imageKitName === media.imageKitName)?.videoMarkers || [],
          );
        }
      });
    },
    [doAddVideoMarker],
  );

  const getPathToAd = useCallback(
    (adId: string) => `/${AD_VIEW}/${adId}?${CLIENT_ID}=${selectedClientId}&${CONCEPT_ID}=${selectedAdConceptId}`,
    [selectedClientId, selectedAdConceptId],
  );

  const onKeydownArrowLeft = useCallback(() => {
    if (!currentAd) return;
    const currentAdIndex = ads.findIndex((i) => i.id === currentAd.id);
    if (currentAdIndex > 0) navigate(getPathToAd(ads[currentAdIndex - 1].id));
  }, [ads, currentAd, getPathToAd, navigate]);

  const onKeydownArrowUp = useCallback(() => {
    if (!currentAd) return;
    const currentAdIndex = ads.findIndex((i) => i.id === currentAd.id);
    if (currentAdIndex < ads.length - 1) navigate(getPathToAd(ads[currentAdIndex + 1].id));
  }, [ads, currentAd, getPathToAd, navigate]);

  const onAddingAdComment = useCallback((value: boolean) => {
    setAddingAdComment(value);
  }, []);

  // initial ad concepts fetch
  useEffect(() => fetchConceptsAndBriefs(selectedClientId), [fetchConceptsAndBriefs, selectedClientId]);

  // initially, select 1st in the list ad concept
  useEffect(() => {
    if (!!adConcepts.length && !selectedAdConceptId?.length) setSelectedAdConceptId(adConcepts[0].id);
  }, [adConcepts, selectedAdConceptId?.length]);

  // select new ads when the ad concept changed
  useEffect(() => {
    if (!!selectedAdConceptId?.length) {
      fetchAds();
      fetchDeletedAds();
    }
  }, [fetchAds, fetchDeletedAds, navigate, selectedClientId, selectedAdConceptId, selectedAdConceptId?.length]);

  // fetch all clients if it's a PG user
  useEffect(() => {
    if (isPgUserOrCreator) {
      doFetchClients({
        variables: {
          sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
          paging: { page: 0, perPage: 100 } as SearchPaging,
        },
      });
    }
  }, [doFetchClients, isPgUserOrCreator]);

  // fetch commentors of an ad from the URL
  useEffect(() => {
    fetchAdCommentors();
  }, [fetchAdCommentors]);

  // fetch those who can be "mentioned" in the comments
  useEffect(() => {
    if ((isPgUser || isClientUser) && !!selectedClientId?.length) {
      doGetClientTeamMembers({ variables: { clientId: selectedClientId } }).then((result) => {
        setClientTeamMembers(result?.data?.getClientTeamMembers || []);
      });
    }
  }, [doGetClientTeamMembers, isClientUser, isPgUser, selectedClientId]);

  // fetch those who can be "mentioned" in the comments
  useEffect(() => {
    if ((isPgUser || isClientUser) && !!selectedClientId?.length) {
      doGetPgTeamMembers().then((result) => {
        setPgTeamMembers(result?.data?.getPgTeamMembers || []);
      });
    }
  }, [doGetPgTeamMembers, isClientUser, isPgUser, selectedClientId]);

  useEffect(() => {
    !id && navigate(`/${ASSETS}?${urlParams}`, { replace: true });
  }, [navigate, urlParams, id]);

  useKeydownListener(
    {
      arrowleft: onKeydownArrowLeft,
      arrowright: onKeydownArrowUp,
    },
    !pathname.startsWith(`/${AD_VIEW}`) || addingAdComment,
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        await doFetchUsers();
      } catch (error) {
        logger.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [doFetchUsers]);

  const value = {
    loading: initialLoading || conceptsAndBriefsLoading || adsLoading || clientsLoading,
    launchingAd: launchingAd,
    error: !!conceptsAndBriefsError || !!adsError || !!clientsError,
    clients,
    adConcepts,
    adConcept,
    brief,
    ads,
    deletedAds,
    adCommentors,
    users,
    selectedClientId,
    onClientSelected,
    selectedAdConceptId,
    onAdConceptSelected,
    selectedAdState,
    setSelectedAdState,
    projectViewLayout,
    onProjectViewLayoutChange,

    onCreateAdConcept,
    onUpdateAdConcept,
    onDeleteAdConcept,

    onCreateBrief,
    onOpenBrief,

    onCreateAd,
    fetchAds,
    fetchDeletedAds,
    fetchAdCommentors,
    onDownloadAd,
    onDownloadApprovedAds,
    onMoveAllToLaunched,
    onMarkAsLaunched,

    adsOrderBy,
    setAdsOrderBy,

    internal,
    setInternal,

    setCurrentAd,
    currentAd,
    setVideoMarkers,
    videoMarkers,
    newVideoMarker,
    setNewVideoMarker,
    onAddVideoMarker,
    onAddingAdComment,
    clientTeamMembers,
    pgTeamMembers,
  } as DashboardContextProps;

  console.log(value);
  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export default DashboardProvider;
