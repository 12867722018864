/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getFullName } from 'utils/userUtils';
import {
  getLocalisedClientPaymentModel,
  getLocalisedCtaPlacement,
  getLocalisedLanguage,
  getLocalisedSocialMediaChannel,
} from 'utils/i18nUtils';
import { AdDna, AdDnaVisualFeature, AdType, AdConcept, ClientEda, ClientModel } from 'types/businessTypes.d';
import { TFunction } from 'react-i18next';
import { LanguageCode, User } from 'types/types';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export enum AD_DNA_COLUMNS {
  INDEX = 'INDEX',

  // MAIN
  PGID = 'PGID',
  CREATED_DATE = 'CREATED_DATE',
  PGID_AUTHOR = 'PGID_AUTHOR',
  AD_TITLE = 'AD_TITLE',
  PROJECT = 'PROJECT',
  PAYMENT_MODEL = 'PAYMENT_MODEL',
  CHANNEL = 'CHANNEL',
  NARRATIVE_TYPE = 'NARRATIVE_TYPE',
  ASSET_FORMAT = 'ASSET_FORMAT',
  ASSET_RATIO = 'ASSET_RATIO',
  LAYOUT = 'LAYOUT',
  ASSET_FORMAT_TYPE = 'ASSET_FORMAT_TYPE',
  VIDEO_LENGTH = 'VIDEO_LENGTH',
  SLIDES_NUMBER = 'SLIDES_NUMBER',
  ASSET_TYPE = 'ASSET_TYPE',
  ASSET_SUB_TYPE = 'ASSET_SUB_TYPE',
  POPULAR_FORMAT = 'POPULAR_FORMAT',
  LANGUAGE = 'LANGUAGE',

  // GRAB ATTENTION
  VISUAL_HOOK_BRANDED = 'VISUAL_HOOK_BRANDED',
  VISUAL_HOOK_FEATURES = 'VISUAL_HOOK_FEATURES',
  // VISUAL_HOOK_UNUSUAL = 'VISUAL_HOOK_UNUSUAL',
  VISUAL_HOOK_RELEVANCE = 'VISUAL_HOOK_RELEVANCE',
  COPY_HOOK = 'COPY_HOOK',
  COPY_HOOK_PLAIN = 'COPY_HOOK_PLAIN',
  COPY_HOOK_FRAMING = 'COPY_HOOK_FRAMING',
  COPY_HOOK_TYPOLOGY = 'COPY_HOOK_TYPOLOGY',
  COPY_HOOK_RELEVANCE = 'COPY_HOOK_RELEVANCE',
  POPULAR_FORMATS = 'POPULAR_FORMATS',
  // REFERENCES = 'REFERENCES',
  // TREND = 'TREND',
  SOUND = 'SOUND',
  // AI_CONTENT = 'AI_CONTENT',

  // HOLD ATTENTION
  UGC_CREATORS = 'UGC_CREATORS',
  VISUAL_BRANDED = 'VISUAL_BRANDED',
  VISUAL_FEATURES = 'VISUAL_FEATURES',
  COPY = 'COPY',
  COPY_PLAIN = 'COPY_PLAIN',
  SCRIPT = 'SCRIPT',
  SCRIPT_PLAIN = 'SCRIPT_PLAIN',
  LOCATION_SPECIFICITY = 'LOCATION_SPECIFICITY',
  PAIN_POINTS = 'PAIN_POINTS',
  PERSONAS = 'PERSONAS',
  BENEFITS = 'BENEFITS',
  PRODUCT_FEATURES = 'PRODUCT_FEATURES',
  PRODUCTS = 'PRODUCTS',
  VALUE_PROPOSITIONS = 'VALUE_PROPOSITIONS',
  // EDUCATING = 'EDUCATING',
  // EDUCATING_APP = 'EDUCATING_APP',
  // EDUCATING_APP_WHOLE_APP = 'EDUCATING_APP_WHOLE_APP',
  // EDUCATING_APP_SERVICE = 'EDUCATING_APP_SERVICE',
  // EDUCATING_BROAD_TOPIC = 'EDUCATING_BROAD_TOPIC',
  USER_CENTRICITY = 'USER_CENTRICITY',
  SPECIFICITY = 'SPECIFICITY',
  STATISTICS = 'STATISTICS',
  SIMPLE_LANGUAGE = 'SIMPLE_LANGUAGE',
  LEGAL_DISCLAIMER = 'LEGAL_DISCLAIMER',
  SUBTITLES = 'SUBTITLES',
  // ENTERTAINING = 'ENTERTAINING',
  // ANTICIPATION = 'ANTICIPATION',

  // EVOKE ACTION
  // FINANCIAL_BENEFIT = 'FINANCIAL_BENEFIT',
  // INSTANT_VALUE = 'INSTANT_VALUE',
  SOCIAL_PROOF = 'SOCIAL_PROOF',
  AUTHORITY = 'AUTHORITY',
  LOSS_AVERSION = 'LOSS_AVERSION',
  SCARCITY = 'SCARCITY',
  // UNITY = 'UNITY',
  DEEPER_MOTIVATION_DESIRE = 'DEEPER_MOTIVATION_DESIRE',
  SELL_THE_PRODUCT_SELL_THE_FEELING = 'SELL_THE_PRODUCT_SELL_THE_FEELING',
  CTA = 'CTA',
  CTA_PLACEMENT = 'CTA_PLACEMENT',

  // ROLES
  CREATIVE_LEAD = 'CREATIVE_LEAD',
  IDEATOR = 'IDEATOR',
  CREATIVE_STRATEGIST = 'CREATIVE_STRATEGIST',
  COPYWRITER = 'COPYWRITER',
  GRAPHIC_DESIGNER = 'GRAPHIC_DESIGNER',
  MOTION_DESIGNER = 'MOTION_DESIGNER',
  CREATOR = 'CREATOR',
  // ILLUSTRATOR = 'ILLUSTRATOR',
  // ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  // PHOTOGRAPHER = 'PHOTOGRAPHER',
  // VIDEOGRAPHER = 'VIDEOGRAPHER',
  // MODEL = 'MODEL',

  // AD SCORE
  // AD_SCORE = 'AD_SCORE',
  // AD_SCORE_META = 'AD_SCORE_META',
  // AD_SCORE_TIKTOK = 'AD_SCORE_TIKTOK',

  // SM INSIGHTS
  // INSIGHTS_SPEND = 'INSIGHTS_SPEND',
  // INSIGHTS_APP_INSTALL = 'INSIGHTS_APP_INSTALL',
  // INSIGHTS_APP_INSTALL_COST = 'INSIGHTS_APP_INSTALL_COST',
  // INSIGHTS_COMPLETE_REGISTRATION = 'INSIGHTS_COMPLETE_REGISTRATION',
  // INSIGHTS_COMPLETE_REGISTRATION_COST = 'INSIGHTS_COMPLETE_REGISTRATION_COST',
  // INSIGHTS_VIEW_CONTENT = 'INSIGHTS_VIEW_CONTENT',
  // INSIGHTS_VIEW_CONTENT_COST = 'INSIGHTS_VIEW_CONTENT_COST',

  META_SPEND = 'META_SPEND',
  INSIGHTS_META_APP_INSTALL = 'INSIGHTS_META_APP_INSTALL',
  INSIGHTS_META_APP_INSTALL_COST = 'INSIGHTS_META_APP_INSTALL_COST',
  INSIGHTS_META_ADD_PAYMENT_INFO = 'INSIGHTS_META_ADD_PAYMENT_INFO',
  INSIGHTS_META_ADD_PAYMENT_INFO_COST = 'INSIGHTS_META_ADD_PAYMENT_INFO_COST',
  INSIGHTS_META_ADD_TO_CART = 'INSIGHTS_META_ADD_TO_CART',
  INSIGHTS_META_ADD_TO_CART_COST = 'INSIGHTS_META_ADD_TO_CART_COST',
  INSIGHTS_META_ADD_TO_WISHLIST = 'INSIGHTS_META_ADD_TO_WISHLIST',
  INSIGHTS_META_ADD_TO_WISHLIST_COST = 'INSIGHTS_META_ADD_TO_WISHLIST_COST',
  INSIGHTS_META_COMPLETE_REGISTRATION = 'INSIGHTS_META_COMPLETE_REGISTRATION',
  INSIGHTS_META_COMPLETE_REGISTRATION_COST = 'INSIGHTS_META_COMPLETE_REGISTRATION_COST',
  INSIGHTS_META_CONTACT = 'INSIGHTS_META_CONTACT',
  INSIGHTS_META_CONTACT_COST = 'INSIGHTS_META_CONTACT_COST',
  INSIGHTS_META_CUSTOMIZE_PRODUCT = 'INSIGHTS_META_CUSTOMIZE_PRODUCT',
  INSIGHTS_META_CUSTOMIZE_PRODUCT_COST = 'INSIGHTS_META_CUSTOMIZE_PRODUCT_COST',
  INSIGHTS_META_DONATE = 'INSIGHTS_META_DONATE',
  INSIGHTS_META_DONATE_COST = 'INSIGHTS_META_DONATE_COST',
  INSIGHTS_META_FIND_LOCATION = 'INSIGHTS_META_FIND_LOCATION',
  INSIGHTS_META_FIND_LOCATION_COST = 'INSIGHTS_META_FIND_LOCATION_COST',
  INSIGHTS_META_INITIATE_CHECKOUT = 'INSIGHTS_META_INITIATE_CHECKOUT',
  INSIGHTS_META_INITIATE_CHECKOUT_COST = 'INSIGHTS_META_INITIATE_CHECKOUT_COST',
  INSIGHTS_META_LEAD = 'INSIGHTS_META_LEAD',
  INSIGHTS_META_LEAD_COST = 'INSIGHTS_META_LEAD_COST',
  INSIGHTS_META_PURCHASE = 'INSIGHTS_META_PURCHASE',
  INSIGHTS_META_PURCHASE_COST = 'INSIGHTS_META_PURCHASE_COST',
  INSIGHTS_META_SCHEDULE = 'INSIGHTS_META_SCHEDULE',
  INSIGHTS_META_SCHEDULE_COST = 'INSIGHTS_META_SCHEDULE_COST',
  INSIGHTS_META_SEARCH = 'INSIGHTS_META_SEARCH',
  INSIGHTS_META_SEARCH_COST = 'INSIGHTS_META_SEARCH_COST',
  INSIGHTS_META_START_TRIAL = 'INSIGHTS_META_START_TRIAL',
  INSIGHTS_META_START_TRIAL_COST = 'INSIGHTS_META_START_TRIAL_COST',
  INSIGHTS_META_SUBMIT_APPLICATION = 'INSIGHTS_META_SUBMIT_APPLICATION',
  INSIGHTS_META_SUBMIT_APPLICATION_COST = 'INSIGHTS_META_SUBMIT_APPLICATION_COST',
  INSIGHTS_META_SUBSCRIBE = 'INSIGHTS_META_SUBSCRIBE',
  INSIGHTS_META_SUBSCRIBE_COST = 'INSIGHTS_META_SUBSCRIBE_COST',
  INSIGHTS_META_VIEW_CONTENT = 'INSIGHTS_META_VIEW_CONTENT',
  INSIGHTS_META_VIEW_CONTENT_COST = 'INSIGHTS_META_VIEW_CONTENT_COST',
  INSIGHTS_META_ADD_TO_WAITING_LIST = 'INSIGHTS_META_ADD_TO_WAITING_LIST',
  INSIGHTS_META_ADD_TO_WAITING_LIST_COST = 'INSIGHTS_META_ADD_TO_WAITING_LIST_COST',
  INSIGHTS_META_RETURN_ON_AD_SPEND = 'INSIGHTS_META_RETURN_ON_AD_SPEND',
  INSIGHTS_META_RETURN_ON_AD_SPEND_COST = 'INSIGHTS_META_RETURN_ON_AD_SPEND_COST',

  GEOM_M_1 = 'GEOM_M_1',
  F_BETA_1 = 'F_BETA_1',

  GEOM_M_2 = 'GEOM_M_2',
  F_BETA_2 = 'F_BETA_2',

  TIKTOK_SPEND = 'TIKTOK_SPEND',
  INSIGHTS_TIKTOK_APP_INSTALL = 'INSIGHTS_TIKTOK_APP_INSTALL',
  INSIGHTS_TIKTOK_APP_INSTALL_COST = 'INSIGHTS_TIKTOK_APP_INSTALL_COST',
  INSIGHTS_TIKTOK_VIEW_CONTENT = 'INSIGHTS_TIKTOK_VIEW_CONTENT',
  INSIGHTS_TIKTOK_VIEW_CONTENT_COST = 'INSIGHTS_TIKTOK_VIEW_CONTENT_COST',
  INSIGHTS_TIKTOK_ADD_TO_CART = 'INSIGHTS_TIKTOK_ADD_TO_CART',
  INSIGHTS_TIKTOK_ADD_TO_CART_COST = 'INSIGHTS_TIKTOK_ADD_TO_CART_COST',
  INSIGHTS_TIKTOK_ADD_TO_WISHLIST = 'INSIGHTS_TIKTOK_ADD_TO_WISHLIST',
  INSIGHTS_TIKTOK_ADD_TO_WISHLIST_COST = 'INSIGHTS_TIKTOK_ADD_TO_WISHLIST_COST',
  INSIGHTS_TIKTOK_COMPLETE_REGISTRATION = 'INSIGHTS_TIKTOK_COMPLETE_REGISTRATION',
  INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST = 'INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST',
  INSIGHTS_TIKTOK_PURCHASE = 'INSIGHTS_TIKTOK_PURCHASE',
  INSIGHTS_TIKTOK_PURCHASE_COST = 'INSIGHTS_TIKTOK_PURCHASE_COST',
}

export const toCsv = (
  items: AdDna[],
  t: TFunction,
  usersById: Map<string, User>,
  projectsById: Map<string, AdConcept>,
  columns: AD_DNA_COLUMNS[],
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { headers: any; data: any[] } => {
  const headers = {};
  columns
    .filter((i) => Object.values(AD_DNA_COLUMNS).includes(i))
    .forEach((i) => {
      // @ts-ignore
      headers[i] = t(`PRIVATE.USER.ADS_DNA.${i}`);
    });

  // @ts-ignore
  headers['metaGScore1'] = 'metaGScore1';
  // @ts-ignore
  headers['metaLogHStandScore1'] = 'metaLogHStandScore1';
  // @ts-ignore
  headers[AD_DNA_COLUMNS.GEOM_M_1] = t(`PRIVATE.USER.ADS_DNA.${AD_DNA_COLUMNS.GEOM_M_1}`);
  // @ts-ignore
  headers['metaHelperColGScore1'] = 'metaHelperColGScore1';
  // @ts-ignore
  headers['metaG1'] = 'metaG1';
  // @ts-ignore
  headers['metaH1'] = 'metaH1';
  // @ts-ignore
  headers['metaDistG1'] = 'metaDistG1';
  // @ts-ignore
  headers['metaDistH1'] = 'metaDistH1';
  // @ts-ignore
  headers[AD_DNA_COLUMNS.F_BETA_1] = t(`PRIVATE.USER.ADS_DNA.${AD_DNA_COLUMNS.F_BETA_1}`);

  [...Object.values(AD_DNA_COLUMNS).slice(Object.values(AD_DNA_COLUMNS).indexOf(AD_DNA_COLUMNS.GEOM_M_2))].forEach(
    (i) => {
      // @ts-ignore
      headers[i] = t(`PRIVATE.USER.ADS_DNA.${i}`);
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [];
  items.forEach((i) => {
    const item = {};

    // @ts-ignore
    item[AD_DNA_COLUMNS.PGID] = i.pgId;
    // @ts-ignore
    item[AD_DNA_COLUMNS.CREATED_DATE] = i.createdDate;
    // @ts-ignore
    item[AD_DNA_COLUMNS.PGID_AUTHOR] = i.pgIdAuthor?.length ? getFullName(usersById.get(i.pgIdAuthor)) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.AD_TITLE] = i.title;
    // @ts-ignore
    item[AD_DNA_COLUMNS.PROJECT] = projectsById.get(i.projectId)?.title || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.PAYMENT_MODEL] = getLocalisedClientPaymentModel(i.paymentModel, t);
    // @ts-ignore
    item[AD_DNA_COLUMNS.CHANNEL] = i.channel ? getLocalisedSocialMediaChannel(i.channel, t) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.NARRATIVE_TYPE] = i.narrativeType
      ? capitalizeFirstLetter(i.narrativeType.toLowerCase().replaceAll('_', ' '))
      : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_FORMAT] = i.assetFormat?.replaceAll('_', ' ')?.toLowerCase();
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_RATIO] = i.assetRatio;
    // @ts-ignore
    item[AD_DNA_COLUMNS.LAYOUT] = i.layout?.toLowerCase();
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_FORMAT_TYPE] = i.singleAssetFormatType?.toLowerCase();
    // @ts-ignore
    item[AD_DNA_COLUMNS.VIDEO_LENGTH] = i.assetFormat === AdType.SINGLE_VIDEO ? i.videoLength : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SLIDES_NUMBER] = i.assetFormat === AdType.CAROUSEL ? i.slidesNumber : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_TYPE] = i.singleAssetType ? t(`ASSET_TYPE.${i.singleAssetType}`) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_SUB_TYPE] = i.singleAssetSubType ? t(`ASSET_SUB_TYPE.${i.singleAssetSubType}`) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.POPULAR_FORMAT] =
      i.popularFormats?.map((format) => capitalizeFirstLetter(format.toLowerCase().replaceAll('_', ' '))).join(', ') ||
      '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.LANGUAGE] =
      i.languages?.map((j) => getLocalisedLanguage(j as LanguageCode, t))?.join(', ') || '';

    // *************** GRAB ATTENTION ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_BRANDED] = i.visualHookBranded == null ? '' : i.visualHookBranded ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_FEATURES] = i.visualHookFeatures
      ?.map((i) =>
        Object.values(AdDnaVisualFeature)
          .map((j) => j.toString())
          .includes(i)
          ? t(`PRIVATE.USER.ADS_DNA.${i}`)
          : i,
      )
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_UNUSUAL] = i.visualHookUnusual == null ? '' : i.visualHookUnusual ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_RELEVANCE] = i.visualHookRelevance
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.VISUAL_HOOK_RELEVANCE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK] = i.copyHookPlain || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK_FRAMING] = i.copyHookFraming
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_FRAMING_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK_TYPOLOGY] = i.copyHookTypology
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_TYPOLOGY_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK_RELEVANCE] = i.copyHookRelevance
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_RELEVANCE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.POPULAR_FORMATS] = i.popularFormats?.map((i) => t(`PRIVATE.USER.ADS_DNA.${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.REFERENCES] = !!i.references?.length
      ? t(`PRIVATE.USER.ADS_DNA.REFERENCES_${i.references[0]}`)
      : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.TREND] = i.trend?.map((i) => t(`PRIVATE.USER.ADS_DNA.TREND_${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.SOUND] = i.sound?.map((i) => t(`PRIVATE.USER.ADS_DNA.SOUND_${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.AI_CONTENT] = i.aiContent == null ? '' : i.aiContent ? 'yes' : 'no';

    // *************** HOLD ATTENTION ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.UGC_CREATORS] = i.ugcCreators
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.UGC_CREATORS_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_BRANDED] = i.visualBranded == null ? '' : i.visualBranded ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_FEATURES] = i.visualFeatures
      ?.map((i) =>
        Object.values(AdDnaVisualFeature)
          .map((j) => j.toString())
          .includes(i)
          ? t(`PRIVATE.USER.ADS_DNA.${i}`)
          : i,
      )
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY] = i.copyPlain || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SCRIPT] = i.scriptPlain || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.LOCATION_SPECIFICITY] =
      i.locationSpecificity == null ? '' : i.locationSpecificity ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.PAIN_POINTS] = i.painPoints?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PERSONAS] = i.personas?.map((i) => i.name)?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.BENEFITS] = i.benefits?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PRODUCT_FEATURES] = i.productFeatures?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PRODUCTS] = i.products?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VALUE_PROPOSITIONS] = i.valuePropositions?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING] = i.educating == null ? '' : i.educating ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_APP] = i.educatingApp == null ? '' : i.educatingApp ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_APP_WHOLE_APP] =
      i.educatingAppWholeApp == null ? '' : i.educatingAppWholeApp ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_APP_SERVICE] =
      i.educatingAppService == null ? '' : i.educatingAppService ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_BROAD_TOPIC] =
      i.educatingBroadTopic == null ? '' : i.educatingBroadTopic ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.USER_CENTRICITY] = i.userCentricity || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SPECIFICITY] = i.specificity || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.STATISTICS] = i.statistics ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SIMPLE_LANGUAGE] = i.simpleLanguage
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.SIMPLE_LANGUAGE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.LEGAL_DISCLAIMER] = i.legalDisclaimer == null ? '' : i.legalDisclaimer ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SUBTITLES] = i.subtitles == null ? '' : i.subtitles ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SUBTITLES] = i.subtitles == null ? '' : i.subtitles ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ENTERTAINING] = i.entertaining == null ? '' : i.entertaining ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ANTICIPATION] = i.anticipation == null ? '' : i.anticipation ? 'yes' : 'no';

    // *************** EVOKE ACTION ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.FINANCIAL_BENEFIT] = i.financialBenefit
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.FINANCIAL_BENEFIT_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSTANT_VALUE] = i.instantValue
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.INSTANT_VALUE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.SOCIAL_PROOF] = i.socialProof
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.SOCIAL_PROOF_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.AUTHORITY] = i.authority?.map((i) => t(`PRIVATE.USER.ADS_DNA.AUTHORITY_${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.LOSS_AVERSION] = i.lossAversion
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.LOSS_AVERSION_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.SCARCITY] = i.unity == null ? '' : i.scarcity ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.UNITY] = i.unity == null ? '' : i.unity ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.DEEPER_MOTIVATION_DESIRE] = i.deeperMotivationalDesire || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SELL_THE_PRODUCT_SELL_THE_FEELING] = i.sellTheProductSellTheFeeling || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.CTA] = i.ctas?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.CTA_PLACEMENT] = i.ctaPlacement ? getLocalisedCtaPlacement(i.ctaPlacement, t) : '';

    // *************** ROLES ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.ACCOUNT_MANAGER] = i.accountManagerNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.CREATIVE_STRATEGIST] = i.creativeStrategistNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPYWRITER] = i.copywriterNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.CREATOR] = i.creatorNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.GRAPHIC_DESIGNER] = i.graphicDesignerNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.MOTION_DESIGNER] = i.motionDesignerNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.ILLUSTRATOR] = i.illustratorNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.PHOTOGRAPHER] = i.photographerNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.VIDEOGRAPHER] = i.videographerNames;
    // @ts-ignore
    item[AD_DNA_COLUMNS.MODEL] = i.modelNames;

    // @ts-ignore
    item[AD_DNA_COLUMNS.META_SPEND] = i.insightsMetaSpend;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_APP_INSTALL] = i.insightsMetaAppInstall;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_APP_INSTALL_COST] = i.insightsMetaAppInstallCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_PAYMENT_INFO] = i.insightsMetaAddPaymentInfo;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_PAYMENT_INFO_COST] = i.insightsMetaAddPaymentInfoCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_CART] = i.insightsMetaAddToCart;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_CART_COST] = i.insightsMetaAddToCartCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WISHLIST] = i.insightsMetaAddToWishlist;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WISHLIST_COST] = i.insightsMetaAddToWishlistCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_COMPLETE_REGISTRATION] = i.insightsMetaCompleteRegistration;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_COMPLETE_REGISTRATION_COST] = i.insightsMetaCompleteRegistrationCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_CONTACT] = i.insightsMetaContact;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_CONTACT_COST] = i.insightsMetaContactCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_CUSTOMIZE_PRODUCT] = i.insightsMetaCustomizeProduct;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_CUSTOMIZE_PRODUCT_COST] = i.insightsMetaCustomizeProductCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_DONATE] = i.insightsMetaDonate;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_DONATE_COST] = i.insightsMetaDonateCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_FIND_LOCATION] = i.insightsMetaFindLocation;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_FIND_LOCATION_COST] = i.insightsMetaFindLocationCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_INITIATE_CHECKOUT] = i.insightsMetaInitiateCheckout;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_INITIATE_CHECKOUT_COST] = i.insightsMetaInitiateCheckoutCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_LEAD] = i.insightsMetaLead;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_LEAD_COST] = i.insightsMetaLeadCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_PURCHASE] = i.insightsMetaPurchase;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_PURCHASE_COST] = i.insightsMetaPurchaseCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SCHEDULE] = i.insightsMetaSchedule;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SCHEDULE_COST] = i.insightsMetaScheduleCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SEARCH] = i.insightsMetaSearch;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SEARCH_COST] = i.insightsMetaSearchCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_START_TRIAL] = i.insightsMetaStartTrial;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_START_TRIAL_COST] = i.insightsMetaStartTrialCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SUBMIT_APPLICATION] = i.insightsMetaSubmitApplication;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SUBMIT_APPLICATION_COST] = i.insightsMetaSubmitApplicationCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SUBSCRIBE] = i.insightsMetaSubscribe;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SUBSCRIBE_COST] = i.insightsMetaSubscribeCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_VIEW_CONTENT] = i.insightsMetaViewContent;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_VIEW_CONTENT_COST] = i.insightsMetaViewContentCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WAITING_LIST] = i.insightsMetaAddToWaitingList;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WAITING_LIST_COST] = i.insightsMetaAddToWaitingListCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_RETURN_ON_AD_SPEND] = i.insightsMetaReturnOnAdSpend;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_RETURN_ON_AD_SPEND_COST] = i.insightsMetaReturnOnAdSpendCost?.toFixed(2);

    // @ts-ignore
    item['metaGScore1'] = i.metaGScore1?.toFixed(5);
    // @ts-ignore
    item['metaLogHStandScore1'] = i.metaLogHStandScore1?.toFixed(5);
    // @ts-ignore
    item[AD_DNA_COLUMNS.GEOM_M_1] = i.metaGeomMScore1?.toFixed(5);
    // @ts-ignore
    item['metaHelperColGScore1'] = i.metaHelperColGScore1?.toFixed(5);
    // @ts-ignore
    item['metaG1'] = i.metaG1?.toFixed(5);
    // @ts-ignore
    item['metaH1'] = i.metaH1?.toFixed(5);
    // @ts-ignore
    item['metaDistG1'] = i.metaDistG1?.toFixed(5);
    // @ts-ignore
    item['metaDistH1'] = i.metaDistH1?.toFixed(5);
    // @ts-ignore
    item[AD_DNA_COLUMNS.F_BETA_1] = i.metaFBetaScore1?.toFixed(5);

    // @ts-ignore
    item[AD_DNA_COLUMNS.GEOM_M_2] = i.metaGeomMScore2?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.F_BETA_2] = i.metaFBetaScore2?.toFixed(2);

    // @ts-ignore
    item[AD_DNA_COLUMNS.TIKTOK_SPEND] = i.insightsTiktokSpend;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_APP_INSTALL] = i.insightsTiktokAppInstall;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_APP_INSTALL_COST] = i.insightsTiktokAppInstallCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_VIEW_CONTENT] = i.insightsTiktokViewContent;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_VIEW_CONTENT_COST] = i.insightsTiktokViewContentCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_CART] = i.insightsTiktokAddToCart;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_CART_COST] = i.insightsTiktokAddToCartCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_WISHLIST] = i.insightsTiktokAddToWishlist;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_WISHLIST_COST] = i.insightsTiktokAddToWishlistCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_COMPLETE_REGISTRATION] = i.insightsTiktokCompleteRegistration;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST] =
      i.insightsTiktokCompleteRegistrationCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_PURCHASE] = i.insightsTiktokPurchase;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_PURCHASE_COST] = i.insightsTiktokPurchaseCost?.toFixed(2);

    data.push(item);
  });

  return { headers, data };
};

export enum CLIENT_EDA_COLUMNS {
  CLIENT_ID = 'CLIENT_ID',
  VAR_NAME = 'VAR_NAME',
  VAR_VALUE = 'VAR_VALUE',
  PLOT_TYPE = 'PLOT_TYPE',
  GEOM_M_SCORE = 'GEOM_M_SCORE',
  F_BETA_SCORE = 'F_BETA_SCORE',
}

export enum CLIENT_MODEL_COLUMNS {
  CLIENT_ID = 'CLIENT_ID',
  VAR_NAME = 'VAR_NAME',
  GEOM_M_IMPORTANCE_SCORE = 'GEOM_M_IMPORTANCE_SCORE',
  F_BETA_IMPORTANCE_SCORE = 'F_BETA_IMPORTANCE_SCORE',
  OVERLAY = 'OVERLAY',
}

export const toEdaCsv = (
  eda: ClientEda,
  t: TFunction,
  columns: CLIENT_EDA_COLUMNS[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { headers: Record<string, string>; data: any[] } => {
  const headers: Record<string, string> = {};

  columns
    // .filter((i) => Object.values(CLIENT_EDA_COLUMNS).includes(i))
    .filter((i) => i !== CLIENT_EDA_COLUMNS.CLIENT_ID)
    .forEach((i) => {
      headers[i] = t(`PRIVATE.USER.HIGH_LEVEL.${i}`);
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [];

  eda.plotData.forEach((entry) => {
    entry.varValue.forEach((varValue, index) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const row: Record<string, any> = {};

      const geom = entry.geomMScore[index] ? parseFloat(entry.geomMScore[index].toFixed(4)) : entry.geomMScore[index];
      const fBeta = entry.fBetaScore[index] ? parseFloat(entry.fBetaScore[index].toFixed(4)) : entry.fBetaScore[index];

      row[CLIENT_EDA_COLUMNS.VAR_NAME] = entry.varName;
      row[CLIENT_EDA_COLUMNS.VAR_VALUE] = varValue;
      row[CLIENT_EDA_COLUMNS.PLOT_TYPE] = entry.plotType;
      row[CLIENT_EDA_COLUMNS.GEOM_M_SCORE] = geom || '';
      row[CLIENT_EDA_COLUMNS.F_BETA_SCORE] = fBeta || '';

      data.push(row);
    });
  });

  return { headers, data };
};

export const toModelCsv = (
  model: ClientModel,
  t: TFunction,
  columns: CLIENT_MODEL_COLUMNS[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { headers: Record<string, string>; data: any[] } => {
  const headers: Record<string, string> = {};

  columns
    // .filter((i) => Object.values(CLIENT_MODEL_COLUMNS).includes(i))
    .filter((i) => i !== CLIENT_MODEL_COLUMNS.CLIENT_ID)
    .forEach((i) => {
      headers[i] = t(`PRIVATE.USER.HIGH_LEVEL.${i}`);
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [];

  model.varNames.forEach((varName, index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const row: Record<string, any> = {};

    const geom = model.geomMScoreImportance[index]
      ? parseFloat(model.geomMScoreImportance[index].toFixed(4))
      : model.geomMScoreImportance[index];
    const fBeta = model.fBetaScoreImportance[index]
      ? parseFloat(model.fBetaScoreImportance[index].toFixed(4))
      : model.fBetaScoreImportance[index];
    const overlay = geom - fBeta ? parseFloat((geom - fBeta).toFixed(4)) : geom - fBeta;

    row[CLIENT_MODEL_COLUMNS.VAR_NAME] = varName;
    row[CLIENT_MODEL_COLUMNS.GEOM_M_IMPORTANCE_SCORE] = geom || '';
    row[CLIENT_MODEL_COLUMNS.F_BETA_IMPORTANCE_SCORE] = fBeta || '';
    row[CLIENT_MODEL_COLUMNS.OVERLAY] = overlay || '';

    data.push(row);
  });

  return { headers, data };
};
