import { gql } from '@apollo/client';

export const fragments = {
  appConfig: gql`
    fragment AppConfig on AppConfig {
      imagekitUrl
      imagekitPublicKey
      imagekitAuthEndpoint
    }
  `,
  paging: gql`
    fragment Paging on Paging {
      page
      perPage
      pageCount
      totalCount
    }
  `,
  translation: gql`
    fragment Translation on Translation {
      languageCode
      value
    }
  `,
  address: gql`
    fragment Address on Address {
      country {
        ...Country
      }
      zipCode
      city
      state
      address1
      address2
    }
  `,
  country: gql`
    fragment Country on Country {
      countryIsoName
      countryIsoAlpha2
      countryIsoAlpha3
    }
  `,
  users: gql`
    fragment Users on Users {
      items {
        ...User
      }
      paging {
        ...Paging
      }
    }
  `,
  user: gql`
    fragment User on User {
      id
      email
      firstName
      lastName
      mobileNumber
      role
      emailConfirmed
      newEmail
      disabled
      invitationCode
      profilePic
      settings {
        ...UserSettings
      }

      clientId
      clientTeamRole

      whitelabel

      onboardingStep

      createdDate
      lastModifiedDate
    }
  `,
  userSettings: gql`
    fragment UserSettings on UserSettings {
      languageIso6391
      projectViewLayout
      dataColumnNames
      dataColumnsConfigs {
        name
        columnNames
      }
    }
  `,
  userNotificationSettings: gql`
    fragment UserNotificationSettings on UserNotificationSettings {
      settings {
        ...UserNotificationSetting
      }
    }
  `,
  userNotificationSetting: gql`
    fragment UserNotificationSetting on UserNotificationSetting {
      type
      email
      slack
    }
  `,
  invitations: gql`
    fragment Invitations on Invitations {
      items {
        ...Invitation
      }
      paging {
        ...Paging
      }
    }
  `,
  invitation: gql`
    fragment Invitation on Invitation {
      code
      usedDate
      usedByUserId
      email
      role

      clientId
      clientTeamRole
      projectId
      whitelabel

      createdBy
      createdDate
    }
  `,
  creatorUser: gql`
    fragment CreatorUser on CreatorUser {
      id
      firstName
      lastName
      email
      createdDate
    }
  `,
  pgTeamMember: gql`
    fragment PgTeamMember on PgTeamMember {
      id
      firstName
      lastName
    }
  `,
  clients: gql`
    fragment Clients on Clients {
      items {
        ...Client
      }
      paging {
        ...Paging
      }
    }
  `,
  client: gql`
    fragment Client on Client {
      id
      name
      description
      paymentModels
      mobileApp
      currency
      slackChannel

      painPoints
      productFeatures
      products
      benefits
      valuePropositions
      personas {
        ...ClientPersona
      }
      ctas

      creativeLeads
      creativeStrategists
      graphicDesigners
      motionDesigners
      copywriters
      creators
      ideators

      logoFilename
      logoContent
      appScreenFilename
      appScreenContent
      productFilename
      productContent
      visualFeatures

      disabledHoldAttentionFields

      facebookAdAccountId
      facebookImportExternalAds
      facebookAdEvents
      facebookUgcCampaignTags
      facebookUgcAdSetTags
      facebookUgcAdTags

      tikTokAdvertiserId
      tikTokImportExternalAds
      tikTokAdEvents

      asanaIds

      airtableId
      airtableAdConceptId
      airtableAbTestId
      airtablePerformanceId
      airtableRoadmapId
      airtableBacklogId

      everhourIds
      disabledProducts

      betaConstant

      starMetric

      metaDataSource {
        createdByName
        createdByAdAccountId
        adAccounts {
          id
          name
          conversionWindow
          reportTime
          events
          starMetric1
          starMetric2
        }
      }

      tikTokDataSource {
        createdByName
        createdByAdAccountId
        adAccounts {
          id
          name
          conversionWindow
          reportTime
          events
          starMetric1
          starMetric2
        }
      }

      whitelabel
      createdDate
    }
  `,
  clientPersona: gql`
    fragment ClientPersona on ClientPersona {
      name
      description
    }
  `,
  clientTeamMember: gql`
    fragment ClientTeamMember on ClientTeamMember {
      id
      invitationCode
      firstName
      lastName
      email
      profilePic
      teamRole
      createdDate
    }
  `,
  whitelabels: gql`
    fragment Whitelabels on Whitelabels {
      items {
        ...Whitelabel
      }
      paging {
        ...Paging
      }
    }
  `,
  whitelabel: gql`
    fragment Whitelabel on Whitelabel {
      id
      name
      domain
      logoContent
      logoName
      faviconContent
      faviconName
      metaDescription
      createdDate
    }
  `,
  stripeSubscription: gql`
    fragment StripeSubscription on StripeSubscription {
      id
      cancelAtPeriodEnd
      cancelAt
      cancelledAt
      created
      currentPeriodEnd
      currentPeriodStart
      endedAt
      startDate
      status
    }
  `,
};

export const getAppConfig = gql`
  query {
    getAppConfig {
      ...AppConfig
    }
  }
  ${fragments.appConfig}
`;

export const me = gql`
  query {
    me {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const getUser = gql`
  query ($email: String!) {
    getUser(email: $email) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const getUsers = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: UserSearchCriteria) {
    getUsers(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Users
    }
  }
  ${fragments.users}
  ${fragments.user}
  ${fragments.userSettings}
  ${fragments.paging}
`;

export const getAllUsers = gql`
  query {
    getAllUsers {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const getUserNotificationSettings = gql`
  query ($email: String) {
    getUserNotificationSettings(email: $email) {
      ...UserNotificationSettings
    }
  }
  ${fragments.userNotificationSettings}
  ${fragments.userNotificationSetting}
`;

export const getInvitations = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: InvitationSearchCriteria) {
    getInvitations(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Invitations
    }
  }
  ${fragments.invitations}
  ${fragments.invitation}
  ${fragments.paging}
`;

export const getCreatorUsers = gql`
  query {
    getCreatorUsers {
      ...CreatorUser
    }
  }
  ${fragments.creatorUser}
`;

export const getPgTeamMembers = gql`
  query {
    getPgTeamMembers {
      ...PgTeamMember
    }
  }
  ${fragments.pgTeamMember}
`;

export const getClients = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: ClientSearchCriteria) {
    getClients(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Clients
    }
  }
  ${fragments.clients}
  ${fragments.client}
  ${fragments.clientPersona}
  ${fragments.paging}
`;

export const getClient = gql`
  query ($id: String) {
    getClient(id: $id) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const getClientTeamMembers = gql`
  query ($clientId: String) {
    getClientTeamMembers(clientId: $clientId) {
      ...ClientTeamMember
    }
  }
  ${fragments.clientTeamMember}
`;

export const getWhitelabels = gql`
  query ($paging: SearchPaging!, $sorting: Sorting) {
    getWhitelabels(paging: $paging, sorting: $sorting) {
      ...Whitelabels
    }
  }
  ${fragments.whitelabels}
  ${fragments.whitelabel}
  ${fragments.paging}
`;

export const getWhitelabel = gql`
  query ($domain: String!) {
    getWhitelabel(domain: $domain) {
      ...Whitelabel
    }
  }
  ${fragments.whitelabel}
`;

export const getCheckoutSessionUrlForSubscription = gql`
  query ($priceId: String!, $successUrl: String!, $cancelUrl: String!, $couponId: String, $promotionCode: String) {
    getCheckoutSessionUrlForSubscription(
      priceId: $priceId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      couponId: $couponId
      promotionCode: $promotionCode
    )
  }
`;

export const getCustomerPortalSessionUrl = gql`
  query ($email: String, $returnUrl: String!) {
    getCustomerPortalSessionUrl(email: $email, returnUrl: $returnUrl)
  }
`;

export const getCustomerSubscriptions = gql`
  query {
    getCustomerSubscriptions {
      ...StripeSubscription
    }
  }
  ${fragments.stripeSubscription}
`;
