import {
  AdDnaAuthority,
  AdDnaCopyHookFraming,
  AdDnaCopyHookRelevance,
  AdDnaCopyHookTypology,
  AdDnaCtaPlacement,
  AdDnaFinancialBenefit,
  AdDnaInstantValue,
  AdDnaLayout,
  AdDnaLossAversion,
  AdDnaPopularFormat,
  AdDnaReferences,
  AdDnaRole,
  AdDnaSimpleLanguage,
  AdDnaSingleAssetFormatType,
  AdDnaSingleAssetSubType,
  AdDnaSingleAssetType,
  AdDnaSocialProof,
  AdDnaSound,
  AdDnaTrend,
  AdDnaUgcCreators,
  AdDnaVisualHookRelevance,
  AdType,
  NarrativeType,
} from 'types/businessTypes';
import { ClientPaymentModel, ClientPersona, Maybe, Scalars, SocialMediaChannel } from './types.d';

export type SearchPaging = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type Sorting = {
  orderBy: OrderBy;
};

export enum OrderBy {
  EmailAsc = 'EmailAsc',
  EmailDesc = 'EmailDesc',
  FirstNameAsc = 'FirstNameAsc',
  FirstNameDesc = 'FirstNameDesc',
  LastNameAsc = 'LastNameAsc',
  LastNameDesc = 'LastNameDesc',
  LanguageAsc = 'LanguageAsc',
  LanguageDesc = 'LanguageDesc',
  IdAsc = 'IdAsc',
  IdDesc = 'IdDesc',
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  UsedDateAsc = 'UsedDateAsc',
  UsedDateDesc = 'UsedDateDesc',
  CreatedDateAsc = 'CreatedDateAsc',
  CreatedDateDesc = 'CreatedDateDesc',
  LastModifiedDateAsc = 'LastModifiedDateAsc',
  LastModifiedDateDesc = 'LastModifiedDateDesc',
  MarketAsc = 'MarketAsc',
  MarketDesc = 'MarketDesc',
  TitleAsc = 'TitleAsc',
  TitleDesc = 'TitleDesc',
  RoleAsc = 'RoleAsc',
  RoleDesc = 'RoleDesc',
  PaymentModelAsc = 'PaymentModelAsc',
  PaymentModelDesc = 'PaymentModelDesc',
  ChannelAsc = 'ChannelAsc',
  ChannelDesc = 'ChannelDesc',
  UgcAsc = 'UgcAsc',
  UgcDesc = 'UgcDesc',
  // ProductFeaturesAsc = 'ProductFeaturesAsc',
  // ProductFeaturesDesc = 'ProductFeaturesDesc',
  // ValuePropositionsAsc = 'ValuePropositionsAsc',
  // ValuePropositionsDesc = 'ValuePropositionsDesc',
  // PersonasAsc = 'PersonasAsc',
  // PersonasDesc = 'PersonasDesc',
  LayoutAsc = 'LayoutAsc',
  LayoutDesc = 'LayoutDesc',
  VideoLengthAsc = 'VideoLengthAsc',
  VideoLengthDesc = 'VideoLengthDesc',
  SlidesNumberAsc = 'SlidesNumberAsc',
  SlidesNumberDesc = 'SlidesNumberDesc',
  // VisualFeaturesAsc = 'VisualFeaturesAsc',
  // VisualFeaturesDesc = 'VisualFeaturesDesc',
  AccountManagerAsc = 'AccountManagerAsc',
  AccountManagerDesc = 'AccountManagerDesc',
  CreativeLeadAsc = 'CreativeLeadAsc',
  CreativeLeadDesc = 'CreativeLeadDesc',
  CreativeStrategistAsc = 'CreativeStrategistAsc',
  CreativeStrategistDesc = 'CreativeStrategistDesc',
  CopywriterAsc = 'CopywriterAsc',
  CopywriterDesc = 'CopywriterDesc',
  GraphicDesignerAsc = 'GraphicDesignerAsc',
  GraphicDesignerDesc = 'GraphicDesignerDesc',
  MotionDesignerAsc = 'MotionDesignerAsc',
  MotionDesignerDesc = 'MotionDesignerDesc',
  IllustratorAsc = 'IllustratorAsc',
  IllustratorDesc = 'IllustratorDesc',
  ModelAsc = 'ModelAsc',
  ModelDesc = 'ModelDesc',
  PhotographerAsc = 'PhotographerAsc',
  PhotographerDesc = 'PhotographerDesc',
  VideographerAsc = 'VideographerAsc',
  VideographerDesc = 'VideographerDesc',
  CreatorAsc = 'CreatorAsc',
  CreatorDesc = 'CreatorDesc',
  IdeatorAsc = 'IdeatorAsc',
  IdeatorDesc = 'IdeatorDesc',
  CtaAsc = 'CtaAsc',
  CtaDesc = 'CtaDesc',
  DomainAsc = 'DomainAsc',
  DomainDesc = 'DomainDesc',
  // Meta
  InsightsMetaSpendAsc = 'InsightsMetaSpendAsc',
  InsightsMetaSpendDesc = 'InsightsMetaSpendDesc',
  // App Install
  InsightsMetaAppInstallAsc = 'InsightsMetaAppInstallAsc',
  InsightsMetaAppInstallDesc = 'InsightsMetaAppInstallDesc',
  InsightsMetaAppInstallCostAsc = 'InsightsMetaAppInstallCostAsc',
  InsightsMetaAppInstallCostDesc = 'InsightsMetaAppInstallCostDesc',
  // Add Payment Info
  InsightsMetaAddPaymentInfoAsc = 'InsightsMetaAddPaymentInfoAsc',
  InsightsMetaAddPaymentInfoDesc = 'InsightsMetaAddPaymentInfoDesc',
  InsightsMetaAddPaymentInfoCostAsc = 'InsightsMetaAddPaymentInfoCostAsc',
  InsightsMetaAddPaymentInfoCostDesc = 'InsightsMetaAddPaymentInfoCostDesc',
  // Add To Cart
  InsightsMetaAddToCartAsc = 'InsightsMetaAddToCartAsc',
  InsightsMetaAddToCartDesc = 'InsightsMetaAddToCartDesc',
  InsightsMetaAddToCartCostAsc = 'InsightsMetaAddToCartCostAsc',
  InsightsMetaAddToCartCostDesc = 'InsightsMetaAddToCartCostDesc',
  // Add To Wishlist
  InsightsMetaAddToWishlistAsc = 'InsightsMetaAddToWishlistAsc',
  InsightsMetaAddToWishlistDesc = 'InsightsMetaAddToWishlistDesc',
  InsightsMetaAddToWishlistCostAsc = 'InsightsMetaAddToWishlistCostAsc',
  InsightsMetaAddToWishlistCostDesc = 'InsightsMetaAddToWishlistCostDesc',
  // Complete Registration
  InsightsMetaCompleteRegistrationAsc = 'InsightsMetaCompleteRegistrationAsc',
  InsightsMetaCompleteRegistrationDesc = 'InsightsMetaCompleteRegistrationDesc',
  InsightsMetaCompleteRegistrationCostAsc = 'InsightsMetaCompleteRegistrationCostAsc',
  InsightsMetaCompleteRegistrationCostDesc = 'InsightsMetaCompleteRegistrationCostDesc',
  // Contact
  InsightsMetaContactAsc = 'InsightsMetaContactAsc',
  InsightsMetaContactDesc = 'InsightsMetaContactDesc',
  InsightsMetaContactCostAsc = 'InsightsMetaContactCostAsc',
  InsightsMetaContactCostDesc = 'InsightsMetaContactCostDesc',
  // Customize Product
  InsightsMetaCustomizeProductAsc = 'InsightsMetaCustomizeProductAsc',
  InsightsMetaCustomizeProductDesc = 'InsightsMetaCustomizeProductDesc',
  InsightsMetaCustomizeProductCostAsc = 'InsightsMetaCustomizeProductCostAsc',
  InsightsMetaCustomizeProductCostDesc = 'InsightsMetaCustomizeProductCostDesc',
  // Donate
  InsightsMetaDonateAsc = 'InsightsMetaDonateAsc',
  InsightsMetaDonateDesc = 'InsightsMetaDonateDesc',
  InsightsMetaDonateCostAsc = 'InsightsMetaDonateCostAsc',
  InsightsMetaDonateCostDesc = 'InsightsMetaDonateCostDesc',
  // Find Location
  InsightsMetaFindLocationAsc = 'InsightsMetaFindLocationAsc',
  InsightsMetaFindLocationDesc = 'InsightsMetaFindLocationDesc',
  InsightsMetaFindLocationCostAsc = 'InsightsMetaFindLocationCostAsc',
  InsightsMetaFindLocationCostDesc = 'InsightsMetaFindLocationCostDesc',
  // Initiate Checkout
  InsightsMetaInitiateCheckoutAsc = 'InsightsMetaInitiateCheckoutAsc',
  InsightsMetaInitiateCheckoutDesc = 'InsightsMetaInitiateCheckoutDesc',
  InsightsMetaInitiateCheckoutCostAsc = 'InsightsMetaInitiateCheckoutCostAsc',
  InsightsMetaInitiateCheckoutCostDesc = 'InsightsMetaInitiateCheckoutCostDesc',
  // Lead
  InsightsMetaLeadAsc = 'InsightsMetaLeadAsc',
  InsightsMetaLeadDesc = 'InsightsMetaLeadDesc',
  InsightsMetaLeadCostAsc = 'InsightsMetaLeadCostAsc',
  InsightsMetaLeadCostDesc = 'InsightsMetaLeadCostDesc',
  // Purchase
  InsightsMetaPurchaseAsc = 'InsightsMetaPurchaseAsc',
  InsightsMetaPurchaseDesc = 'InsightsMetaPurchaseDesc',
  InsightsMetaPurchaseCostAsc = 'InsightsMetaPurchaseCostAsc',
  InsightsMetaPurchaseCostDesc = 'InsightsMetaPurchaseCostDesc',
  // Schedule
  InsightsMetaScheduleAsc = 'InsightsMetaScheduleAsc',
  InsightsMetaScheduleDesc = 'InsightsMetaScheduleDesc',
  InsightsMetaScheduleCostAsc = 'InsightsMetaScheduleCostAsc',
  InsightsMetaScheduleCostDesc = 'InsightsMetaScheduleCostDesc',
  // Search
  InsightsMetaSearchAsc = 'InsightsMetaSearchAsc',
  InsightsMetaSearchDesc = 'InsightsMetaSearchDesc',
  InsightsMetaSearchCostAsc = 'InsightsMetaSearchCostAsc',
  InsightsMetaSearchCostDesc = 'InsightsMetaSearchCostDesc',
  // Start Trial
  InsightsMetaStartTrialAsc = 'InsightsMetaStartTrialAsc',
  InsightsMetaStartTrialDesc = 'InsightsMetaStartTrialDesc',
  InsightsMetaStartTrialCostAsc = 'InsightsMetaStartTrialCostAsc',
  InsightsMetaStartTrialCostDesc = 'InsightsMetaStartTrialCostDesc',
  // Submit Application
  InsightsSubmitApplicationAsc = 'InsightsSubmitApplicationAsc',
  InsightsSubmitApplicationDesc = 'InsightsSubmitApplicationDesc',
  InsightsSubmitApplicationCostAsc = 'InsightsSubmitApplicationCostAsc',
  InsightsSubmitApplicationCostDesc = 'InsightsSubmitApplicationCostDesc',
  // Subscribe
  InsightsMetaSubscribeAsc = 'InsightsMetaSubscribeAsc',
  InsightsMetaSubscribeDesc = 'InsightsMetaSubscribeDesc',
  InsightsMetaSubscribeCostAsc = 'InsightsMetaSubscribeCostAsc',
  InsightsMetaSubscribeCostDesc = 'InsightsMetaSubscribeCostDesc',
  // View Content
  InsightsMetaViewContentAsc = 'InsightsMetaViewContentAsc',
  InsightsMetaViewContentDesc = 'InsightsMetaViewContentDesc',
  InsightsMetaViewContentCostAsc = 'InsightsMetaViewContentCostAsc',
  InsightsMetaViewContentCostDesc = 'InsightsMetaViewContentCostDesc',
  // Add To Waiting list
  InsightsMetaAddToWaitingListAsc = 'InsightsMetaAddToWaitingListAsc',
  InsightsMetaAddToWaitingListDesc = 'InsightsMetaAddToWaitingListDesc',
  InsightsMetaAddToWaitingListCostAsc = 'InsightsMetaAddToWaitingListCostAsc',
  InsightsMetaAddToWaitingListCostDesc = 'InsightsMetaAddToWaitingListCostDesc',
  // Return On Ad Apend
  InsightsMetaReturnOnAdSpendAsc = 'InsightsMetaReturnOnAdSpendAsc',
  InsightsMetaReturnOnAdSpendDesc = 'InsightsMetaReturnOnAdSpendDesc',
  InsightsMetaReturnOnAdSpendCostAsc = 'InsightsMetaReturnOnAdSpendCostAsc',
  InsightsMetaReturnOnAdSpendCostDesc = 'InsightsMetaReturnOnAdSpendCostDesc',

  InsightsTiktokSpendAsc = 'InsightsTiktokSpendAsc',
  InsightsTiktokSpendDesc = 'InsightsTiktokSpendDesc',
  InsightsTiktokAppInstallAsc = 'InsightsTiktokAppInstallAsc',
  InsightsTiktokAppInstallDesc = 'InsightsTiktokAppInstallDesc',
  InsightsTiktokAppInstallCostAsc = 'InsightsTiktokAppInstallCostAsc',
  InsightsTiktokAppInstallCostDesc = 'InsightsTiktokAppInstallCostDesc',
  InsightsTiktokViewContentAsc = 'InsightsTiktokViewContentAsc',
  InsightsTiktokViewContentDesc = 'InsightsTiktokViewContentDesc',
  InsightsTiktokViewContentCostAsc = 'InsightsTiktokViewContentCostAsc',
  InsightsTiktokViewContentCostDesc = 'InsightsTiktokViewContentCostDesc',
  InsightsTiktokAddToCartAsc = 'InsightsTiktokAddToCartAsc',
  InsightsTiktokAddToCartDesc = 'InsightsTiktokAddToCartDesc',
  InsightsTiktokAddToCartCostAsc = 'InsightsTiktokViewContentCostAsc',
  InsightsTiktokAddToCartCostDesc = 'InsightsTiktokAddToCartCostDesc',
  InsightsTiktokAddToWishlistAsc = 'InsightsTiktokAddToWishlistAsc',
  InsightsTiktokAddToWishlistDesc = 'InsightsTiktokAddToWishlistDesc',
  InsightsTiktokAddToWishlistCostAsc = 'InsightsTiktokAddToWishlistCostAsc',
  InsightsTiktokAddToWishlistCostDesc = 'InsightsTiktokAddToWishlistCostDesc',
  InsightsTiktokCompleteRegistrationAsc = 'InsightsTiktokCompleteRegistrationAsc',
  InsightsTiktokCompleteRegistrationDesc = 'InsightsTiktokCompleteRegistrationDesc',
  InsightsTiktokCompleteRegistrationCostAsc = 'InsightsTiktokCompleteRegistrationCostAsc',
  InsightsTiktokCompleteRegistrationCostDesc = 'InsightsTiktokCompleteRegistrationCostDesc',
  InsightsTiktokPurchaseAsc = 'InsightsTiktokPurchaseAsc',
  InsightsTiktokPurchaseDesc = 'InsightsTiktokPurchaseDesc',
  InsightsTiktokPurchaseCostAsc = 'InsightsTiktokPurchaseCostAsc',
  InsightsTiktokPurchaseCostDesc = 'InsightsTiktokPurchaseCostDesc',
  AdScoreAsc = 'AdScoreAsc',
  AdScoreDesc = 'AdScoreDesc',
  AdScoreMetaAsc = 'AdScoreMetaAsc',
  AdScoreMetaDesc = 'AdScoreMetaDesc',
  AdScoreTiktokAsc = 'AdScoreTiktokAsc',
  AdScoreTiktokDesc = 'AdScoreTiktokDesc',
  ShareAsc = 'ShareAsc',
  ShareDesc = 'ShareDesc',
  FBeta1Asc = 'FBeta1Asc',
  FBeta1Desc = 'FBeta1Desc',
  FBeta2Asc = 'FBeta2Asc',
  FBeta2Desc = 'FBeta2Desc',
  GeomM1Asc = 'GeomM1Asc',
  GeomM1Desc = 'GeomM1Desc',
  GeomM2Asc = 'GeomM2Asc',
  GeomM2Desc = 'GeomM2Desc',
}

export type RegisterUser = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  invitationCode: Scalars['String'];
  whitelabel?: Maybe<Scalars['String']>;
};

export type UserSearchCriteria = {
  email?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
};

export type InvitationSearchCriteria = {
  code?: Maybe<Scalars['String']>;
};

export type ClientSearchCriteria = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingInput = {
  type: Scalars['String'];
  email: Scalars['Boolean'];
  slack: Scalars['Boolean'];
};

export type AdConceptSearchCriteria = {
  clientId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BriefSearchCriteria = {
  projectId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AdSearchCriteria = {
  projectId?: Maybe<Scalars['String']>;
  type?: AdType;
  title?: Maybe<Scalars['String']>;
  internalState?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type AdConceptInput = {
  clientId: Scalars['String'];

  id?: Maybe<Scalars['String']>;
  paymentModel: ClientPaymentModel;
  title: Scalars['String'];
  deliveryDate: Scalars['String'];
  ugc: Scalars['Boolean'];

  briefId?: Maybe<Scalars['String']>;

  // *************** ROLES ***************
  accountManagerUserIds?: Maybe<Array<Scalars['String']>>;
  accountManagerNames?: Maybe<Scalars['String']>;

  creativeLeadUserIds?: Maybe<Array<Scalars['String']>>;
  creativeLeadNames?: Maybe<Scalars['String']>;

  creativeStrategistUserIds?: Maybe<Array<Scalars['String']>>;
  creativeStrategistNames?: Maybe<Scalars['String']>;

  copywriterUserIds?: Maybe<Array<Scalars['String']>>;
  copywriterNames?: Maybe<Scalars['String']>;

  graphicDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  graphicDesignerNames?: Maybe<Scalars['String']>;

  motionDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  motionDesignerNames?: Maybe<Scalars['String']>;

  illustratorUserIds?: Maybe<Array<Scalars['String']>>;
  illustratorNames?: Maybe<Scalars['String']>;

  modelUserIds?: Maybe<Array<Scalars['String']>>;
  modelNames?: Maybe<Scalars['String']>;

  photographerUserIds?: Maybe<Array<Scalars['String']>>;
  photographerNames?: Maybe<Scalars['String']>;

  videographerUserIds?: Maybe<Array<Scalars['String']>>;
  videographerNames?: Maybe<Scalars['String']>;

  creatorUserIds?: Maybe<Array<Scalars['String']>>;
  creatorNames?: Maybe<Scalars['String']>;
};

export type AdDnaSearchCriteria = {
  clientIds?: Maybe<Array<Scalars['String']>>;
  projectId?: Maybe<Scalars['String']>;

  pgId?: Maybe<Scalars['String']>;
  pgIdAuthor?: Maybe<Scalars['String']>;

  // *************** MAIN ***************
  title?: Maybe<Scalars['String']>;
  paymentModel?: Maybe<ClientPaymentModel>;
  channel?: Maybe<SocialMediaChannel>;
  layout?: Maybe<AdDnaLayout>;
  assetRatio?: Maybe<Scalars['String']>;
  assetFormat?: Maybe<AdType>;
  slidesNumber?: Maybe<Scalars['Int']>;
  videoLength?: Maybe<Scalars['Int']>;

  ctaPlacement?: Maybe<AdDnaCtaPlacement>;

  // *************** SCRIPT/COPY ***************
  copyPlain?: Maybe<Scalars['String']>;
  scriptPlain?: Maybe<Scalars['String']>;

  // *************** AD SCORE ***************
  adScore?: Maybe<Scalars['Float']>;
  adScoreMeta?: Maybe<Scalars['Float']>;
  adScoreTiktok?: Maybe<Scalars['Float']>;
};

export type AdDnaUpdateRequest = {
  // *************** MAIN ***************
  channel?: Maybe<SocialMediaChannel>;
  narrativeType?: Maybe<NarrativeType>;
  singleAssetFormatType?: Maybe<AdDnaSingleAssetFormatType>;
  singleAssetType?: Maybe<AdDnaSingleAssetType>;
  singleAssetSubType?: Maybe<AdDnaSingleAssetSubType>;
  languages?: Maybe<Array<Scalars['String']>>;
  layout?: Maybe<AdDnaLayout>;

  // ************** GRAB ATTENTION ***************
  visualHookBranded?: Maybe<Scalars['Boolean']>;
  visualHookFeatures?: Maybe<Array<Scalars['String']>>;
  visualHookUnusual?: Maybe<Scalars['Boolean']>;
  visualHookRelevance?: Maybe<Array<AdDnaVisualHookRelevance>>;
  copyHook?: Maybe<Scalars['String']>;
  copyHookPlain?: Maybe<Scalars['String']>;
  copyHookFraming?: Maybe<Array<AdDnaCopyHookFraming>>;
  copyHookTypology?: Maybe<Array<AdDnaCopyHookTypology>>;
  copyHookRelevance?: Maybe<Array<AdDnaCopyHookRelevance>>;
  popularFormats?: Maybe<Array<AdDnaPopularFormat>>;
  references?: Maybe<Array<AdDnaReferences>>;
  trend?: Maybe<Array<AdDnaTrend>>;
  sound?: Maybe<Array<AdDnaSound>>;
  aiContent?: Maybe<Scalars['Boolean']>;

  // ************** HOLD ATTENTION ***************
  ugcCreators?: Maybe<Array<AdDnaUgcCreators>>;
  visualBranded?: Maybe<Scalars['Boolean']>;
  visualFeatures?: Maybe<Array<Scalars['String']>>;
  copy?: Maybe<Scalars['String']>;
  copyPlain?: Maybe<Scalars['String']>;
  script?: Maybe<Scalars['String']>;
  scriptPlain?: Maybe<Scalars['String']>;
  painPoints?: Maybe<Array<Scalars['String']>>;
  locationSpecificity?: Maybe<Scalars['Boolean']>;
  personas?: Maybe<Array<ClientPersona>>;
  benefits?: Maybe<Array<Scalars['String']>>;
  productFeatures?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  valuePropositions?: Maybe<Array<Scalars['String']>>;
  educating?: Maybe<Scalars['Boolean']>;
  educatingApp?: Maybe<Scalars['Boolean']>;
  educatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  educatingAppService?: Maybe<Scalars['Boolean']>;
  educatingBroadTopic?: Maybe<Scalars['Boolean']>;
  userCentricity?: Maybe<Scalars['Int']>;
  specificity?: Maybe<Scalars['Int']>;
  statistics?: Maybe<Scalars['Boolean']>;
  simpleLanguage?: Maybe<Array<AdDnaSimpleLanguage>>;
  legalDisclaimer?: Maybe<Scalars['Boolean']>;
  subtitles?: Maybe<Scalars['Boolean']>;
  entertaining?: Maybe<Scalars['Boolean']>;
  anticipation?: Maybe<Scalars['Boolean']>;

  // ************** EVOKE ACTION ***************
  financialBenefit?: Maybe<Array<AdDnaFinancialBenefit>>;
  instantValue?: Maybe<Array<AdDnaInstantValue>>;
  socialProof?: Maybe<Array<AdDnaSocialProof>>;
  authority?: Maybe<Array<AdDnaAuthority>>;
  lossAversion?: Maybe<Array<AdDnaLossAversion>>;
  scarcity?: Maybe<Scalars['Boolean']>;
  unity?: Maybe<Scalars['Boolean']>;
  deeperMotivationalDesire?: Maybe<Scalars['Int']>;
  sellTheProductSellTheFeeling?: Maybe<Scalars['Int']>;
  ctas?: Maybe<Array<Scalars['String']>>;
  ctaPlacement?: AdDnaCtaPlacement;

  // *************** ROLES ***************
  roles?: Maybe<Array<AdDnaRole>>;

  accountManagerUserIds?: Maybe<Array<Scalars['String']>>;
  accountManagerNames?: Maybe<Scalars['String']>;

  creativeLeadUserIds?: Maybe<Array<Scalars['String']>>;
  creativeLeadNames?: Maybe<Scalars['String']>;

  creativeStrategistUserIds?: Maybe<Array<Scalars['String']>>;
  creativeStrategistNames?: Maybe<Scalars['String']>;

  copywriterUserIds?: Maybe<Array<Scalars['String']>>;
  copywriterNames?: Maybe<Scalars['String']>;

  graphicDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  graphicDesignerNames?: Maybe<Scalars['String']>;

  motionDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  motionDesignerNames?: Maybe<Scalars['String']>;

  illustratorUserIds?: Maybe<Array<Scalars['String']>>;
  illustratorNames?: Maybe<Scalars['String']>;

  modelUserIds?: Maybe<Array<Scalars['String']>>;
  modelNames?: Maybe<Scalars['String']>;

  photographerUserIds?: Maybe<Array<Scalars['String']>>;
  photographerNames?: Maybe<Scalars['String']>;

  videographerUserIds?: Maybe<Array<Scalars['String']>>;
  videographerNames?: Maybe<Scalars['String']>;

  creatorUserIds?: Maybe<Array<Scalars['String']>>;
  creatorNames?: Maybe<Scalars['String']>;

  ideatorUserIds?: Maybe<Array<Scalars['String']>>;
  ideatorNames?: Maybe<Scalars['String']>;

  // *************** SM INSIGHTS ***************
  insightsSpend?: Maybe<Scalars['Float']>;
  insightsAppInstall?: Maybe<Scalars['Int']>;
  insightsAppInstallCost?: Maybe<Scalars['Float']>;
  insightsCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsViewContent?: Maybe<Scalars['Int']>;
  insightsViewContentCost?: Maybe<Scalars['Float']>;

  // META
  insightsMetaSpend?: Maybe<Scalars['Float']>;
  insightsMetaAppInstall?: Maybe<Scalars['Int']>;
  insightsMetaAppInstallCost?: Maybe<Scalars['Float']>;
  insightsMetaAddPaymentInfo?: Maybe<Scalars['Int']>;
  insightsMetaAddPaymentInfoCost?: Maybe<Scalars['Float']>;
  insightsMetaAddToCart?: Maybe<Scalars['Int']>;
  insightsMetaAddToCartCost?: Maybe<Scalars['Float']>;
  insightsMetaAddToWishlist?: Maybe<Scalars['Int']>;
  insightsMetaAddToWishlistCost?: Maybe<Scalars['Float']>;
  insightsMetaCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsMetaCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsMetaContact?: Maybe<Scalars['Int']>;
  insightsMetaContactCost?: Maybe<Scalars['Float']>;
  insightsMetaCustomizeProduct?: Maybe<Scalars['Int']>;
  insightsMetaCustomizeProductCost?: Maybe<Scalars['Float']>;
  insightsMetaDonate?: Maybe<Scalars['Int']>;
  insightsMetaDonateCost?: Maybe<Scalars['Float']>;
  insightsMetaFindLocation?: Maybe<Scalars['Int']>;
  insightsMetaFindLocationCost?: Maybe<Scalars['Float']>;
  insightsMetaInitiateCheckout?: Maybe<Scalars['Int']>;
  insightsMetaInitiateCheckoutCost?: Maybe<Scalars['Float']>;
  insightsMetaLead?: Maybe<Scalars['Int']>;
  insightsMetaLeadCost?: Maybe<Scalars['Float']>;
  insightsMetaPurchase?: Maybe<Scalars['Int']>;
  insightsMetaPurchaseCost?: Maybe<Scalars['Float']>;
  insightsMetaSchedule?: Maybe<Scalars['Int']>;
  insightsMetaScheduleCost?: Maybe<Scalars['Float']>;
  insightsMetaSearch?: Maybe<Scalars['Int']>;
  insightsMetaSearchCost?: Maybe<Scalars['Float']>;
  insightsMetaStartTrial?: Maybe<Scalars['Int']>;
  insightsMetaStartTrialCost?: Maybe<Scalars['Float']>;
  insightsMetaSubmitApplication?: Maybe<Scalars['Int']>;
  insightsMetaSubmitApplicationCost?: Maybe<Scalars['Float']>;
  insightsMetaSubscribe?: Maybe<Scalars['Int']>;
  insightsMetaSubscribeCost?: Maybe<Scalars['Float']>;
  insightsMetaViewContent?: Maybe<Scalars['Int']>;
  insightsMetaViewContentCost?: Maybe<Scalars['Float']>;
  insightsMetaAddToWaitingList?: Maybe<Scalars['Int']>;
  insightsMetaAddToWaitingListCost?: Maybe<Scalars['Float']>;
  insightsMetaReturnOnAdSpend?: Maybe<Scalars['Int']>;
  insightsMetaReturnOnAdSpendCost?: Maybe<Scalars['Float']>;

  // TIKTOK
  insightsTiktokSpend?: Maybe<Scalars['Float']>;
  insightsTiktokAppInstall?: Maybe<Scalars['Int']>;
  insightsTiktokAppInstallCost?: Maybe<Scalars['Float']>;
  insightsTiktokCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsTiktokCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsTiktokPurchase?: Maybe<Scalars['Int']>;
  insightsTiktokPurchaseCost?: Maybe<Scalars['Float']>;
  insightsTiktokViewContent?: Maybe<Scalars['Int']>;
  insightsTiktokViewContentCost?: Maybe<Scalars['Float']>;

  // *************** AD SCORE ***************
  adScore?: Maybe<Scalars['Float']>;
  adScoreMeta?: Maybe<Scalars['Float']>;
  adScoreTiktok?: Maybe<Scalars['Float']>;
};
