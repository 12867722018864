import { gql } from '@apollo/client';
import { fragments } from './queries';

export const updateUser = gql`
  mutation (
    $email: String
    $firstName: String
    $lastName: String
    $profilePic: String
    $clientTeamRole: ClientTeamRole
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      profilePic: $profilePic
      clientTeamRole: $clientTeamRole
    ) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const updateProjectViewLayout = gql`
  mutation ($layout: ProjectViewLayout!) {
    updateProjectViewLayout(layout: $layout) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const updateOnboardingStep = gql`
  mutation ($step: OnboardingStep!) {
    updateOnboardingStep(step: $step) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const updateDataColumns = gql`
  mutation ($name: String!, $columnNames: [String!]!) {
    updateDataColumns(name: $name, columnNames: $columnNames) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const removeDataColumns = gql`
  mutation ($name: String!) {
    removeDataColumns(name: $name) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const requestEmailChange = gql`
  mutation ($email: String, $newEmail: String!) {
    requestEmailChange(email: $email, newEmail: $newEmail) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const changePassword = gql`
  mutation ($email: String, $currentPassword: String!, $newPassword: String!) {
    changePassword(email: $email, currentPassword: $currentPassword, newPassword: $newPassword) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const requestUserDeletion = gql`
  mutation ($email: String) {
    requestUserDeletion(email: $email)
  }
`;

export const updateUserNotificationSettings = gql`
  mutation ($email: String, $settings: [UserNotificationSettingInput!]!) {
    updateUserNotificationSettings(email: $email, settings: $settings) {
      ...UserNotificationSettings
    }
  }
  ${fragments.userNotificationSettings}
  ${fragments.userNotificationSetting}
`;

export const createInvitation = gql`
  mutation (
    $email: String!
    $role: String!
    $clientId: String
    $clientTeamRole: ClientTeamRole
    $projectId: String
    $whitelabelId: String
  ) {
    createInvitation(
      email: $email
      role: $role
      clientId: $clientId
      clientTeamRole: $clientTeamRole
      projectId: $projectId
      whitelabelId: $whitelabelId
    ) {
      ...Invitation
    }
  }
  ${fragments.invitation}
`;

export const createClient = gql`
  mutation ($input: InputClient!) {
    createClient(input: $input) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const updateClient = gql`
  mutation ($input: InputClient!) {
    updateClient(input: $input) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const updateClientDescription = gql`
  mutation ($newDescription: String!) {
    updateClientDescription(newDescription: $newDescription) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const deleteClient = gql`
  mutation ($id: String!) {
    deleteClient(id: $id)
  }
`;

export const addDataSource = gql`
  mutation (
    $clientId: String
    $platform: AdDnaInsightsPlatform!
    $adAccountId: String!
    $conversionWindow: AdAccountConversionWindow!
    $reportTime: AdAccountReportTime!
  ) {
    addDataSource(
      clientId: $clientId
      adAccountId: $adAccountId
      platform: $platform
      conversionWindow: $conversionWindow
      reportTime: $reportTime
    ) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const deleteDataSource = gql`
  mutation ($clientId: String, $platform: AdDnaInsightsPlatform!, $adAccountId: String!) {
    deleteDataSource(clientId: $clientId, platform: $platform, adAccountId: $adAccountId) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const updateDataSource = gql`
  mutation (
    $clientId: String
    $platform: AdDnaInsightsPlatform!
    $adAccountId: String!
    $events: [String!]
    $starMetric1: String
    $starMetric2: String
  ) {
    updateDataSource(
      clientId: $clientId
      platform: $platform
      adAccountId: $adAccountId
      events: $events
      starMetric1: $starMetric1
      starMetric2: $starMetric2
    ) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const updateDataSourceUgcAdNames = gql`
  mutation ($clientId: String, $platform: AdDnaInsightsPlatform!, $adAccountId: String!, $names: [String!]) {
    updateDataSourceUgcAdNames(clientId: $clientId, platform: $platform, adAccountId: $adAccountId, names: $names) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const createWhitelabel = gql`
  mutation (
    $domain: String!
    $name: String
    $logoContent: String
    $logoName: String
    $faviconContent: String
    $faviconName: String
    $metaDescription: String
  ) {
    createWhitelabel(
      domain: $domain
      name: $name
      logoContent: $logoContent
      logoName: $logoName
      faviconContent: $faviconContent
      faviconName: $faviconName
      metaDescription: $metaDescription
    ) {
      ...Whitelabel
    }
  }
  ${fragments.whitelabel}
`;

export const updateWhitelabel = gql`
  mutation (
    $id: String!
    $domain: String
    $name: String
    $logoContent: String
    $logoName: String
    $faviconContent: String
    $faviconName: String
    $metaDescription: String
  ) {
    updateWhitelabel(
      id: $id
      domain: $domain
      name: $name
      logoContent: $logoContent
      logoName: $logoName
      faviconContent: $faviconContent
      faviconName: $faviconName
      metaDescription: $metaDescription
    ) {
      ...Whitelabel
    }
  }
  ${fragments.whitelabel}
`;

export const deleteWhitelabel = gql`
  mutation ($id: String!) {
    deleteWhitelabel(id: $id)
  }
`;
