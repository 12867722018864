import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LOGIN, PASSWORD_CHANGE, PASSWORD_RESET, REGISTER } from 'utils/routingUtils';
import ChangePassword from '../changePassword';
import ResetPassword from '../resetPassword';
import Register from '../register';
import Login from '../login';

/**
 * Handles public home where user is not yet logged in.
 */
export default function Home(): JSX.Element {
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={PASSWORD_RESET} element={<ResetPassword />} />
      <Route path={REGISTER} element={<Register />} />
      <Route path={PASSWORD_CHANGE} element={<ChangePassword />} />
      <Route path="*" element={<Navigate to={LOGIN} />} />
    </Routes>
  );
}
