import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import { baseErrorNotification } from 'utils/notificationUtils';
import { openInNewTab, reloadPage } from 'utils/windowUtils';
import { toast } from 'react-toastify';
import { containsNumber, hasUpperCase } from 'utils/validationUtils';
import { COOKIE_REFRESH_TOKEN, LOGIN, REGISTER, URL_QUERY_PARAM_TOKEN, useURLSearchParams } from 'utils/routingUtils';
import {
  emailMaxLength,
  firstNameMaxLength,
  getPrivacyPolicy,
  getTermsAndConditions,
  lastNameMaxLength,
  passwordMaxLength,
  passwordMinLength,
} from 'utils/userUtils';
import { register, registerWithGoogle } from 'services/user.service';
import { login } from 'services/auth.service';
import { Link, Button, Input, Loading, Paragraph } from 'components/common';
import { ReactComponent as OpenEyeIcon } from 'assets/img/openEye.svg';
import { ReactComponent as ClosedEyeIcon } from 'assets/img/closedEye.svg';
import { ReactComponent as Check } from 'assets/img/check.svg';
import { ReactComponent as Close } from 'assets/img/close.svg';
import { ReactComponent as GoogleIcon } from 'assets/img/google.svg';
import { ReactComponent as AdsDnaIcon } from 'assets/img/adsDnaSecond.svg';
import ToasterInfo from 'components/common/toasterInfo';
import { RegisterUser } from 'types/inputTypes';
import { getContactUsMessage } from 'utils/i18nUtils';
import { Divider } from 'antd';

import styles from './Register.module.scss';
import { createCookie } from 'utils/cookieUtils';

interface Values {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(emailMaxLength, t('VALIDATION.EMAIL_MAX_LENGTH', { length: emailMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(emailMaxLength, t('VALIDATION.EMAIL_MAX_LENGTH', { length: emailMaxLength }))
          .optional(),
    firstName: validateOnSubmit
      ? Yup.string()
          .max(firstNameMaxLength, t('VALIDATION.FIRST_NAME_MAX_LENGTH', { length: firstNameMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(firstNameMaxLength, t('VALIDATION.FIRST_NAME_MAX_LENGTH', { length: firstNameMaxLength }))
          .optional(),
    lastName: validateOnSubmit
      ? Yup.string()
          .max(lastNameMaxLength, t('VALIDATION.LAST_NAME_MAX_LENGTH', { length: lastNameMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(lastNameMaxLength, t('VALIDATION.LAST_NAME_MAX_LENGTH', { length: lastNameMaxLength }))
          .optional(),
    password: validateOnSubmit
      ? Yup.string()
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .optional(),
  });
};

export interface LoginLocationState {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = useURLSearchParams().get(URL_QUERY_PARAM_TOKEN) || '';

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const passwordValidation = useMemo(
    () => ({
      passwordSizeValidation: {
        valid: password.length >= passwordMinLength,
        message: t('VALIDATION.PASSWORD_MIN_CHARACTERS'),
      },
      passwordNumberValidation: {
        valid: containsNumber(password),
        message: t('VALIDATION.PASSWORD_NUMBERS'),
      },
      passwordUpperCaseValidation: {
        valid: hasUpperCase(password),
        message: t('VALIDATION.PASSWORD_UPPERCASE'),
      },
    }),
    [password, t],
  );

  const showErrorNotification = useCallback(
    (title: string) => {
      const message: string = t(getContactUsMessage());
      toast.error(<ToasterInfo type="error" title={title} description={message} />, { ...baseErrorNotification });
    },
    [t],
  );

  /**
   * Tries to log in. If succeeds then refreshes the page, otherwise redirects to the login page.
   */
  const doLogin = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        const result = await login(email, password, false);
        if (result) {
          // update the page and let user start working
          reloadPage();
        } else {
          showErrorNotification(t('PUBLIC.LOGIN.FAILED'));
          navigate(LOGIN);
        }
      } catch {
        showErrorNotification(t('PUBLIC.LOGIN.FAILED'));
        navigate(LOGIN);
      }
    },
    [navigate, showErrorNotification, t],
  );

  /**
   * Tries to register and if succeeds tries to log in
   */
  const handleSubmit = useCallback(
    async (
      values: Values,
      setSubmitting: (isSubmitting: boolean) => void,
      setErrors: (arg: LoginLocationState) => void,
    ): Promise<void> => {
      if (Object.values(passwordValidation).some((validation) => !validation.valid)) {
        setErrors({ password: t('PUBLIC.SIGN_UP.PASSWORD_REQUIREMENTS') });
      } else {
        setSubmitTriggered(true);
        setSubmitting(true);

        const userData = {
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          email: values.email,
          invitationCode: '',
        } as RegisterUser;

        try {
          const res = await register(userData);
          if (res) {
            await doLogin(values.email, values.password);
          } else {
            showErrorNotification(t('PUBLIC.SIGN_UP.FAILED'));
            setSubmitting(false);
          }
        } catch {
          showErrorNotification(t('PUBLIC.SIGN_UP.FAILED'));
          setSubmitting(false);
        }
      }
    },
    [passwordValidation, t, doLogin, showErrorNotification],
  );

  const handleSubmitForm = useCallback(
    (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) =>
      handleSubmit(values, setSubmitting, setErrors),
    [handleSubmit],
  );

  const handleRegisterWithGoogle = useCallback(() => {
    registerWithGoogle(REGISTER);
  }, []);

  if (token) {
    // save refresh token in a cookie and refresh the page, 2min must be enough(BE creates a JWT token with the same expiration)
    const secure = !!process.env.REACT_APP_HTTPS ? process.env.REACT_APP_HTTPS == 'true' : true;
    createCookie(COOKIE_REFRESH_TOKEN, token, 120, secure, true);
    reloadPage();
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperDescription}>
        <div className={styles.description}>
          <div className={styles.header}>
            <AdsDnaIcon /> {t('PUBLIC.SIGN_UP.ADS_DNA')}
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>{t('PUBLIC.SIGN_UP.IMPORT_YOUR_ADS')}</div>
            <div className={styles.sectionDescription}>{t('PUBLIC.SIGN_UP.IMPORT_YOUR_ADS_DESC')}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>{t('PUBLIC.SIGN_UP.TELL_US_ABOUT_YOUR_COMPANY')}</div>
            <div className={styles.sectionDescription}>{t('PUBLIC.SIGN_UP.TELL_US_ABOUT_YOUR_COMPANY_DESC')}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>{t('PUBLIC.SIGN_UP.FIND_OUT_WHAT_MAKES_WINNING_ADS_SUCCESSFUL')}</div>
            <div className={styles.sectionDescription}>
              {t('PUBLIC.SIGN_UP.FIND_OUT_WHAT_MAKES_WINNING_ADS_SUCCESSFUL_DESC')}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.signUp}>
        <div className={styles.card}>
          <div className={styles.loginTitle}>{t('PUBLIC.SIGN_UP.SIGN_UP')}</div>

          <Formik
            validationSchema={validationSchema(t, submitTriggered)}
            onSubmit={handleSubmitForm}
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
            }}
          >
            {({ handleChange, handleBlur, values, errors, isSubmitting }) => (
              <Form noValidate className={styles.content}>
                <Input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.SIGN_UP.EMAIL')} <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <Input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={errors.firstName}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.SIGN_UP.FIRST_NAME')} <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <Input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={errors.lastName}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.SIGN_UP.LAST_NAME')} <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                    setPassword(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  icon={
                    showPassword ? (
                      <OpenEyeIcon onClick={() => setShowPassword(false)} />
                    ) : (
                      <ClosedEyeIcon onClick={() => setShowPassword(true)} />
                    )
                  }
                  alignIcon="right"
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.LOGIN.PASSWORD')}
                        <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <div className={styles.errorList}>
                  {Object.values(passwordValidation).map((validation, index) => (
                    <div className={styles.passwordRequirements} key={index}>
                      {validation.valid ? <Check className={styles.icon} /> : <Close className={styles.icon} />}
                      <Paragraph size="small">{validation.message} </Paragraph>
                    </div>
                  ))}
                </div>
                <div className={styles.formButton}>
                  <Button
                    className={styles.formButton}
                    type="submit"
                    disabled={
                      !values.email ||
                      !values.firstName ||
                      !values.lastName ||
                      !values.password ||
                      !!errors.email ||
                      !!errors.firstName ||
                      !!errors.lastName ||
                      !!errors.password ||
                      isSubmitting
                    }
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {isSubmitting ? <Loading /> : t('PUBLIC.NAVIGATION.SIGN_UP')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          <Divider>{t('COMMON.OR')}</Divider>

          {/* Google */}
          <Button
            className={styles.googleBtn}
            icon={<GoogleIcon />}
            size="small"
            buttonType="secondary"
            onClick={handleRegisterWithGoogle}
          >
            {t('PUBLIC.SIGN_UP.CONTINUE_WITH_GOOGLE')}
          </Button>

          <div className={styles.linkContainer}>
            <Paragraph className={styles.linkContainerText}>{t('PUBLIC.SIGN_UP.HAVE_ACCOUNT')}</Paragraph>
            <Link className={styles.bottomLink} href={LOGIN}>
              {t('PUBLIC.NAVIGATION.LOG_IN')}
            </Link>
          </div>

          <div className={styles.agreement}>
            <Paragraph size="small" className={styles.agreementStatement}>
              {t('PUBLIC.SIGN_UP.YOU_AGREE')}
              <span className={styles.terms} onClick={() => openInNewTab(getTermsAndConditions())}>
                {t('PUBLIC.SIGN_UP.TERMS')}
              </span>
              {t('PUBLIC.SIGN_UP.AND')}
              <span className={styles.terms} onClick={() => openInNewTab(getPrivacyPolicy())}>
                {t('PUBLIC.SIGN_UP.PRIVACY_POLICY')}
              </span>
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
