import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'classnames';
import { logout } from 'services/auth.service';
import { Dropdown, Paragraph } from 'components/common';
import { ReactComponent as ChevronIcon } from 'assets/img/chevron.svg';
import { ReactComponent as WhiteChevronIcon } from 'assets/img/whiteChevron.svg';
import { RESET, useTrackedSelector } from 'components/app/store';
import { Client, OnboardingStep, PaintgunProducts, SecurityRole } from 'types/types.d';
import styles from './LoggedInFrame.module.scss';
import {
  ADMIN_CLIENTS,
  ADMIN_INVITATIONS,
  ADMIN_SETTINGS,
  ADMIN_USERS,
  ADMIN_WHITELABELS,
  ANALYTICS,
  ASSETS,
  BUSINESS,
  DATA,
  SETTINGS,
  TEAM,
  TEST,
} from 'utils/routingUtils';
import { Props } from 'components/features/private/loggedInFrame/type';
import { userHasAdminRole, userHasSession } from 'utils/userUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { buildAdsDnaUrl } from 'components/features/private/adsDna/AdsDnaProvider';
import { buildDashboardUrl } from 'components/features/private/dashboard/DashboardProvider';
import { getLogoBlack, getLogoWhite } from 'utils/styleUtils';
import { buildAnalyticsUrl } from 'components/features/private/analytics/AnalyticsProvider';
import { buildTestUrl } from 'components/features/private/test/TestProvider';
import { useLazyQuery } from '@apollo/client';
import { getClient } from 'graphql/queries';

const LoggedInFrame = ({ children, color = 'white' }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    pathname,
    searchParams: { [SearchParams.CLIENT_ID]: _clientId = '', [SearchParams.CONCEPT_ID]: _projectId = '' },
  } = useCustomLocation();

  const {
    auth: { user, whitelabel },
  } = useTrackedSelector();

  const [doFetchClient, { data: clientData, loading: clientLoading, error: clientError }] = useLazyQuery<{
    getClient?: Client;
  }>(getClient);

  const client = useMemo(
    () => (!clientLoading && !clientError && clientData?.getClient ? clientData.getClient : null),
    [clientData?.getClient, clientError, clientLoading],
  );

  const finalWhitelabel = useMemo(() => whitelabel?.id, [whitelabel?.id]);

  const navBarStyle = useMemo(
    () =>
      clsx(styles.navBar, {
        [styles.white]: color === 'white',
        [styles.black]: color === 'black',
      }),
    [color],
  );

  // const notificationBackground = useMemo(
  //   () =>
  //     clsx(styles.notificationBackground, {
  //       [styles.notificationWhite]: color === 'white',
  //       [styles.notificationBlack]: color === 'black',
  //     }),
  //   [color],
  // );

  const hasSession = useMemo(() => userHasSession(user), [user]);

  const menu = useMemo(() => {
    switch (user?.role) {
      case SecurityRole.ADMIN:
        return !finalWhitelabel?.length
          ? [
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.CLIENTS'), route: ADMIN_CLIENTS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.USERS'), route: ADMIN_USERS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.INVITATIONS'), route: ADMIN_INVITATIONS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.WHITELABELS'), route: ADMIN_WHITELABELS },
            ]
          : [
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.CLIENTS'), route: ADMIN_CLIENTS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.USERS'), route: ADMIN_USERS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.INVITATIONS'), route: ADMIN_INVITATIONS },
            ];
      case SecurityRole.PG_USER:
        return [
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.TEST'),
            route: TEST,
            onNavigate: () =>
              navigate(
                buildTestUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                }),
              ),
          },
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ASSETS'),
            route: ASSETS,
            onNavigate: () =>
              navigate(
                buildDashboardUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                  [SearchParams.CONCEPT_ID]: _projectId,
                }),
              ),
          },
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DATA'),
            route: DATA,
            onNavigate: () =>
              navigate(
                buildAdsDnaUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                  [SearchParams.CONCEPT_ID]: _projectId,
                }),
              ),
          },
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ANALYTICS'),
            route: ANALYTICS,
            onNavigate: () =>
              navigate(
                buildAnalyticsUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                  [SearchParams.CONCEPT_ID]: _projectId,
                }),
              ),
          },
          // {
          //   label: t('PRIVATE.LOGGED_IN_FRAME.MENU.AUDIT'),
          //   route: AUDIT,
          //   onNavigate: () => navigate(buildAuditUrl({})),
          // },
          // {
          //   label: t('PRIVATE.LOGGED_IN_FRAME.MENU.AGENCY'),
          //   route: AGENCY,
          //   onNavigate: () =>
          //     navigate(
          //       buildAgencyUrl({
          //         [SearchParams.CLIENT_ID]: _clientId,
          //       }),
          //     ),
          // },
        ];
      case SecurityRole.CREATOR:
        return [{ label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ASSETS'), route: ASSETS }];
      case SecurityRole.CLIENT_ADMIN:
      case SecurityRole.CLIENT_USER:
      case SecurityRole.SAAS_ADMIN:
      case SecurityRole.SAAS_USER:
        if (
          [SecurityRole.SAAS_ADMIN, SecurityRole.SAAS_USER].includes(user?.role) &&
          user?.onboardingStep !== OnboardingStep.COMPLETED
        ) {
          return [];
        }

        return [
          ...(client?.disabledProducts?.includes(PaintgunProducts.TEST)
            ? []
            : [
                {
                  label: t('PRIVATE.LOGGED_IN_FRAME.MENU.TEST'),
                  route: TEST,
                  onNavigate: () =>
                    navigate(
                      buildTestUrl({
                        [SearchParams.CLIENT_ID]: _clientId,
                      }),
                    ),
                },
              ]),
          ...(client?.disabledProducts?.includes(PaintgunProducts.ASSETS)
            ? []
            : [
                {
                  label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ASSETS'),
                  route: ASSETS,
                  onNavigate: () =>
                    navigate(
                      buildDashboardUrl({
                        [SearchParams.CLIENT_ID]: _clientId,
                        [SearchParams.CONCEPT_ID]: _projectId,
                      }),
                    ),
                },
              ]),
          ...(client?.disabledProducts?.includes(PaintgunProducts.DATA)
            ? []
            : [
                {
                  label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DATA'),
                  route: DATA,
                  onNavigate: () =>
                    navigate(
                      buildAdsDnaUrl({
                        [SearchParams.CLIENT_ID]: _clientId,
                        [SearchParams.CONCEPT_ID]: _projectId,
                      }),
                    ),
                },
              ]),
          ...(client?.disabledProducts?.includes(PaintgunProducts.ANALYTICS)
            ? []
            : [
                {
                  label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ANALYTICS'),
                  route: ANALYTICS,
                  onNavigate: () =>
                    navigate(
                      buildAnalyticsUrl({
                        [SearchParams.CLIENT_ID]: _clientId,
                        [SearchParams.CONCEPT_ID]: _projectId,
                      }),
                    ),
                },
              ]),
        ];
      default:
        return [];
    }
  }, [
    _clientId,
    _projectId,
    client?.disabledProducts,
    finalWhitelabel?.length,
    navigate,
    t,
    user?.onboardingStep,
    user?.role,
  ]);

  const handleLogout = useCallback(async () => {
    const response = await logout();
    if (response) {
      dispatch(RESET);
    }
  }, [dispatch]);

  const dropdownVariants = useMemo(() => {
    const teamOption =
      user?.role &&
      [SecurityRole.CLIENT_ADMIN, SecurityRole.CLIENT_USER, SecurityRole.SAAS_ADMIN, SecurityRole.SAAS_USER].includes(
        user.role,
      )
        ? [{ name: t('PRIVATE.LOGGED_IN_FRAME.MENU.TEAM'), id: 'team' }]
        : [];
    const business =
      user?.role &&
      [SecurityRole.CLIENT_ADMIN, SecurityRole.CLIENT_USER, SecurityRole.SAAS_ADMIN, SecurityRole.SAAS_USER].includes(
        user.role,
      )
        ? [{ name: t('PRIVATE.LOGGED_IN_FRAME.MENU.BUSINESS'), id: 'business' }]
        : [];

    const otherOptions = [
      { name: t('PRIVATE.LOGGED_IN_FRAME.SETTINGS'), id: 'settings' },
      { name: t('PRIVATE.LOGGED_IN_FRAME.LOG_OUT'), id: 'logOut' },
    ];

    if (
      user?.role &&
      [SecurityRole.SAAS_ADMIN, SecurityRole.SAAS_USER].includes(user.role) &&
      user?.onboardingStep !== OnboardingStep.COMPLETED
    ) {
      return [{ name: t('PRIVATE.LOGGED_IN_FRAME.LOG_OUT'), id: 'logOut' }];
    }

    return [...teamOption, ...business, ...otherOptions];
  }, [t, user?.onboardingStep, user?.role]);

  const handleDropdownValueSelect = useCallback(
    (option: { name: string; id: string }) => {
      if (option.id === 'logOut') {
        handleLogout();
      } else if (option.id === 'settings') {
        navigate(userHasAdminRole(user) ? `/${ADMIN_SETTINGS}` : `/${SETTINGS}`);
      } else if (option.id === 'business') {
        navigate(`/${BUSINESS}`);
      } else if (option.id === 'team') {
        navigate(`/${TEAM}`);
      }
    },
    [handleLogout, navigate, user],
  );

  useEffect(() => {
    if (!!_clientId) {
      doFetchClient({ variables: { id: _clientId } });
    }
  }, [_clientId, doFetchClient]);

  return (
    <div className={styles.mainContainer}>
      <div className={navBarStyle}>
        <div className={styles.logoAndMenu}>
          <span className={styles.logo}>
            {!!whitelabel?.logoContent?.length ? (
              <img src={whitelabel.logoContent} alt={'logo'} />
            ) : color === 'white' ? (
              getLogoBlack()
            ) : (
              getLogoWhite()
            )}
          </span>
          {menu.map((menuItem, index) => (
            <span
              key={index}
              className={clsx(styles.menuItem, {
                [styles.menuItemHighlighted]: pathname.startsWith(`/${menuItem.route}`),
              })}
              onClick={() => {
                if ('onNavigate' in menuItem && menuItem.onNavigate) {
                  menuItem.onNavigate();
                } else {
                  navigate(`/${menuItem.route}`);
                }
              }}
            >
              {menuItem.label}
            </span>
          ))}
        </div>
        {hasSession && (
          <div className={styles.iconContainer}>
            {/*<div className={styles.notificationContainer}>*/}
            {/*  {color === 'white' ? <NotificationIcon /> : <WhiteNotificationIcon />}*/}
            {/*  <div className={notificationBackground}>*/}
            {/*    <div className={styles.notification} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {user?.profilePic && <img src={user.profilePic} alt="" className={styles.profilePic} />}
            <Dropdown chooseValue={handleDropdownValueSelect} variants={dropdownVariants}>
              <div className={styles.userName}>
                <Paragraph size="medium">{t('PRIVATE.LOGGED_IN_FRAME.WELCOME', { name: user?.firstName })}</Paragraph>
                <span className={styles.dropDownIcon}>
                  {color === 'white' ? <ChevronIcon /> : <WhiteChevronIcon />}
                </span>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default LoggedInFrame;
