/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Slider } from 'antd';
import { Button, Checkbox, RadioButton, TooltipWrapper } from 'components/common';
import { getFullName } from 'utils/userUtils';
import { getContactUsMessage, getLocalisedLanguage } from 'utils/i18nUtils';
import DropDownInput from 'components/common/dropdownInput';
import { Client, LanguageCode, SecurityRole, User } from 'types/types.d';
import {
  AdDna,
  AdDnaAuthority,
  AdDnaCopyHookFraming,
  AdDnaCopyHookRelevance,
  AdDnaCopyHookTypology,
  AdDnaLossAversion,
  AdDnaPopularFormat,
  AdDnaRoleType,
  AdDnaSimpleLanguage,
  AdDnaSingleAssetFormatType,
  AdDnaSingleAssetSubType,
  AdDnaSingleAssetType,
  AdDnaSocialProof,
  AdDnaSound,
  AdDnaUgcCreators,
  AdDnaVisualFeature,
  AdDnaVisualHookRelevance,
  NarrativeType,
} from 'types/businessTypes.d';
import { ReactComponent as ArrowLeftIcon } from 'assets/img/arrowLeft.svg';
import { ReactComponent as AppScreenIcon } from 'assets/img/appScreen.svg';
import { ReactComponent as AppScreenLightIcon } from 'assets/img/appScreenLight.svg';
import { ReactComponent as ProductIcon } from 'assets/img/product.svg';
import { ReactComponent as ProductLightIcon } from 'assets/img/productLight.svg';
import { ReactComponent as BankCardIcon } from 'assets/img/bankCard.svg';
import { ReactComponent as BankCardLightIcon } from 'assets/img/bankCardLight.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as MoneyIcon } from 'assets/img/money.svg';
import { ReactComponent as MoneyLightIcon } from 'assets/img/moneyLight.svg';
import { ReactComponent as ArIcon } from 'assets/img/arCube.svg';
import { ReactComponent as ArLightIcon } from 'assets/img/arCubeLight.svg';
import { ReactComponent as EmojisIcon } from 'assets/img/emojis.svg';
import { ReactComponent as AppStoresIcon } from 'assets/img/appStores.svg';
import { ReactComponent as LogoSmallIcon } from 'assets/img/logoAgencySmall.svg';
import { ReactComponent as LogoSmallWhiteIcon } from 'assets/img/logoAgencySmallWhite.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/img/chevronDown.svg';
import VisualFeature from 'components/features/private/adsDna/adDnaDetails/VisualFeature';
import { capitalizeFirstLetter, makeId } from 'utils/stringUtils';
import cloneDeep from 'lodash/cloneDeep';
import CtaPlacement from 'components/features/private/adsDna/adDnaDetails/CtaPlacement';
import { AdDnaUpdateRequest } from 'types/inputTypes';
import { Variant } from 'components/common/dropdownMenu/types';
import clsx from 'classnames';
import { useMutation } from '@apollo/client';
import { updateAdDnas } from 'graphql/businessMutations';
import logger from 'utils/logger/logger';
import { toast } from 'react-toastify';
import ToasterInfo from 'components/common/toasterInfo';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { black_0, black_300, black_500, black_700 } from 'utils/styleUtils';
import MultiSelectDropDown from 'components/common/multiSelectDropdown';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import styles from './MultiEditModal.module.scss';

interface Props {
  ids: string[];
  clients: Client[];
  selectedClientId: string;
  users: User[];
  onHide: () => void;
  onDone: () => void;
}

export default function MultiEditModal({ ids, clients, selectedClientId, users, onHide, onDone }: Props): JSX.Element {
  const { t } = useTranslation();

  const dnaContent = useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sectionRefs = {
    mainRef: useRef<HTMLDivElement>(null),
    grabAttentionRef: useRef<HTMLDivElement>(null),
    holdAttentionRef: useRef<HTMLDivElement>(null),
    evokeActionRef: useRef<HTMLDivElement>(null),
    rolesRef: useRef<HTMLDivElement>(null),
  };

  const [doUpdateAdDnas] = useMutation<{ updateAdDnas: AdDna[] }>(updateAdDnas);

  const [updateRequest, setUpdateRequest] = useState<AdDnaUpdateRequest>({});
  const [copyHookEditorState, setCopyHookEditorState] = useState(() => EditorState.createEmpty());
  const [copyEditorState, setCopyEditorState] = useState(() => EditorState.createEmpty());
  const [script, setScript] = useState(() => EditorState.createEmpty());
  const [activeTabId, setActiveTabId] = useState('0');

  const clientsById = useMemo(() => new Map(clients.map((c) => [c.id, c])), [clients]);
  const client = useMemo(() => clientsById.get(selectedClientId || ''), [clientsById, selectedClientId]);

  const creatorAndPgUsers = useMemo(
    () => [
      ...users
        .filter(({ role }) => [SecurityRole.CREATOR, SecurityRole.PG_USER].includes(role))
        .map(
          (i) =>
            ({
              id: i.id,
              name: getFullName(i),
            } as Variant),
        ),
    ],
    [users],
  );

  const visualFeaturesList = useMemo(() => {
    const clientFeatures = clientsById.get(selectedClientId || '')?.visualFeatures || [];

    return [
      ...[
        {
          feature: AdDnaVisualFeature.PAINTGUN_LOGO.toString(),
          text: t('PRIVATE.USER.ADS_DNA.PAINTGUN_LOGO'),
          selectedIcon: <LogoSmallIcon />,
          icon: <LogoSmallWhiteIcon />,
        },
        {
          feature: AdDnaVisualFeature.APP_SCREEN.toString(),
          text: t('PRIVATE.USER.ADS_DNA.APP_SCREEN'),
          selectedIcon: <AppScreenIcon />,
          icon: <AppScreenLightIcon />,
        },
        {
          feature: AdDnaVisualFeature.PRODUCT.toString(),
          text: t('PRIVATE.USER.ADS_DNA.PRODUCT'),
          selectedIcon: <ProductIcon />,
          icon: <ProductLightIcon />,
        },
        {
          feature: AdDnaVisualFeature.BANK_CARD.toString(),
          text: t('PRIVATE.USER.ADS_DNA.BANK_CARD'),
          selectedIcon: <BankCardIcon />,
          icon: <BankCardLightIcon />,
        },
        {
          feature: AdDnaVisualFeature.MONEY.toString(),
          text: t('PRIVATE.USER.ADS_DNA.MONEY'),
          selectedIcon: <MoneyIcon />,
          icon: <MoneyLightIcon />,
        },
        {
          feature: AdDnaVisualFeature.AR.toString(),
          text: t('PRIVATE.USER.ADS_DNA.AR'),
          selectedIcon: <ArIcon />,
          icon: <ArLightIcon />,
        },
        {
          feature: AdDnaVisualFeature.EMOJIS.toString(),
          text: t('PRIVATE.USER.ADS_DNA.EMOJIS'),
          selectedIcon: <EmojisIcon />,
          icon: <EmojisIcon />,
        },
        {
          feature: AdDnaVisualFeature.APP_STORES.toString(),
          text: t('PRIVATE.USER.ADS_DNA.APP_STORES'),
          selectedIcon: <AppStoresIcon />,
          icon: <AppStoresIcon />,
        },
      ],
      ...(!!clientFeatures.length
        ? clientFeatures.map((i) => ({
            feature: i,
            text: i,
            selectedIcon: <ProductIcon />,
            icon: <ProductLightIcon />,
          }))
        : []),
    ];
  }, [clientsById, selectedClientId, t]);

  // combination of system accounts & creators defined in the client but having no system accounts
  const roleVariants = useMemo(() => {
    const client = clientsById.get(selectedClientId || '') || {};

    return Object.fromEntries(
      Object.entries(client).map(([role, items]) => [
        role,
        (Array.isArray(items) ? items : []).map((i) => ({ id: i, name: i } as Variant)),
      ]),
    );
  }, [clientsById, selectedClientId]);

  const { creators, creativeStrategists, creativeLeads, copywriters, ideators, graphicDesigners, motionDesigners } =
    roleVariants;

  const tabs = useMemo(() => {
    return [
      { title: t('PRIVATE.USER.ADS_DNA.MAIN'), active: activeTabId === '0', id: '0' },
      { title: t('PRIVATE.USER.ADS_DNA.GRAB_ATTENTION'), active: activeTabId === '1', id: '1' },
      { title: t('PRIVATE.USER.ADS_DNA.HOLD_ATTENTION'), active: activeTabId === '2', id: '2' },
      { title: t('PRIVATE.USER.ADS_DNA.EVOKE_ACTION'), active: activeTabId === '3', id: '3' },
      { title: t('PRIVATE.USER.ADS_DNA.ROLES'), active: activeTabId === '4', id: '4' },
    ];
  }, [activeTabId, t]);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const handleTabClick = useCallback(
    (id: string) => {
      setActiveTabId(id);

      let ref = null;
      switch (id) {
        case '0':
          ref = sectionRefs.mainRef;
          break;
        case '1':
          ref = sectionRefs.grabAttentionRef;
          break;
        case '2':
          ref = sectionRefs.holdAttentionRef;
          break;
        case '3':
          ref = sectionRefs.evokeActionRef;
          break;
        case '4':
          ref = sectionRefs.rolesRef;
          break;
      }

      if (ref) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const offset = document.getElementById(ref?.current?.id)?.offsetTop - 160;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (dnaContent) document.getElementById(dnaContent?.current?.id).scrollTo({ top: offset });
      }
    },
    [sectionRefs],
  );

  const handleSubmit = useCallback((): void => {
    if (JSON.stringify(updateRequest).toString() === '{}') {
      onHide();
      return;
    }

    doUpdateAdDnas({ variables: { ids, request: updateRequest } })
      .then((result) => {
        if (result?.data?.updateAdDnas?.length) {
          showSuccessNotification(t('PRIVATE.USER.ADS_DNA.MULTI_EDIT.SUCCESS'));
          onDone();
        } else {
          logger.error(`Error updating ad dnas`);
          showErrorNotification();
        }
      })
      .catch((e) => {
        logger.error(`Error updating ad dnas: ${e}`);
        showErrorNotification();
      });
  }, [doUpdateAdDnas, ids, onDone, onHide, showErrorNotification, showSuccessNotification, t, updateRequest]);

  // Automatic scrolling for sections
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const visibleSection = Object.entries(sectionRefs).find(([, ref]) => ref.current?.id === entry.target.id);
            if (visibleSection) {
              setActiveTabId(Object.keys(sectionRefs).indexOf(visibleSection[0]).toString());
            }
          }
        });
      },
      {
        root: dnaContent.current,
        rootMargin: '0px 0px -50% 0px',
        threshold: 0.1,
      },
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  return (
    <Modal
      open={true}
      footer={null}
      closeIcon={null}
      maskClosable={false}
      onCancel={onHide}
      width="100vw"
      style={{ top: 0, maxWidth: '100%', paddingBottom: 0 }}
      className={styles.modal}
      wrapClassName={styles.wrapClassName}
      getContainer={false}
    >
      <div className={styles.header}>
        <div className={styles.btnBack} onClick={onHide}>
          <ArrowLeftIcon />
          <div>{t('PRIVATE.USER.ADS_DNA.BACK_TO_DATA')}</div>
        </div>
        <div>
          {ids.length > 1
            ? t('PRIVATE.USER.ADS_DNA.MULTI_EDIT.TITLE_MULTIPLE', { count: ids.length })
            : t('PRIVATE.USER.ADS_DNA.MULTI_EDIT.TITLE')}
        </div>
        <div className={styles.btns}>
          <Button buttonType="secondary" onClick={onHide}>
            {t('COMMON.CANCEL')}
          </Button>
          <Button buttonType="primary" type="button" onClick={handleSubmit}>
            {t('COMMON.SAVE')}
          </Button>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.dna}>
          <div className={styles.sectionsNav}>
            {tabs.map(({ title, active, id }) => (
              <div
                key={id}
                className={clsx(styles.sectionsNavItem, { [styles.sectionsNavItemActive]: active })}
                onClick={() => {
                  if (!active) handleTabClick(id);
                }}
              >
                {title}
              </div>
            ))}
          </div>

          <div className={styles.dnaContent} ref={dnaContent} id="dnaContent">
            {/************ MAIN ************/}
            <div ref={sectionRefs.mainRef} id="mainSection" />
            <div className={`${styles.dnaContentCard}`}>
              <div className={styles.sectionTitle}>{t('PRIVATE.USER.ADS_DNA.MAIN')}</div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  {t('PRIVATE.USER.ADS_DNA.PGID_AUTHOR')}
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.PGID_AUTHOR')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <DropDownInput
                    className={styles.dropdown}
                    icon={<ChevronDownIcon />}
                    darkMode
                    takeValue={(name, id) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, pgIdAuthor: id }));
                    }}
                    variants={creatorAndPgUsers}
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  {t('PRIVATE.USER.ADS_DNA.NARRATIVE_TYPE')}
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.NARRATIVE_TYPE')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <DropDownInput
                    className={styles.dropdown}
                    icon={<ChevronDownIcon />}
                    darkMode
                    takeValue={(name, id) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, narrativeType: id }));
                    }}
                    variants={
                      Object.values(NarrativeType)?.map((i) => ({
                        id: i,
                        name: capitalizeFirstLetter(i.toLowerCase().replaceAll('_', ' ')) || '',
                        selected: !!updateRequest.narrativeType?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <>
                <div className={styles.item}>
                  <div className={clsx(styles.itemLabel)}>
                    {t('PRIVATE.USER.ADS_DNA.ASSET_TYPE')}
                    <div>
                      <TooltipWrapper
                        id={makeId()}
                        place="bottom"
                        msg={t('PRIVATE.USER.ADS_DNA.ASSET_TYPE')}
                        content={<QuestionCircleOutlined rev={undefined} />}
                        tooltipClassName={styles.tooltip}
                      />
                    </div>
                  </div>
                  <div className={styles.itemInput}>
                    <DropDownInput
                      className={styles.dropdown}
                      takeValue={(_arg, id) => {
                        setUpdateRequest((prev) => ({
                          ...prev,
                          singleAssetType: id === AdDnaSingleAssetType.NONE ? null : (id as AdDnaSingleAssetType),
                        }));
                      }}
                      variants={(updateRequest.singleAssetFormatType === AdDnaSingleAssetFormatType.STATIC
                        ? [
                            AdDnaSingleAssetType.NONE,
                            AdDnaSingleAssetType.CP,
                            AdDnaSingleAssetType.GRAPHIC_DESIGN,
                            AdDnaSingleAssetType.ILLUSTRATION,
                            AdDnaSingleAssetType.PHOTOGRAPHY,
                          ]
                        : [AdDnaSingleAssetType.MOTION, AdDnaSingleAssetType.LIVE_ACTION]
                      ).map((i) => ({
                        name:
                          i === AdDnaSingleAssetType.NONE
                            ? ''
                            : capitalizeFirstLetter(i.toLowerCase().replace('_', ' ')) || '',
                        selected: updateRequest.singleAssetType === i,
                        id: i,
                      }))}
                    />
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={clsx(styles.itemLabel)}>
                    {t('PRIVATE.USER.ADS_DNA.ASSET_SUB_TYPE')}
                    <div>
                      <TooltipWrapper
                        id={makeId()}
                        place="bottom"
                        msg={t('PRIVATE.USER.ADS_DNA.ASSET_SUB_TYPE')}
                        content={<QuestionCircleOutlined rev={undefined} />}
                        tooltipClassName={styles.tooltip}
                      />
                    </div>
                  </div>
                  <div className={styles.itemInput}>
                    <DropDownInput
                      className={styles.dropdown}
                      takeValue={(_arg, id) => {
                        setUpdateRequest((prev) => ({
                          ...prev,
                          singleAssetSubType:
                            id === AdDnaSingleAssetSubType.NONE ? null : (id as AdDnaSingleAssetSubType),
                        }));
                      }}
                      variants={Object.values(AdDnaSingleAssetSubType).map((i) => ({
                        id: i,
                        name: capitalizeFirstLetter(i.toLowerCase().replaceAll('_', ' ')) || '',
                        selected: i === updateRequest.singleAssetSubType,
                      }))}
                    />
                  </div>
                </div>
              </>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.POPULAR_FORMATS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.POPULAR_FORMATS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => {
                        return { ...prev, popularFormats: values.map(({ id }) => id as AdDnaPopularFormat) };
                      });
                    }}
                    variants={
                      Object.values(AdDnaPopularFormat)?.map((i) => ({
                        id: i,
                        name: t(`PRIVATE.USER.ADS_DNA.${i}`),
                        selected: !!updateRequest.popularFormats?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <div>{t('PRIVATE.USER.ADS_DNA.LANGUAGE')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.LANGUAGE_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setUpdateRequest((prev) => {
                        return { ...prev, languages: values.map(({ id }) => id) };
                      });
                    }}
                    variants={
                      Object.values(LanguageCode).map((i) => ({
                        id: i,
                        name: getLocalisedLanguage(i, t),
                        selected: updateRequest.languages?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>
            </div>

            {/************ GRAB ATTENTION ************/}
            <div ref={sectionRefs.grabAttentionRef} id="grabAttentionSection" />
            <div className={`${styles.dnaContentCard}`}>
              <div className={styles.sectionTitle}>{t('PRIVATE.USER.ADS_DNA.GRAB_ATTENTION')}</div>
              <div className={styles.sectionDescription}>{t('PRIVATE.USER.ADS_DNA.GRAB_ATTENTION_DESC')}</div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_BRANDED')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_BRANDED_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          visualHookBranded: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.visualHookBranded !== undefined
                          ? updateRequest.visualHookBranded === (i === 'Yes')
                          : false
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel} style={{ alignSelf: 'flex-start' }}>
                  <div>{t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_FEATURES')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_FEATURES_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <div className={styles.visualFeatures}>
                    {visualFeaturesList.map(({ feature, text, icon, selectedIcon }) => (
                      <VisualFeature
                        key={feature}
                        active={updateRequest.visualHookFeatures?.includes(feature) || false}
                        icon={updateRequest.visualHookFeatures?.includes(feature) ? selectedIcon : icon}
                        text={text}
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          setUpdateRequest((prev) => {
                            let newArray = cloneDeep(updateRequest.visualHookFeatures || []);
                            if ((prev?.visualHookFeatures || [])?.includes(feature)) {
                              newArray = newArray.filter((i) => i !== feature);
                            } else newArray.push(feature);
                            return { ...prev, visualHookFeatures: newArray };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_RELEVANCE')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_RELEVANCE_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {Object.values(AdDnaVisualHookRelevance).map((ref) => (
                    <Checkbox
                      key={ref}
                      className={styles.checkbox}
                      checked={updateRequest.visualHookRelevance?.includes(ref) || false}
                      label={t(`PRIVATE.USER.ADS_DNA.VISUAL_HOOK_RELEVANCE_${ref}`)}
                      labelAfter={true}
                      labelClassName={styles.firstSecond}
                      onChange={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          let newArray = cloneDeep(updateRequest.visualHookRelevance || []);
                          if ((prev?.visualHookRelevance || [])?.includes(ref)) {
                            newArray = newArray.filter((i) => i !== ref);
                          } else newArray.push(ref);
                          return { ...prev, visualHookRelevance: newArray };
                        });
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel} style={{ alignSelf: 'flex-start' }}>
                  <div>{t('PRIVATE.USER.ADS_DNA.COPY_HOOK')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.COPY_HOOK_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <div className={styles.richEditor}>
                    <Editor
                      editorStyle={{ height: '10rem', backgroundColor: black_500, color: black_0 }}
                      toolbarStyle={{ backgroundColor: black_700 }}
                      editorState={copyHookEditorState}
                      onEditorStateChange={(newState) => {
                        setCopyHookEditorState(newState);
                        setUpdateRequest((prev) => ({
                          ...prev,
                          copyHook: draftToHtml(convertToRaw(newState.getCurrentContent())),
                        }));
                      }}
                      toolbarClassName={styles.richEditorToolbar}
                      editorClassName={styles.richEditorEditor}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.COPY_HOOK_FRAMING')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.COPY_HOOK_FRAMING_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {Object.values(AdDnaCopyHookFraming).map((i) => (
                    <RadioButton
                      key={i}
                      style={{ marginRight: '1rem' }}
                      label={t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_FRAMING_${i}`)}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          return {
                            ...prev,
                            copyHookFraming: prev?.copyHookFraming?.includes(i) ? [] : [i],
                          };
                        });
                      }}
                      checked={updateRequest.copyHookFraming?.includes(i) || false}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.COPY_HOOK_TYPOLOGY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.COPY_HOOK_TYPOLOGY_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {Object.values(AdDnaCopyHookTypology).map((ref) => (
                    <Checkbox
                      key={ref}
                      className={styles.checkbox}
                      checked={updateRequest.copyHookTypology?.includes(ref) || false}
                      label={t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_TYPOLOGY_${ref}`)}
                      labelAfter={true}
                      labelClassName={styles.firstSecond}
                      onChange={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          let newArray = cloneDeep(updateRequest.copyHookTypology || []);
                          if ((prev?.copyHookTypology || [])?.includes(ref)) {
                            newArray = newArray.filter((i) => i !== ref);
                          } else newArray.push(ref);
                          return { ...prev, copyHookTypology: newArray };
                        });
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.COPY_HOOK_RELEVANCE')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.COPY_HOOK_RELEVANCE_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {Object.values(AdDnaCopyHookRelevance).map((ref) => (
                    <Checkbox
                      key={ref}
                      className={styles.checkbox}
                      checked={updateRequest.copyHookRelevance?.includes(ref) || false}
                      label={t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_RELEVANCE_${ref}`)}
                      labelAfter={true}
                      labelClassName={styles.firstSecond}
                      onChange={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          let newArray = cloneDeep(updateRequest.copyHookRelevance || []);
                          if ((prev?.copyHookRelevance || [])?.includes(ref)) {
                            newArray = newArray.filter((i) => i !== ref);
                          } else newArray.push(ref);
                          return { ...prev, copyHookRelevance: newArray };
                        });
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SOUND')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SOUND_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <div className={styles.item}>
                    <div className={styles.itemInput}>
                      <MultiSelectDropDown
                        takeValues={(values) => {
                          // @ts-ignore
                          setUpdateRequest((prev) => {
                            return { ...prev, sound: values.map(({ id }) => id) };
                          });
                        }}
                        variants={
                          Object.values(AdDnaSound)?.map((i) => ({
                            id: i,
                            name: t(`PRIVATE.USER.ADS_DNA.SOUND_${i}`),
                            selected: !!updateRequest.sound?.includes(i),
                          })) || []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/************ HOLD ATTENTION ************/}
            <div ref={sectionRefs.holdAttentionRef} id="holdAttentionSection" />
            <div className={`${styles.dnaContentCard}`}>
              <div className={styles.sectionTitle}>{t('PRIVATE.USER.ADS_DNA.HOLD_ATTENTION')}</div>
              <div className={styles.sectionDescription}>{t('PRIVATE.USER.ADS_DNA.HOLD_ATTENTION_DESC')}</div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.UGC_CREATORS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.UGC_CREATORS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {Object.values(AdDnaUgcCreators).map((i) => (
                    <RadioButton
                      key={i}
                      label={t(`PRIVATE.USER.ADS_DNA.UGC_CREATORS_${i}`)}
                      alignLabel="right"
                      style={{ paddingRight: '1rem' }}
                      value={i}
                      onChange={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          return {
                            ...prev,
                            ugcCreators: prev?.ugcCreators?.includes(i) ? [] : [i],
                          };
                        });
                      }}
                      checked={updateRequest.ugcCreators?.includes(i) || false}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>
                    {/* {adDna.singleAssetType === AdDnaSingleAssetType.UGC
                      ? t('PRIVATE.USER.ADS_DNA.VISUAL_BRANDED')
                      : t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_BRANDED')} */}
                    {t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_BRANDED')}
                  </div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={
                        // adDna.singleAssetType === AdDnaSingleAssetType.UGC
                        //   ? t('PRIVATE.USER.ADS_DNA.VISUAL_BRANDED_INFO')
                        //   : t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_BRANDED_INFO')
                        t('PRIVATE.USER.ADS_DNA.VISUAL_HOOK_BRANDED_INFO')
                      }
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          visualBranded: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.visualBranded !== undefined
                          ? updateRequest.visualBranded === (i === 'Yes')
                          : false
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel} style={{ alignSelf: 'flex-start' }}>
                  <div>{t('PRIVATE.USER.ADS_DNA.VISUAL_FEATURES')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.VISUAL_FEATURES_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <div className={styles.visualFeatures}>
                    {visualFeaturesList.map(({ feature, text, icon, selectedIcon }) => (
                      <VisualFeature
                        key={feature}
                        active={updateRequest.visualFeatures?.includes(feature) || false}
                        icon={updateRequest.visualFeatures?.includes(feature) ? selectedIcon : icon}
                        text={text}
                        onClick={() => {
                          // @ts-ignore
                          setUpdateRequest((prev) => {
                            let newArray = cloneDeep(updateRequest.visualFeatures || []);
                            if ((prev?.visualFeatures || [])?.includes(feature)) {
                              newArray = newArray.filter((i) => i !== feature);
                            } else newArray.push(feature);
                            return { ...prev, visualFeatures: newArray };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel} style={{ alignSelf: 'flex-start' }}>
                  <div>{t('PRIVATE.USER.ADS_DNA.COPY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.COPY_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <div className={styles.richEditor}>
                    <Editor
                      editorStyle={{ height: '10rem', backgroundColor: black_500, color: black_0 }}
                      toolbarStyle={{ backgroundColor: black_700 }}
                      editorState={copyEditorState}
                      onEditorStateChange={(newState) => {
                        setCopyEditorState(newState);
                        setUpdateRequest((prev) => ({
                          ...prev,
                          copy: draftToHtml(convertToRaw(newState.getCurrentContent())),
                        }));
                      }}
                      toolbarClassName={styles.richEditorToolbar}
                      editorClassName={styles.richEditorEditor}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel} style={{ alignSelf: 'flex-start' }}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SCRIPT')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SCRIPT')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput}>
                  <div className={styles.richEditor}>
                    <Editor
                      editorStyle={{ height: '10rem', backgroundColor: black_500, color: black_0 }}
                      toolbarStyle={{ backgroundColor: black_700 }}
                      editorState={script}
                      onEditorStateChange={(newState) => {
                        setScript(newState);
                        setUpdateRequest((prev) => ({
                          ...prev,
                          script: draftToHtml(convertToRaw(newState.getCurrentContent())),
                        }));
                      }}
                      toolbarClassName={styles.richEditorToolbar}
                      editorClassName={styles.richEditorEditor}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.LOCATION_SPECIFICITY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.LOCATION_SPECIFICITY')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          locationSpecificity: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.locationSpecificity !== undefined
                          ? updateRequest.locationSpecificity === (i === 'Yes')
                          : false
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.PAIN_POINTS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.PAIN_POINTS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({
                        ...prev,
                        painPoints: values.map(({ id }) => id),
                      }));
                    }}
                    variants={
                      client?.painPoints?.map((i) => ({
                        id: i,
                        name: i,
                        selected: updateRequest.painPoints?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.PERSONAS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.PERSONAS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => ({
                        ...prev,
                        personas: client?.personas?.filter(({ name }) => values.map(({ id }) => id).includes(name)),
                      }));
                    }}
                    variants={
                      client?.personas?.map(({ name, description }) => ({
                        id: name,
                        name: name,
                        tooltip: description || undefined,
                        selected: !!updateRequest.personas?.map(({ name }) => name)?.includes(name),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.BENEFITS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.BENEFITS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => ({ ...prev, benefits: values.map(({ id }) => id) }));
                    }}
                    variants={
                      client?.benefits?.map((i) => ({
                        id: i,
                        name: i,
                        selected: updateRequest.benefits?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.PRODUCT_FEATURES')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.PRODUCT_FEATURES_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => ({ ...prev, productFeatures: values.map(({ id }) => id) }));
                    }}
                    variants={
                      client?.productFeatures?.map((i) => ({
                        id: i,
                        name: i,
                        selected: updateRequest.productFeatures?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.PRODUCTS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.PRODUCTS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => ({ ...prev, products: values.map(({ id }) => id) }));
                    }}
                    variants={
                      client?.products?.map((i) => ({
                        id: i,
                        name: i,
                        selected: updateRequest.products?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.VALUE_PROPOSITIONS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.VALUE_PROPOSITIONS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => ({
                        ...prev,
                        valuePropositions: values.map(({ id }) => id),
                      }));
                    }}
                    variants={
                      client?.valuePropositions?.map((i) => ({
                        id: i,
                        name: i,
                        selected: updateRequest.valuePropositions?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.USER_CENTRICITY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.USER_CENTRICITY_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ alignSelf: 'center' }}>
                  <Slider
                    className={styles.slider}
                    value={updateRequest?.userCentricity || 1}
                    min={1}
                    max={10}
                    trackStyle={{ backgroundColor: black_0 }}
                    railStyle={{ backgroundColor: black_300 }}
                    onChange={(value) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, userCentricity: value }));
                    }}
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SPECIFICITY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SPECIFICITY_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ alignSelf: 'center' }}>
                  <Slider
                    className={styles.slider}
                    value={updateRequest?.specificity || 1}
                    min={1}
                    max={10}
                    trackStyle={{ backgroundColor: black_0 }}
                    railStyle={{ backgroundColor: black_300 }}
                    onChange={(value) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, specificity: value }));
                    }}
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.STATISTICS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.STATISTICS_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          statistics: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.statistics !== undefined ? updateRequest.statistics === (i === 'Yes') : false
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SIMPLE_LANGUAGE')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SIMPLE_LANGUAGE_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {[
                    AdDnaSimpleLanguage.NO_JARGON,
                    AdDnaSimpleLanguage.MINIMAL_JARGON,
                    AdDnaSimpleLanguage.YES_JARGON,
                  ].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(`PRIVATE.USER.ADS_DNA.SIMPLE_LANGUAGE_${i}`)}
                      alignLabel="right"
                      value={i}
                      onChange={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          return {
                            ...prev,
                            simpleLanguage: prev?.simpleLanguage?.includes(i) ? [] : [i],
                          };
                        });
                      }}
                      checked={
                        i === AdDnaSimpleLanguage.NO_JARGON &&
                        updateRequest.simpleLanguage?.includes(AdDnaSimpleLanguage.SIMPLE_LANGUAGE)
                          ? true
                          : updateRequest.simpleLanguage?.includes(i) || false
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.LEGAL_DISCLAIMER')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.LEGAL_DISCLAIMER_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          legalDisclaimer: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.legalDisclaimer !== undefined
                          ? updateRequest.legalDisclaimer === (i === 'Yes')
                          : false
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SUBTITLES')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SUBTITLES_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          subtitles: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.subtitles !== undefined ? updateRequest.subtitles === (i === 'Yes') : false
                      }
                    />
                  ))}
                </div>
              </div>
            </div>

            {/************ EVOKE ACTION ************/}
            <div ref={sectionRefs.evokeActionRef} id="evokeActionSection" />
            <div className={`${styles.dnaContentCard}`}>
              <div className={styles.sectionTitle}>{t('PRIVATE.USER.ADS_DNA.EVOKE_ACTION')}</div>
              <div className={styles.sectionDescription}>{t('PRIVATE.USER.ADS_DNA.EVOKE_ACTION_DESC')}</div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SOCIAL_PROOF')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SOCIAL_PROOF_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => {
                        return { ...prev, socialProof: values.map(({ id }) => id) };
                      });
                    }}
                    variants={
                      Object.values(AdDnaSocialProof)?.map((i) => ({
                        id: i,
                        name: t(`PRIVATE.USER.ADS_DNA.SOCIAL_PROOF_${i}`),
                        selected: !!updateRequest.socialProof?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.AUTHORITY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.AUTHORITY_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => {
                        return { ...prev, authority: values.map(({ id }) => id) };
                      });
                    }}
                    variants={
                      Object.values(AdDnaAuthority)?.map((i) => ({
                        id: i,
                        name: t(`PRIVATE.USER.ADS_DNA.AUTHORITY_${i}`),
                        selected: !!updateRequest.authority?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.LOSS_AVERSION')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.LOSS_AVERSION_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {Object.values(AdDnaLossAversion).map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(`PRIVATE.USER.ADS_DNA.LOSS_AVERSION_${i}`)}
                      alignLabel="right"
                      value={i}
                      onChange={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => {
                          return {
                            ...prev,
                            lossAversion: prev?.lossAversion?.includes(i) ? [] : [i],
                          };
                        });
                      }}
                      checked={updateRequest.lossAversion?.includes(i) || false}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SCARCITY')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.SCARCITY_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ display: 'flex', gap: '1.5rem' }}>
                  {['Yes', 'No'].map((i) => (
                    <RadioButton
                      key={i}
                      darkMode
                      label={t(i === 'Yes' ? 'COMMON.YES' : 'COMMON.NO')}
                      alignLabel="right"
                      value={i}
                      onClick={() => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          scarcity: i === 'Yes',
                        }));
                      }}
                      checked={
                        updateRequest.scarcity !== undefined ? updateRequest.scarcity === (i === 'Yes') : i === 'No'
                      }
                    />
                  ))}
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.DEEPER_MOTIVATION_DESIRE')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.DEEPER_MOTIVATION_DESIRE_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div className={styles.itemInput} style={{ alignSelf: 'center' }}>
                  <Slider
                    className={styles.slider}
                    value={updateRequest?.deeperMotivationalDesire || 1}
                    min={1}
                    max={10}
                    trackStyle={{ backgroundColor: black_0 }}
                    railStyle={{ backgroundColor: black_300 }}
                    onChange={(value) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, deeperMotivationalDesire: value }));
                    }}
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.SELL_THE_PRODUCT_SELL_THE_FEELING')}</div>
                </div>
                <div className={styles.itemInput} style={{ alignSelf: 'center' }}>
                  <Slider
                    className={styles.slider}
                    value={updateRequest?.sellTheProductSellTheFeeling || 1}
                    min={1}
                    max={10}
                    trackStyle={{ backgroundColor: black_0 }}
                    railStyle={{ backgroundColor: black_300 }}
                    onChange={(value) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({
                        ...prev,
                        sellTheProductSellTheFeeling: value,
                      }));
                    }}
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={clsx(styles.itemLabel)}>
                  <div>{t('PRIVATE.USER.ADS_DNA.CTAS')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.CTAS')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <MultiSelectDropDown
                    takeValues={(values) => {
                      setUpdateRequest((prev) => ({ ...prev, ctas: values.map(({ id }) => id) }));
                    }}
                    variants={
                      client?.ctas?.map((i) => ({
                        id: i,
                        name: i,
                        selected: updateRequest.ctas?.includes(i),
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.itemLabel} style={{ height: '100%', alignSelf: 'flex-start' }}>
                  <div>{t('PRIVATE.USER.ADS_DNA.CTA_PLACEMENT')}</div>
                  <div>
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={t('PRIVATE.USER.ADS_DNA.CTA_PLACEMENT_INFO')}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <CtaPlacement
                    value={updateRequest.ctaPlacement || null}
                    onClick={(value) => {
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, ctaPlacement: value }));
                    }}
                  />
                </div>
              </div>
            </div>

            {/************ ROLES ************/}
            <div ref={sectionRefs.rolesRef} id="rolesSection" />
            <div className={`${styles.dnaContentCard}`}>
              <div className={styles.sectionTitle}>{t('PRIVATE.USER.ADS_DNA.ROLES')}</div>
              <div className={styles.sectionDescription}>{t('PRIVATE.USER.ADS_DNA.ROLES_DESC')}</div>
              {[
                {
                  idField: 'creativeLeadUserId',
                  nameField: 'creativeLeadName',
                  role: AdDnaRoleType.CREATIVE_LEAD,
                  label: t('PRIVATE.USER.ADS_DNA.CREATIVE_LEAD'),
                },
                {
                  idField: 'ideatorUserId',
                  nameField: 'ideatorName',
                  role: AdDnaRoleType.IDEATOR,
                  label: t('PRIVATE.USER.ADS_DNA.IDEATOR'),
                },
                {
                  idField: 'creativeStrategistUserId',
                  nameField: 'creativeStrategistName',
                  role: AdDnaRoleType.CREATIVE_STRATEGIST,
                  label: t('PRIVATE.USER.ADS_DNA.CREATIVE_STRATEGIST'),
                },
                {
                  idField: 'copywriterUserId',
                  nameField: 'copywriterName',
                  role: AdDnaRoleType.COPYWRITER,
                  label: t('PRIVATE.USER.ADS_DNA.COPYWRITER'),
                },
                {
                  idField: 'graphicDesignerUserId',
                  nameField: 'graphicDesignerName',
                  role: AdDnaRoleType.GRAPHIC_DESIGNER,
                  label: t('PRIVATE.USER.ADS_DNA.GRAPHIC_DESIGNER'),
                },
                {
                  idField: 'motionDesignerUserId',
                  nameField: 'motionDesignerName',
                  role: AdDnaRoleType.MOTION_DESIGNER,
                  label: t('PRIVATE.USER.ADS_DNA.MOTION_DESIGNER'),
                },
                {
                  idField: 'creatorUserId',
                  nameField: 'creatorName',
                  role: AdDnaRoleType.CREATOR,
                  label: t('PRIVATE.USER.ADS_DNA.CREATOR'),
                },
              ].map(({ idField, nameField, role, label }) => (
                <div key={role} className={styles.item}>
                  <div className={clsx(styles.itemLabel)}>
                    {label}
                    <TooltipWrapper
                      id={makeId()}
                      place="bottom"
                      msg={label}
                      content={<QuestionCircleOutlined rev={undefined} />}
                      tooltipClassName={styles.tooltip}
                    />
                  </div>
                  <div className={clsx(styles.itemInput)}>
                    <MultiSelectDropDown
                      takeValues={(values) => {
                        // @ts-ignore
                        setUpdateRequest((prev) => ({
                          ...prev,
                          [`${idField}s`]: values.map(({ id }) => id),
                          [`${nameField}s`]: values.map(({ name }) => name).join(', '),
                        }));
                      }}
                      variants={
                        (role === AdDnaRoleType.CREATOR
                          ? creators
                          : role === AdDnaRoleType.CREATIVE_LEAD
                          ? creativeLeads
                          : role === AdDnaRoleType.CREATIVE_STRATEGIST
                          ? creativeStrategists
                          : role === AdDnaRoleType.COPYWRITER
                          ? copywriters
                          : role === AdDnaRoleType.IDEATOR
                          ? ideators
                          : role === AdDnaRoleType.GRAPHIC_DESIGNER
                          ? graphicDesigners
                          : role === AdDnaRoleType.MOTION_DESIGNER
                          ? motionDesigners
                          : creatorAndPgUsers
                        )?.map(({ id, name }) => {
                          // @ts-ignore
                          const selected = !!updateRequest[`${idField}s`]?.length
                            ? // @ts-ignore
                              updateRequest[`${idField}s`]
                            : [];

                          return {
                            id: id,
                            name: name,
                            selected: selected.includes(id),
                          };
                        }) || []
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
