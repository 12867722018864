import { TFunction, useTranslation } from 'react-i18next';
import moment, { LocaleSpecification, Moment } from 'moment';
import logger from './logger/logger';
import { LanguageCode } from 'types/types.d';

export const defaultDateFormat = 'dd.mm.yyyy';
export const iso8601DateFormat = 'YYYY-MM-DD';

/**
 * This is a place to define any Moment lib customisations.
 */
export const useCustomisedMomentLocales = (): void => {
  const { i18n } = useTranslation();

  [LanguageCode.EN].forEach((language) => {
    const data = i18n.getDataByLanguage(language);

    if (data && data.translation) {
      const dateAndTime = JSON.parse(JSON.stringify(data.translation['DATE_AND_TIME']));
      moment.defineLocale(LanguageCode.EN, {
        monthsShort: dateAndTime.MONTHS.SHORT_ALL.split(','),
        months: dateAndTime.MONTHS.LONG_ALL.split(','),
        weekdaysMin: dateAndTime.WEEK_DAYS.MIN_ALL.split(','),
        weekdaysShort: dateAndTime.WEEK_DAYS.SHORT_ALL.split(','),
        weekdays: dateAndTime.WEEK_DAYS.LONG_ALL.split(','),
      } as LocaleSpecification);
    } else {
      logger.error(`${language} i18n is required`);
    }
  });
};

export const useLocalisedMonthPeriodFormat = (): string => {
  const { t } = useTranslation();
  return t('DATE_AND_TIME.MONTH_FORMAT');
};

export const useLocalisedDayPeriodFormat = (): string => {
  const { t } = useTranslation();
  return t('DATE_AND_TIME.DATE_FORMAT');
};

interface PresetDate {
  label: string;
  value: [Moment, Moment];
}

export const getRangePickerPresets = (t: TFunction): PresetDate[] => [
  { label: t('DATE_AND_TIME.TODAY'), value: [moment().startOf('day'), moment().endOf('day')] },
  {
    label: t('DATE_AND_TIME.YESTERDAY'),
    value: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
  },
  {
    label: t('DATE_AND_TIME.LAST_7_DAYS'),
    value: [moment().subtract(7, 'day'), moment()],
  },
  {
    label: t('DATE_AND_TIME.LAST_30_DAYS'),
    value: [moment().subtract(30, 'day'), moment()],
  },
  {
    label: t('DATE_AND_TIME.THIS_WEEK'),
    value: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  },
  {
    label: t('DATE_AND_TIME.THIS_MONTH'),
    value: [moment().startOf('month'), moment().endOf('month')],
  },
  // {
  //   label: t('DATE_AND_TIME.THIS_QUARTER'),
  //   value: [moment().startOf('quarter'), moment().endOf('quarter')],
  // },
  // {
  //   label: t('DATE_AND_TIME.THIS_YEAR'),
  //   value: [moment().startOf('year'), moment().endOf('year')],
  // },
  // {
  //   label: t('DATE_AND_TIME.LAST_MONTH'),
  //   value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  // },
  // {
  //   label: t('DATE_AND_TIME.LAST_QUARTER'),
  //   value: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
  // },
  // {
  //   label: t('DATE_AND_TIME.LAST_YEAR'),
  //   value: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  // },
  // {
  //   label: t('DATE_AND_TIME.LAST_3_MONTHS'),
  //   value: [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  // },
  // {
  //   label: t('DATE_AND_TIME.LAST_3_QUARTERS'),
  //   value: [moment().subtract(3, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
  // },
  // {
  //   label: t('DATE_AND_TIME.YEAR_TO_DATE'),
  //   value: [moment().startOf('year'), moment()],
  // },
];
