/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ANALYTICS } from 'utils/routingUtils';
import { getObjectUrlParams } from 'utils/stringUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { useNavigate } from 'react-router-dom';
import { userHasClientUserRole } from 'utils/userUtils';
import { useTrackedSelector } from 'components/app/store';
import { useLazyQuery } from '@apollo/client';
import { getAdsDna } from 'graphql/businessQueries';
import { AdDna, AdScoreMetric, AdsDna, Analytics } from 'types/businessTypes.d';
import { Client, Clients } from 'types/types';
import { getClients } from 'graphql/queries';
import { AdDnaSearchCriteria, OrderBy, SearchPaging, Sorting } from 'types/inputTypes';
import {
  getCumulatedDna,
  getDnaStarMetricCost,
  getDnaStarMetricCount,
  getDnaStarMetricName,
} from 'components/features/private/analytics/analyticsUtils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { iso8601DateFormat } from 'utils/dateTimeUtils';
import { extendMoment } from 'moment-range';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const momentWithRange = extendMoment(moment);

export const AnalyticsContext = createContext({});

export const filtersInitialState = {
  [SearchParams.CLIENT_ID]: '',
  [SearchParams.CONCEPT_ID]: '',
};

export const buildAnalyticsUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.CONCEPT_ID]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.CONCEPT_ID]: input[SearchParams.CONCEPT_ID] || filtersInitialState[SearchParams.CONCEPT_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${ANALYTICS}?${urlParams}`;
};

export interface AnalyticsContextProps {
  loading: boolean;
  error: boolean;

  analytics: Analytics | null; // todo remove

  adScoreMetric: AdScoreMetric;
  setAdScoreMetric: (value: AdScoreMetric) => void;

  startDate: string;
  endDate: string;
  onDateRangeChange: (startDate: string, endDate: string) => void;

  starMetricName: string;
  starMetricCostName: string;
  totalSpend: number | undefined;
  totalCount: number | undefined;
  totalCost: number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resultsData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outlierDistributionData: any[];

  onClientSelected: (id: string) => void;
  selectedClientId: string | null;
  clients: Client[];
}

const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    searchParams: {
      [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID],
      [SearchParams.CONCEPT_ID]: _projectId = filtersInitialState[SearchParams.CONCEPT_ID],
    },
  } = useCustomLocation();

  const {
    auth: { user },
  } = useTrackedSelector();

  const [doFetchClients, { data: clientsData, loading: clientsLoading, error: clientsError }] = useLazyQuery<{
    getClients: Clients;
  }>(getClients);
  const [doGetAdsDna, { data: adsDnaData, loading: adsDnaLoading, error: adsDnaError }] = useLazyQuery<{
    getAdsDna: AdsDna;
  }>(getAdsDna);

  const [adScoreMetric, setAdScoreMetric] = useState(AdScoreMetric.STANDARD);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'day').format(iso8601DateFormat));
  const [endDate, setEndDate] = useState(moment().format(iso8601DateFormat));

  const [dataFetchingCompleted, setDataFetchingCompleted] = useState(false);

  const [selectedClientId, setSelectedClientId] = useState<string>(_clientId || '');
  const [selectedProjectId, setSelectedProjectId] = useState<string>(_projectId || '');
  const [items, setItems] = useState<AdDna[]>([]);

  const clients = useMemo(() => clientsData?.getClients?.items || [], [clientsData?.getClients?.items]);

  const isClient = useMemo(() => userHasClientUserRole(user), [user]);

  const selectedClient = useMemo(
    () => (selectedClientId && !!clients.length ? clients.find(({ id }) => id === selectedClientId) || null : null),
    [clients, selectedClientId],
  );

  const {
    starMetricName,
    starMetricCostName,
    totalSpend,
    totalCount,
    totalCost,
    resultsData,
    outlierDistributionData,
  } = useMemo(() => {
    if (!items.length || !selectedClient?.metaDataSource?.adAccounts?.length) return {};

    const starMetric = selectedClient.metaDataSource.adAccounts[0].starMetric1;
    if (!starMetric) return {};

    const dateRange = momentWithRange.range([moment(startDate), moment(endDate)]);

    const starMetricName = getDnaStarMetricName(starMetric);

    // DNAs with the data only for this range
    const dateItems = items.map((item) => {
      return getCumulatedDna(startDate, item.insightsByDate?.filter((i) => dateRange.contains(moment(i.date))) || []);
    });
    console.log({ dateItems });

    const totalSpend = dateItems.map((i) => i.insightsMetaSpend || 0).reduce((a, b) => a + b, 0);
    const totalCount = dateItems.map((i) => getDnaStarMetricCount(i, starMetric) || 0).reduce((a, b) => a + b, 0);
    const totalCost = dateItems.map((i) => getDnaStarMetricCost(i, starMetric) || 0).reduce((a, b) => a + b, 0);

    const resultsData = Array.from(dateRange.by('week')).map((i) => {
      const range = momentWithRange.range([i, i.add(1, 'week')]);
      const count = items
        .map((j) => {
          const item = j.insightsByDate?.find((k) => range.contains(moment(k.date)));
          return item ? getDnaStarMetricCount(item, starMetric) : 0;
        })
        .reduce((a, b) => a + b, 0);
      const spend = items
        .map((j) => {
          const item = j.insightsByDate?.find((k) => range.contains(moment(k.date)));
          return item ? getDnaStarMetricCount(item, 'spend') : 0;
        })
        .reduce((a, b) => a + b, 0);

      return [i.format(iso8601DateFormat), count, !!spend && !!count ? spend / count : 0];
    });
    console.log({ resultsData });

    const outlierDistributionData = dateItems.map((i) => {
      return [
        // cost
        getDnaStarMetricCost(i, starMetric),
        // count
        getDnaStarMetricCount(i, starMetric),
      ];
    });
    console.log({ outlierDistributionData });

    return {
      starMetricName: t(`PRIVATE.USER.ADS_DNA.${starMetricName}`),
      starMetricCostName: t(`PRIVATE.USER.ADS_DNA.${starMetricName}_COST`),
      totalSpend,
      totalCount,
      totalCost,
      resultsData,
      outlierDistributionData,
    };
  }, [endDate, items, selectedClient?.metaDataSource?.adAccounts, startDate, t]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: selectedClientId || _clientId || filtersInitialState[SearchParams.CLIENT_ID],
        [SearchParams.CONCEPT_ID]: selectedProjectId || _projectId || filtersInitialState[SearchParams.CONCEPT_ID],
      }),
    [_clientId, _projectId, selectedClientId, selectedProjectId],
  );

  const onDateRangeChange = useCallback((startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const fetchAdsDna = useCallback(() => {
    const searchCriteria = {
      clientIds: !!selectedClientId?.length ? [selectedClientId] : null,
    } as AdDnaSearchCriteria;

    doGetAdsDna({
      variables: {
        searchCriteria,
      },
    });
  }, [doGetAdsDna, selectedClientId]);

  const onClientSelected = useCallback(
    (id: string) => {
      setSelectedClientId(id);
      setSelectedProjectId('');
      navigate(
        buildAnalyticsUrl({
          [SearchParams.CLIENT_ID]: id,
          [SearchParams.CONCEPT_ID]: '',
        }),
      );
    },
    [navigate],
  );

  // set client id for client user
  useEffect(() => {
    if (user && userHasClientUserRole(user) && !selectedClientId?.length) {
      setSelectedClientId(user.clientId || '');
    }
  }, [selectedClientId?.length, user]);

  // initial fetch of all entities
  useEffect(() => {
    if (!user) return;
    if (isClient && !selectedClientId?.length) return;

    doFetchClients({
      variables: {
        sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
        paging: { page: 0, perPage: 100 } as SearchPaging,
      },
    });

    fetchAdsDna();
  }, [doFetchClients, fetchAdsDna, isClient, selectedClientId?.length, user]);

  // define displayed items
  useEffect(() => {
    let items: AdDna[] = [];
    if (!adsDnaLoading && !adsDnaError) {
      items = adsDnaData?.getAdsDna?.items || [];
    }
    setDataFetchingCompleted(!!adsDnaData?.getAdsDna);
    setItems(items);
  }, [adsDnaLoading, adsDnaError, adsDnaData?.getAdsDna]);

  // select the very first client if no client selected
  useEffect(() => {
    if (!selectedClientId?.length && !!clients.length) {
      setSelectedClientId(clients[0].id);
    }
  }, [clients, selectedClientId?.length]);

  useEffect(() => {
    navigate(`/${ANALYTICS}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading: clientsLoading || adsDnaLoading || !dataFetchingCompleted,
    error: !!clientsError || !!adsDnaError,

    analytics: null,

    adScoreMetric,
    setAdScoreMetric,

    startDate,
    endDate,
    onDateRangeChange,

    starMetricName,
    starMetricCostName,
    totalSpend,
    totalCount,
    totalCost,
    resultsData,
    outlierDistributionData,

    selectedClientId,
    clients,
    onClientSelected,
  } as AnalyticsContextProps;

  console.log(value);
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
