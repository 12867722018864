import React from 'react';
import { CSSProperties } from 'react';
import { ReactComponent as LogoAgencyWhiteIcon } from 'assets/img/logoAgencyWhite.svg';
import { ReactComponent as LogoAgencyBlackIcon } from 'assets/img/logoAgencyBlack.svg';
import { ReactComponent as LogoSaasWhiteIcon } from 'assets/img/logoSaasWhite.svg';
import { ReactComponent as LogoSaasBlackIcon } from 'assets/img/logoSaasBlack.svg';
import { isSaas } from 'utils/businessUtils';

export const primary = '#C8FD6A';
export const black_0 = '#ffffff';
export const black_10 = '#fafafa';
export const black_20 = '#f5f5f5';
export const black_40 = '#dfdfdf';
export const black_60 = '#b3b3b3';
export const black_70 = '#373737';
export const black_100 = '#7b7b7b';
export const black_300 = '#5E5E5E';
export const black_400 = '#525252';
export const black_500 = '#434343';
export const black_700 = '#262626';
export const black_800 = '#171717';
export const main_black_900 = '#1e1e1e';

export const purple_50 = '#ece9f7';
export const purple_100 = '#c5bce6';
export const purple_300 = '#826ec8';
export const main_purple_500 = '#4526ad';

export const green_yellow_600 = '#b6e660';
export const green_50 = '#e6fff2';
export const green_100 = '#b0ffd7';
export const green_300 = '#54ffa9';
export const green_500 = '#00ff7f';

export const pink_50 = '#fee8f3';
export const pink_100 = '#fdb7db';
export const pink_300 = '#fa64b1';
export const pink_500 = '#f7188a';

export const fuchsia_50 = '#ffe6ff';
export const fuchsia_100 = '#ffb0ff';
export const fuchsia_300 = '#ff54ff';
export const fuchsia_500 = '#ff00ff';

export const blue_50 = '#e6ffff';
export const blue_100 = '#b0ffff';
export const blue_300 = '#54ffff';
export const blue_500 = '#00ffff';

export const addAlpha = (color: string, opacity: number): string => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const tooltipStyle = {
  textAlign: 'start',
  fontSize: '.8rem',
} as CSSProperties;

export const getLogoWhite = (): JSX.Element => {
  return isSaas() ? <LogoSaasWhiteIcon /> : <LogoAgencyWhiteIcon />;
};

export const getLogoBlack = (): JSX.Element => {
  return isSaas() ? <LogoSaasBlackIcon /> : <LogoAgencyBlackIcon />;
};
