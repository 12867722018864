import { makeId } from './stringUtils';
import { openInNewTab } from './windowUtils';
import { ContentType } from 'types/contentTypes';

export const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64.replace(/_/g, '/').replace(/-/g, '+'));
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

export const base64JpgPrefix = `data:${ContentType.IMAGE_JPG};base64,`;
export const base64PdfPrefix = `data:${ContentType.APPLICATION_PDF};base64,`;
export const base64ZipPrefix = `data:${ContentType.APPLICATION_ZIP};base64,`;
export const base64CsvPrefix = `data:${ContentType.TEXT_CSV};base64,`;

export const getBase64Prefix = (mime: string) => `data:${mime};base64,`;

export const normalizeBlobContent = (content: string, prefix = base64PdfPrefix): string => {
  return content.startsWith(prefix) ? content : prefix + content;
};

export const denormalizeBlobContent = (content: string, prefix = base64PdfPrefix): string => {
  return content.startsWith(prefix) ? content.substr(prefix.length) : content;
};

/**
 * @param checkBodyForBase64Prefix true if body must be checked for the bas 64 prefix, false otherwise
 * @param body can be encoded body or a link to the body stored in the browser
 * @param filename name of the file
 * @param prefix base64 prefix
 */
export const createAndDownloadBlobFile = (
  checkBodyForBase64Prefix: boolean,
  body: string,
  filename: string,
  prefix = base64PdfPrefix,
): void => {
  const link = document.createElement('a');
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    link.setAttribute('href', checkBodyForBase64Prefix ? normalizeBlobContent(body, prefix) : body);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadFromUrl = (url: string): void => {
  const link = document.createElement('a');
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const downloadIFrameContent = (iframe: any, linkName: string): void => {
  const link = document.createElement('a');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link.download = linkName;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link.href = iframe?.getAttribute('src');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const printIFrameContent = (iframe: any): void => {
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  iframe?.contentWindow?.print();
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const iframeWindow = iframe?.contentWindow || iframe;
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  iframe?.focus();
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  iframeWindow.print();
};

export const printBlobFile = (src: string): void => {
  const iframe = document.createElement('iframe');
  const id = makeId(10);
  iframe.id = id;
  iframe.className = id;
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe?.contentWindow?.print();
      URL.revokeObjectURL(src);
      document.body.removeChild(iframe);
    }, 100);
  };
  iframe.src = src;
};

export const base64ToBlob = (value: string, contentType: string): Blob => {
  const byteArray = base64ToArrayBuffer(value);
  return new Blob([byteArray], { type: `${contentType};base64` });
};

export const base64ToUrl = (value: string, contentType: string): string => {
  const file = base64ToBlob(value, contentType);
  return URL.createObjectURL(file);
};

export const openBase64FileInNewTab = (content: string, contentType: string): void => {
  openInNewTab(base64ToUrl(content, contentType));
};

export const fetchBase64UrlContent = (url: string, callback?: (data: string) => void): Promise<string> => {
  return fetch(url, {})
    .then((response) => response.arrayBuffer())
    .then((buffer) => {
      const base64 = window.btoa(
        [].slice
          .call(new Uint8Array(buffer))
          .map(function (bin) {
            return String.fromCharCode(bin);
          })
          .join(''),
      );
      if (callback) callback(base64);
      return base64;
    });
};

/**
 * @return composed from headers and data CSV file
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeCsv = (headers: any, rows: any[]): string => {
  const separator = ';';
  const keys: string[] = Object.keys(headers);
  return `${Object.values(headers).join(separator)}\n${rows
    .map((row) =>
      keys
        .map((k) => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];

          cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');

          return `"${cell.trim()}"`;
        })
        .join(separator),
    )
    .join('\n')}`;
};

export const stringToBlob = (value: string, type: ContentType): Blob => {
  return new Blob([value], { type });
};

export const stringToBase64 = (value: string, type: ContentType): Promise<string> => {
  const blob = stringToBlob(value, type);
  return new Promise((resolve) => {
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
