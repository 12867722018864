import { TFunction, useTranslation } from 'react-i18next';
import {
  AdAccountReportTime,
  ClientPaymentModel,
  ClientTeamRole,
  CountryCode,
  LanguageCode,
  Location,
  SecurityRole,
  SocialMediaChannel,
} from 'types/types.d';
import logger from './logger/logger';
import { AdScoreMetric, AdState, BriefCreatorsAge, BriefDeliverablesVideoLength } from 'types/businessTypes.d';
import { useTrackedSelector } from 'components/app/store';
import { isSaas } from 'utils/businessUtils';

export const useProductName = (): string => {
  const {
    auth: { whitelabel },
  } = useTrackedSelector();
  const { t } = useTranslation();

  return isSaas()
    ? t('COMMON.PRODUCT_NAME_SAAS')
    : whitelabel?.name
    ? whitelabel.name.toUpperCase()
    : t('COMMON.PRODUCT_NAME_AGENCY');
};

export const getContactUsMessage = (): string => {
  // todo if needed add "supportEmail" to the Whitelabel and use it here
  return 'COMMON.TRY_AGAIN_OR_CONTACT_CUSTOMER_SERVICE';
};

export const getCountryCodeByLanguageCode = (languageCode: string): CountryCode => {
  switch (languageCode) {
    case LanguageCode.EN:
      return CountryCode.US;
    case LanguageCode.DE:
      return CountryCode.DE;
    default:
      return CountryCode.US;
  }
};

export const getLanguageCodeByCountryCode = (countryCode: string): LanguageCode => {
  switch (countryCode) {
    case CountryCode.US:
      return LanguageCode.EN;
    case CountryCode.DE:
      return LanguageCode.DE;
    default:
      return LanguageCode.EN;
  }
};

export const getLanguageCodeByString = (languageCode: string): LanguageCode => {
  switch (languageCode) {
    case LanguageCode.EN:
      return LanguageCode.EN;
    case LanguageCode.DE:
      return LanguageCode.DE;
    case LanguageCode.SE:
      return LanguageCode.SE;
    case LanguageCode.FI:
      return LanguageCode.FI;
    case LanguageCode.RU:
      return LanguageCode.RU;
    case LanguageCode.ES:
      return LanguageCode.ES;
    case LanguageCode.FR:
      return LanguageCode.FR;
    case LanguageCode.PT:
      return LanguageCode.PT;
    default:
      return LanguageCode.EN;
  }
};

export const getLocalisedLanguage = (code: LanguageCode, t: TFunction): string => {
  return t(`LANGUAGE.FULL.${code.toUpperCase()}`);
};

export const getLocalisedCountry = (code: CountryCode, t: TFunction): string => {
  switch (code) {
    case CountryCode.US:
      return t('COUNTRY.NAME.US');
    case CountryCode.DE:
      return t('COUNTRY.NAME.DE');
    default:
      logger.error(`Undefined country ${code}`);
      return '';
  }
};

export const getLocalisedSecurityRole = (role: SecurityRole, t: TFunction, whitelabelName?: string | null): string => {
  switch (role) {
    case SecurityRole.SUPER_ADMIN:
      return t('SECURITY_ROLE.SUPER_ADMIN');
    case SecurityRole.ADMIN:
      return t('SECURITY_ROLE.ADMIN');
    case SecurityRole.PG_USER:
      return !whitelabelName?.length ? t('SECURITY_ROLE.PG_USER') : `${whitelabelName} ${t('SECURITY_ROLE.USER')}`;
    case SecurityRole.CLIENT_ADMIN:
      return t('SECURITY_ROLE.CLIENT_ADMIN');
    case SecurityRole.CLIENT_USER:
      return t('SECURITY_ROLE.CLIENT_USER');
    case SecurityRole.SAAS_ADMIN:
      return t('SECURITY_ROLE.SAAS_ADMIN');
    case SecurityRole.SAAS_USER:
      return t('SECURITY_ROLE.SAAS_USER');
    case SecurityRole.CREATOR:
      return t('SECURITY_ROLE.CREATOR');
    default:
      logger.error(`Undefined SecurityRole ${role}`);
      return '';
  }
};

export const getLocalisedClientTeamRole = (role: ClientTeamRole, t: TFunction): string => {
  switch (role) {
    case ClientTeamRole.REVIEWER:
      return t('CLIENT_TEAM_ROLE.REVIEWER');
    case ClientTeamRole.APPROVER:
      return t('CLIENT_TEAM_ROLE.APPROVER');
    default:
      logger.error(`Undefined ClientTeamRole ${role}`);
      return '';
  }
};

export const getLocalisedClientPaymentModel = (value: ClientPaymentModel, t: TFunction): string => {
  switch (value) {
    case ClientPaymentModel.STANDARD:
      return t('CLIENT_PAYMENT_MODEL.STANDARD');
    case ClientPaymentModel.PERFORMANCE:
      return t('CLIENT_PAYMENT_MODEL.PERFORMANCE');
    default:
      logger.error(`Undefined ClientPaymentModel ${value}`);
      return '';
  }
};

export const getLocalisedBriefDeliverablesVideoLength = (value: BriefDeliverablesVideoLength): string => {
  switch (value) {
    case BriefDeliverablesVideoLength.UP_TO_15_SECONDS:
      return 'Up to 15 seconds';
    case BriefDeliverablesVideoLength.SECONDS_15_30:
      return '15-30 seconds';
    case BriefDeliverablesVideoLength.SECONDS_30_60:
      return '30-60 seconds';
    default:
      logger.error(`Undefined BriefDeliverablesVideoLength ${value}`);
      return '';
  }
};

export const getLocalisedBriefCreatorsAge = (value: BriefCreatorsAge): string => {
  switch (value) {
    case BriefCreatorsAge._18_24:
      return '18-24';
    case BriefCreatorsAge._25_34:
      return '25-34';
    case BriefCreatorsAge._35_AND_MORE:
      return '35+';
    case BriefCreatorsAge.ANY:
      return 'Any';
    default:
      logger.error(`Undefined BriefCreatorsAge ${value}`);
      return '';
  }
};

export const getLocalisedCtaPlacement = (value: string, t: TFunction): string => {
  return t(`CTA_PLACEMENT.${value.toUpperCase()}`);
};

export const getLocalisedLocation = (value: Location): string => {
  switch (value) {
    case Location.US:
      return 'USA';
    case Location.ASIA:
      return 'Asia';
    case Location.EU:
      return 'Europe';
    case Location.GLOBAL:
      return 'Global';
    default:
      return value;
  }
};

export const getLocalisedSocialMediaChannel = (value: SocialMediaChannel, t: TFunction): string => {
  return t(`SOCIAL_MEDIA_CHANNEL.${value.toUpperCase()}`);
};

export const getLocalisedAdScoreMetric = (value: AdScoreMetric, t: TFunction): string => {
  return t(`AD_SCORE_METRIC.${value.toUpperCase()}`);
};

export const getLocalisedAdState = (value: AdState, t: TFunction): string => {
  switch (value) {
    case AdState.APPROVED:
      return t('AD_STATE.APPROVED');
    case AdState.IN_REVIEW:
      return t('AD_STATE.IN_REVIEW');
    // case AdState.DELETED:
    //   return t('AD_STATE.DELETED');
    case AdState.LAUNCHED:
      return t('AD_STATE.LAUNCHED');
    case AdState.EDIT_REQUESTED:
      return t('AD_STATE.EDIT_REQUESTED');
    default:
      logger.error(`Undefined state ${value}`);
      return '';
  }
};

/**
 * @return a currency formatter that uses the defined in the i18n context locale
 */
export const useLocalisedCurrencyFormatterWithDecimal = (
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  const { i18n } = useTranslation();
  return getLocalisedCurrencyFormatterWithDecimal(i18n.language, currency, currencyDisplay);
};

/**
 * @return a currency formatter that uses the defined in the i18n context locale
 */
export const useLocalisedCurrencyFormatterWithoutDecimal = (
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  const { i18n } = useTranslation();
  return getLocalisedCurrencyFormatterWithoutDecimal(i18n.language, currency, currencyDisplay);
};

/**
 * @return a currency formatter that uses the provided number formatter options
 */
export const getLocalisedCurrencyFormatterWithDecimal = (
  language: string,
  currency = 'USD',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency ? currency : 'USD',
    currencyDisplay: currencyDisplay,
  });
};

/**
 * @return a currency formatter that uses the provided number formatter options
 */
export const getLocalisedCurrencyFormatterWithoutDecimal = (
  language: string,
  currency = 'EUR',
  currencyDisplay = CurrencyDisplay.SYMBOL,
): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency ? currency : 'EUR',
    currencyDisplay: currencyDisplay,
    // These options are needed to round to whole numbers.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
};

export enum CurrencyDisplay {
  SYMBOL = 'symbol',
  CODE = 'code',
  NAME = 'name',
}

export const getThousandSeparator = (languageCode: string): string => {
  switch (languageCode) {
    case LanguageCode.EN:
      return ',';
    case LanguageCode.DE:
    default:
      return '.';
  }
};

export const getDecimalSeparator = (languageCode: string): string => {
  switch (languageCode) {
    case LanguageCode.EN:
      return '.';
    case LanguageCode.DE:
    default:
      return ',';
  }
};

export const getLocalisedReportTime = (value: AdAccountReportTime, t: TFunction): string => {
  switch (value) {
    case AdAccountReportTime.ON_CONVERSION_DATE:
      return t('AD_ACCOUNT_REPORT_TIME.ON_CONVERSION_DATE');
    case AdAccountReportTime.ON_IMPRESSION_DATE:
      return t('AD_ACCOUNT_REPORT_TIME.ON_IMPRESSION_DATE');
    case AdAccountReportTime.MIXED:
      return t('AD_ACCOUNT_REPORT_TIME.MIXED');
    default:
      return value;
  }
};
