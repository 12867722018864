export const createCookie = (
  name: string,
  value: string,
  expiresInSeconds: number | null,
  secure: boolean,
  sameSiteStrict: boolean,
): void => {
  let expires;
  if (expiresInSeconds) {
    const date = new Date();
    date.setTime(date.getTime() + expiresInSeconds * 1000);
    expires = '; expires=' + date.toUTCString();
  } else {
    expires = '';
  }

  const secureFeature = secure ? '; secure' : '';
  const sameSiteStrictFeature = sameSiteStrict ? '; samesite=strict' : '';

  document.cookie = name + '=' + value + expires + secureFeature + sameSiteStrictFeature + '; path=/';
};

export const readCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const eraseCookie = (name: string): void => createCookie(name, '', -1, false, false);
