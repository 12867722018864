import { useLocation } from 'react-router-dom';

export const ID = 'id';
export const CLIENT_ID = 'clientId';
export const CONCEPT_ID = 'conceptId';

/**
 * Public routes
 */
export const HOME = '/';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const REGISTER = '/register';
export const PASSWORD_RESET = '/reset-password';
export const PASSWORD_CHANGE = '/change-password';
export const PROFILE_DELETION = '/delete-profile';

/**
 * USER private routes
 */
export const ONBOARDING = 'onboarding';
export const TEST = 'test';
export const ASSETS = 'assets';
export const AD_VIEW = 'ad-view';
export const AD_VIEW_WITH_ID = `${AD_VIEW}/:${ID}`;
export const BRIEF = 'brief';
export const TEAM = 'team';
export const DATA = 'data';
export const ANALYTICS = 'analytics';
export const AUDIT = 'audit';
export const AUDIT_REPORT = `${AUDIT}/report`;
export const AGENCY = 'agency';
export const SETTINGS = 'settings';
export const BUSINESS = 'business';

/**
 * ADMIN private routes
 */
export const ADMIN = 'admin';
export const ADMIN_CLIENTS = `${ADMIN}/clients`;
export const ADMIN_CLIENT_DETAILS = `${ADMIN_CLIENTS}/:id`;
export const ADMIN_CLIENT_DETAILS_ADD = `${ADMIN_CLIENTS}/add`;
export const ADMIN_USERS = `${ADMIN}/users`;
export const ADMIN_INVITATIONS = `${ADMIN}/invitations`;
export const ADMIN_WHITELABELS = `${ADMIN}/whitelabels`;
export const ADMIN_PROFILE = `${ADMIN}/profile`;
export const ADMIN_SETTINGS = `${ADMIN}/${SETTINGS}`;

export const allPrivateRoutes = [
  ONBOARDING,
  TEST,
  ASSETS,
  AD_VIEW,
  AD_VIEW_WITH_ID,
  BRIEF,
  TEAM,
  DATA,
  ANALYTICS,
  AUDIT,
  AGENCY,
  AUDIT_REPORT,
  SETTINGS,
  BUSINESS,

  ADMIN_CLIENTS,
  ADMIN_CLIENT_DETAILS,
  ADMIN_USERS,
  ADMIN_INVITATIONS,
  ADMIN_WHITELABELS,
  ADMIN_PROFILE,
  ADMIN_SETTINGS,
];

export const URL_QUERY_PARAM_KEY = 'key';
export const URL_QUERY_PARAM_CODE = 'code';
export const URL_QUERY_PARAM_TOKEN = 'token';
export const URL_QUERY_PARAM_EXPIRED_SESSION = 'expired';
export const URL_QUERY_PARAM_LANGUAGE = 'lang';
export const URL_QUERY_PARAM_DOMAIN = 'domain';
export const URL_QUERY_PARAM_ACTION = 'action';
export const URL_QUERY_PARAM_ACTION_LOGIN = 'login';
export const URL_QUERY_PARAM_ACTION_REG = 'reg';

export const COOKIE_REFRESH_TOKEN = 'refresh_token';

export const useURLSearchParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};
