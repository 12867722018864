import { AdDnaMetricsForDate } from 'types/businessTypes';

export const getDnaStarMetricName = (starMetric: string): string => {
  switch (starMetric) {
    case 'app_install':
      return 'INSIGHTS_META_APP_INSTALL';
    case 'add_payment_info':
      return 'INSIGHTS_META_ADD_PAYMENT_INFO';
    case 'add_to_cart':
      return 'INSIGHTS_META_ADD_TO_CART';
    case 'add_to_wishlist':
      return 'INSIGHTS_META_ADD_TO_WISHLIST';
    case 'complete_registration':
      return 'INSIGHTS_META_COMPLETE_REGISTRATION';
    case 'contact':
      return 'INSIGHTS_META_CONTACT';
    case 'customize_product':
      return 'INSIGHTS_META_CUSTOMIZE_PRODUCT';
    case 'donate':
      return 'INSIGHTS_META_DONATE';
    case 'find_location':
      return 'INSIGHTS_META_FIND_LOCATION';
    case 'initiate_checkout':
      return 'INSIGHTS_META_INITIATE_CHECKOUT';
    case 'lead':
      return 'INSIGHTS_META_LEAD';
    case 'purchase':
      return 'INSIGHTS_META_PURCHASE';
    case 'schedule':
      return 'INSIGHTS_META_SCHEDULE';
    case 'search':
      return 'INSIGHTS_META_SEARCH';
    case 'start_trial':
      return 'INSIGHTS_META_START_TRIAL';
    case 'submit_application':
      return 'INSIGHTS_META_SUBMIT_APPLICATION';
    case 'subscribe':
      return 'INSIGHTS_META_SUBSCRIBE';
    case 'view_content':
      return 'INSIGHTS_META_VIEW_CONTENT';
    default:
      return '';
  }
};

export const getDnaStarMetricCount = (adDna: AdDnaMetricsForDate, starMetric: string): number => {
  switch (starMetric) {
    case 'spend':
      return adDna.insightsMetaSpend || 0;
    case 'app_install':
      return adDna.insightsMetaAppInstall || 0;
    case 'add_payment_info':
      return adDna.insightsMetaAddPaymentInfo || 0;
    case 'add_to_cart':
      return adDna.insightsMetaAddToCart || 0;
    case 'add_to_wishlist':
      return adDna.insightsMetaAddToWishlist || 0;
    case 'complete_registration':
      return adDna.insightsMetaCompleteRegistration || 0;
    case 'contact':
      return adDna.insightsMetaContact || 0;
    case 'customize_product':
      return adDna.insightsMetaCustomizeProduct || 0;
    case 'donate':
      return adDna.insightsMetaDonate || 0;
    case 'find_location':
      return adDna.insightsMetaFindLocation || 0;
    case 'initiate_checkout':
      return adDna.insightsMetaInitiateCheckout || 0;
    case 'lead':
      return adDna.insightsMetaLead || 0;
    case 'purchase':
      return adDna.insightsMetaPurchase || 0;
    case 'schedule':
      return adDna.insightsMetaSchedule || 0;
    case 'search':
      return adDna.insightsMetaSearch || 0;
    case 'start_trial':
      return adDna.insightsMetaStartTrial || 0;
    case 'submit_application':
      return adDna.insightsMetaSubmitApplication || 0;
    case 'subscribe':
      return adDna.insightsMetaSubscribe || 0;
    case 'view_content':
      return adDna.insightsMetaViewContent || 0;
    default:
      return 0;
  }
};

export const getDnaStarMetricCost = (adDna: AdDnaMetricsForDate, starMetric: string): number => {
  switch (starMetric) {
    case 'app_install':
      return adDna.insightsMetaAppInstallCost || 0;
    case 'add_payment_info':
      return adDna.insightsMetaAddPaymentInfoCost || 0;
    case 'add_to_cart':
      return adDna.insightsMetaAddToCartCost || 0;
    case 'add_to_wishlist':
      return adDna.insightsMetaAddToWishlistCost || 0;
    case 'complete_registration':
      return adDna.insightsMetaCompleteRegistrationCost || 0;
    case 'contact':
      return adDna.insightsMetaContactCost || 0;
    case 'customize_product':
      return adDna.insightsMetaCustomizeProductCost || 0;
    case 'donate':
      return adDna.insightsMetaDonateCost || 0;
    case 'find_location':
      return adDna.insightsMetaFindLocationCost || 0;
    case 'initiate_checkout':
      return adDna.insightsMetaInitiateCheckoutCost || 0;
    case 'lead':
      return adDna.insightsMetaLeadCost || 0;
    case 'purchase':
      return adDna.insightsMetaPurchaseCost || 0;
    case 'schedule':
      return adDna.insightsMetaScheduleCost || 0;
    case 'search':
      return adDna.insightsMetaSearchCost || 0;
    case 'start_trial':
      return adDna.insightsMetaStartTrialCost || 0;
    case 'submit_application':
      return adDna.insightsMetaSubmitApplicationCost || 0;
    case 'subscribe':
      return adDna.insightsMetaSubscribeCost || 0;
    case 'view_content':
      return adDna.insightsMetaViewContentCost || 0;
    default:
      return 0;
  }
};

/**
 * @return a single [AdDnaMetricsForDate] that combines the data from all [adDnas]
 */
export const getCumulatedDna = (date: string, adDnas: AdDnaMetricsForDate[]): AdDnaMetricsForDate => {
  const result = { date: date } as AdDnaMetricsForDate;

  const metaGeomMScore1s: number[] = [];
  const metaFBetaScore1s: number[] = [];

  adDnas.forEach((i) => {
    if (i.insightsMetaSpend) result.insightsMetaSpend = (result.insightsMetaSpend || 0) + i.insightsMetaSpend;

    if (!!i.metaGeomMScore1) metaGeomMScore1s.push(result.metaGeomMScore1 || 0);
    if (!!i.metaFBetaScore1) metaFBetaScore1s.push(result.metaFBetaScore1 || 0);

    if (!!i.insightsMetaAppInstall)
      result.insightsMetaAppInstall = (result.insightsMetaAppInstall || 0) + i.insightsMetaAppInstall;
    if (!!i.insightsMetaAddPaymentInfo)
      result.insightsMetaAddPaymentInfo = (result.insightsMetaAddPaymentInfo || 0) + i.insightsMetaAddPaymentInfo;
    if (!!i.insightsMetaAddToCart)
      result.insightsMetaAddToCart = (result.insightsMetaAddToCart || 0) + i.insightsMetaAddToCart;
    if (!!i.insightsMetaAddToWishlist)
      result.insightsMetaAddToWishlist = (result.insightsMetaAddToWishlist || 0) + i.insightsMetaAddToWishlist;
    if (!!i.insightsMetaCompleteRegistration)
      result.insightsMetaCompleteRegistration =
        (result.insightsMetaCompleteRegistration || 0) + i.insightsMetaCompleteRegistration;
    if (!!i.insightsMetaContact) result.insightsMetaContact = (result.insightsMetaContact || 0) + i.insightsMetaContact;
    if (!!i.insightsMetaContact) result.insightsMetaContact = (result.insightsMetaContact || 0) + i.insightsMetaContact;
    if (!!i.insightsMetaDonate) result.insightsMetaDonate = (result.insightsMetaDonate || 0) + i.insightsMetaDonate;
    if (!!i.insightsMetaFindLocation)
      result.insightsMetaFindLocation = (result.insightsMetaFindLocation || 0) + i.insightsMetaFindLocation;
    if (!!i.insightsMetaInitiateCheckout)
      result.insightsMetaInitiateCheckout = (result.insightsMetaInitiateCheckout || 0) + i.insightsMetaInitiateCheckout;
    if (!!i.insightsMetaLead) result.insightsMetaLead = (result.insightsMetaLead || 0) + i.insightsMetaLead;
    if (!!i.insightsMetaPurchase)
      result.insightsMetaPurchase = (result.insightsMetaPurchase || 0) + i.insightsMetaPurchase;
    if (!!i.insightsMetaSchedule)
      result.insightsMetaSchedule = (result.insightsMetaSchedule || 0) + i.insightsMetaSchedule;
    if (!!i.insightsMetaSearch) result.insightsMetaSearch = (result.insightsMetaSearch || 0) + i.insightsMetaSearch;
    if (!!i.insightsMetaStartTrial)
      result.insightsMetaStartTrial = (result.insightsMetaStartTrial || 0) + i.insightsMetaStartTrial;
    if (!!i.insightsMetaSubmitApplication)
      result.insightsMetaSubmitApplication =
        (result.insightsMetaSubmitApplication || 0) + i.insightsMetaSubmitApplication;
    if (!!i.insightsMetaSubscribe)
      result.insightsMetaSubscribe = (result.insightsMetaSubscribe || 0) + i.insightsMetaSubscribe;
    if (!!i.insightsMetaViewContent)
      result.insightsMetaViewContent = (result.insightsMetaViewContent || 0) + i.insightsMetaViewContent;
  });

  result.metaGeomMScore1 = metaGeomMScore1s.reduce((a, b) => a + b, 0) / metaGeomMScore1s.length;
  result.metaGeomMScore1 = metaFBetaScore1s.reduce((a, b) => a + b, 0) / metaFBetaScore1s.length;

  result.insightsMetaAppInstallCost =
    !!result.insightsMetaSpend && !!result.insightsMetaAppInstall
      ? result.insightsMetaSpend / result.insightsMetaAppInstall
      : null;
  result.insightsMetaAddPaymentInfoCost =
    !!result.insightsMetaSpend && !!result.insightsMetaAddPaymentInfo
      ? result.insightsMetaSpend / result.insightsMetaAddPaymentInfo
      : null;
  result.insightsMetaAddToCartCost =
    !!result.insightsMetaSpend && !!result.insightsMetaAddToCart
      ? result.insightsMetaSpend / result.insightsMetaAddToCart
      : null;
  result.insightsMetaAddToWishlistCost =
    !!result.insightsMetaSpend && !!result.insightsMetaAddToWishlist
      ? result.insightsMetaSpend / result.insightsMetaAddToWishlist
      : null;
  result.insightsMetaCompleteRegistrationCost =
    !!result.insightsMetaSpend && !!result.insightsMetaCompleteRegistration
      ? result.insightsMetaSpend / result.insightsMetaCompleteRegistration
      : null;
  result.insightsMetaContactCost =
    !!result.insightsMetaSpend && !!result.insightsMetaContact
      ? result.insightsMetaSpend / result.insightsMetaContact
      : null;
  result.insightsMetaCustomizeProductCost =
    !!result.insightsMetaSpend && !!result.insightsMetaCustomizeProduct
      ? result.insightsMetaSpend / result.insightsMetaCustomizeProduct
      : null;
  result.insightsMetaDonateCost =
    !!result.insightsMetaSpend && !!result.insightsMetaDonate
      ? result.insightsMetaSpend / result.insightsMetaDonate
      : null;
  result.insightsMetaFindLocationCost =
    !!result.insightsMetaSpend && !!result.insightsMetaFindLocation
      ? result.insightsMetaSpend / result.insightsMetaFindLocation
      : null;
  result.insightsMetaInitiateCheckoutCost =
    !!result.insightsMetaSpend && !!result.insightsMetaInitiateCheckout
      ? result.insightsMetaSpend / result.insightsMetaInitiateCheckout
      : null;
  result.insightsMetaLeadCost =
    !!result.insightsMetaSpend && !!result.insightsMetaLead ? result.insightsMetaSpend / result.insightsMetaLead : null;
  result.insightsMetaPurchaseCost =
    !!result.insightsMetaSpend && !!result.insightsMetaPurchase
      ? result.insightsMetaSpend / result.insightsMetaPurchase
      : null;
  result.insightsMetaScheduleCost =
    !!result.insightsMetaSpend && !!result.insightsMetaSchedule
      ? result.insightsMetaSpend / result.insightsMetaSchedule
      : null;
  result.insightsMetaSearchCost =
    !!result.insightsMetaSpend && !!result.insightsMetaSearch
      ? result.insightsMetaSpend / result.insightsMetaSearch
      : null;
  result.insightsMetaStartTrialCost =
    !!result.insightsMetaSpend && !!result.insightsMetaStartTrial
      ? result.insightsMetaSpend / result.insightsMetaStartTrial
      : null;
  result.insightsMetaSubmitApplicationCost =
    !!result.insightsMetaSpend && !!result.insightsMetaSubmitApplication
      ? result.insightsMetaSpend / result.insightsMetaSubmitApplication
      : null;
  result.insightsMetaSubscribeCost =
    !!result.insightsMetaSpend && !!result.insightsMetaSubscribe
      ? result.insightsMetaSpend / result.insightsMetaSubscribe
      : null;
  result.insightsMetaViewContentCost =
    !!result.insightsMetaSpend && !!result.insightsMetaViewContent
      ? result.insightsMetaSpend / result.insightsMetaViewContent
      : null;

  return result;
};
