import React, { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { AD_DNA_COLUMNS } from 'components/features/private/adsDna/adsDnaUtils';
import { CloseOutlined } from '@ant-design/icons';
import { black_0, black_100 } from 'utils/styleUtils';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';
import { ReactComponent as DragAndDropIcon } from 'assets/img/dragAndDrop.svg';
import { ReactComponent as SaveIcon } from 'assets/img/save.svg';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Checkbox, Input } from 'components/common';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { useMutation } from '@apollo/client';
import { updateDataColumns } from 'graphql/mutations';
import { User } from 'types/types';
import logger from 'utils/logger/logger';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import ToasterInfo from 'components/common/toasterInfo';
import { getContactUsMessage } from 'utils/i18nUtils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { userLoaded } from 'slices/authSlice';

import styles from './CustomizeColumnsModal.module.scss';

interface PropsSaveName {
  t: TFunction;
  initialValue: string;
  onSave: (inputValue: string) => void;
}

const SaveNameContent = ({ t, initialValue, onSave }: PropsSaveName) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const isDisabled = !inputValue.trim();

  return (
    <div>
      <div style={{ color: black_0, paddingBottom: '.5rem' }}>{t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.NAME')}</div>
      <Input
        type="text"
        name="name"
        maxLength={50}
        placeholder={t('COMMON.TYPE_HERE')}
        defaultValue={initialValue}
        onChange={(e) => setInputValue(e.target.value)}
      />

      <div style={{ display: 'flex', justifyContent: 'end', gap: '1rem', paddingTop: '2rem' }}>
        <Button buttonType="secondary" onClick={() => Modal.destroyAll()}>
          {t('COMMON.CANCEL')}
        </Button>
        <Button buttonType="primary" type="button" onClick={() => onSave(inputValue)} disabled={isDisabled}>
          {t('COMMON.SAVE')}
        </Button>
      </div>
    </div>
  );
};

interface Props {
  columns: AD_DNA_COLUMNS[];
  selectedMetaEvents: string[];
  selectedTikTokEvents: string[];
  onHide: () => void;
  onDone: (value: AD_DNA_COLUMNS[]) => void;
}

interface SelectedColumns {
  [section: string]: AD_DNA_COLUMNS[];
}

const categories = (t: TFunction) => [
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ADS_DNA'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.MAIN'),
    columns: [
      AD_DNA_COLUMNS.PGID,
      AD_DNA_COLUMNS.CREATED_DATE,
      AD_DNA_COLUMNS.PGID_AUTHOR,
      AD_DNA_COLUMNS.AD_TITLE,
      AD_DNA_COLUMNS.PROJECT,
      AD_DNA_COLUMNS.PAYMENT_MODEL,
      AD_DNA_COLUMNS.CHANNEL,
      AD_DNA_COLUMNS.NARRATIVE_TYPE,
      AD_DNA_COLUMNS.ASSET_FORMAT,
      AD_DNA_COLUMNS.ASSET_RATIO,
      AD_DNA_COLUMNS.LAYOUT,
      AD_DNA_COLUMNS.ASSET_FORMAT_TYPE,
      AD_DNA_COLUMNS.VIDEO_LENGTH,
      AD_DNA_COLUMNS.SLIDES_NUMBER,
      AD_DNA_COLUMNS.ASSET_TYPE,
      AD_DNA_COLUMNS.ASSET_SUB_TYPE,
      AD_DNA_COLUMNS.POPULAR_FORMAT,
      AD_DNA_COLUMNS.LANGUAGE,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ADS_DNA'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.GRAB_ATTENTION'),
    columns: [
      AD_DNA_COLUMNS.VISUAL_HOOK_BRANDED,
      AD_DNA_COLUMNS.VISUAL_HOOK_FEATURES,
      // AD_DNA_COLUMNS.VISUAL_HOOK_UNUSUAL,
      AD_DNA_COLUMNS.VISUAL_HOOK_RELEVANCE,
      AD_DNA_COLUMNS.COPY_HOOK,
      AD_DNA_COLUMNS.COPY_HOOK_PLAIN,
      AD_DNA_COLUMNS.COPY_HOOK_FRAMING,
      AD_DNA_COLUMNS.COPY_HOOK_TYPOLOGY,
      AD_DNA_COLUMNS.COPY_HOOK_RELEVANCE,
      AD_DNA_COLUMNS.POPULAR_FORMATS,
      // AD_DNA_COLUMNS.REFERENCES,
      // AD_DNA_COLUMNS.TREND,
      AD_DNA_COLUMNS.SOUND,
      // AD_DNA_COLUMNS.AI_CONTENT,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ADS_DNA'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.HOLD_ATTENTION'),
    columns: [
      AD_DNA_COLUMNS.UGC_CREATORS,
      AD_DNA_COLUMNS.VISUAL_BRANDED,
      AD_DNA_COLUMNS.VISUAL_FEATURES,
      AD_DNA_COLUMNS.COPY,
      AD_DNA_COLUMNS.COPY_PLAIN,
      AD_DNA_COLUMNS.SCRIPT,
      AD_DNA_COLUMNS.SCRIPT_PLAIN,
      AD_DNA_COLUMNS.LOCATION_SPECIFICITY,
      AD_DNA_COLUMNS.PAIN_POINTS,
      AD_DNA_COLUMNS.PERSONAS,
      AD_DNA_COLUMNS.BENEFITS,
      AD_DNA_COLUMNS.PRODUCT_FEATURES,
      AD_DNA_COLUMNS.PRODUCTS,
      AD_DNA_COLUMNS.VALUE_PROPOSITIONS,
      // AD_DNA_COLUMNS.EDUCATING,
      // AD_DNA_COLUMNS.EDUCATING_APP,
      // AD_DNA_COLUMNS.EDUCATING_APP_WHOLE_APP,
      // AD_DNA_COLUMNS.EDUCATING_APP_SERVICE,
      // AD_DNA_COLUMNS.EDUCATING_BROAD_TOPIC,
      AD_DNA_COLUMNS.USER_CENTRICITY,
      AD_DNA_COLUMNS.SPECIFICITY,
      AD_DNA_COLUMNS.STATISTICS,
      AD_DNA_COLUMNS.SIMPLE_LANGUAGE,
      AD_DNA_COLUMNS.LEGAL_DISCLAIMER,
      AD_DNA_COLUMNS.SUBTITLES,
      // AD_DNA_COLUMNS.ENTERTAINING,
      // AD_DNA_COLUMNS.ANTICIPATION,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ADS_DNA'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.EVOKE_ACTION'),
    columns: [
      // AD_DNA_COLUMNS.FINANCIAL_BENEFIT,
      // AD_DNA_COLUMNS.INSTANT_VALUE,
      AD_DNA_COLUMNS.SOCIAL_PROOF,
      AD_DNA_COLUMNS.AUTHORITY,
      AD_DNA_COLUMNS.LOSS_AVERSION,
      AD_DNA_COLUMNS.SCARCITY,
      // AD_DNA_COLUMNS.UNITY,
      AD_DNA_COLUMNS.DEEPER_MOTIVATION_DESIRE,
      AD_DNA_COLUMNS.SELL_THE_PRODUCT_SELL_THE_FEELING,
      AD_DNA_COLUMNS.CTA,
      AD_DNA_COLUMNS.CTA_PLACEMENT,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ADS_DNA'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ROLES'),
    columns: [
      AD_DNA_COLUMNS.CREATIVE_LEAD,
      AD_DNA_COLUMNS.IDEATOR,
      AD_DNA_COLUMNS.CREATIVE_STRATEGIST,
      AD_DNA_COLUMNS.COPYWRITER,
      AD_DNA_COLUMNS.GRAPHIC_DESIGNER,
      AD_DNA_COLUMNS.MOTION_DESIGNER,
      AD_DNA_COLUMNS.CREATOR,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.DATA_SOURCES'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.META'),
    columns: [
      AD_DNA_COLUMNS.META_SPEND,
      AD_DNA_COLUMNS.INSIGHTS_META_APP_INSTALL,
      AD_DNA_COLUMNS.INSIGHTS_META_APP_INSTALL_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_PAYMENT_INFO,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_PAYMENT_INFO_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_CART,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_CART_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WISHLIST,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WISHLIST_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_COMPLETE_REGISTRATION,
      AD_DNA_COLUMNS.INSIGHTS_META_COMPLETE_REGISTRATION_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_CONTACT,
      AD_DNA_COLUMNS.INSIGHTS_META_CONTACT_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_CUSTOMIZE_PRODUCT,
      AD_DNA_COLUMNS.INSIGHTS_META_CUSTOMIZE_PRODUCT_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_DONATE,
      AD_DNA_COLUMNS.INSIGHTS_META_DONATE_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_FIND_LOCATION,
      AD_DNA_COLUMNS.INSIGHTS_META_FIND_LOCATION_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_INITIATE_CHECKOUT,
      AD_DNA_COLUMNS.INSIGHTS_META_INITIATE_CHECKOUT_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_LEAD,
      AD_DNA_COLUMNS.INSIGHTS_META_LEAD_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_PURCHASE,
      AD_DNA_COLUMNS.INSIGHTS_META_PURCHASE_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_SCHEDULE,
      AD_DNA_COLUMNS.INSIGHTS_META_SCHEDULE_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_SEARCH,
      AD_DNA_COLUMNS.INSIGHTS_META_SEARCH_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_START_TRIAL,
      AD_DNA_COLUMNS.INSIGHTS_META_START_TRIAL_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_SUBMIT_APPLICATION,
      AD_DNA_COLUMNS.INSIGHTS_META_SUBMIT_APPLICATION_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_SUBSCRIBE,
      AD_DNA_COLUMNS.INSIGHTS_META_SUBSCRIBE_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_VIEW_CONTENT,
      AD_DNA_COLUMNS.INSIGHTS_META_VIEW_CONTENT_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WAITING_LIST,
      AD_DNA_COLUMNS.INSIGHTS_META_ADD_TO_WAITING_LIST_COST,
      AD_DNA_COLUMNS.INSIGHTS_META_RETURN_ON_AD_SPEND,
      AD_DNA_COLUMNS.INSIGHTS_META_RETURN_ON_AD_SPEND_COST,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.DATA_SOURCES'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.TIKTOK'),
    columns: [
      AD_DNA_COLUMNS.TIKTOK_SPEND,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_APP_INSTALL,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_APP_INSTALL_COST,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_VIEW_CONTENT,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_VIEW_CONTENT_COST,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_CART,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_CART_COST,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_WISHLIST,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_ADD_TO_WISHLIST_COST,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_COMPLETE_REGISTRATION,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_PURCHASE,
      AD_DNA_COLUMNS.INSIGHTS_TIKTOK_PURCHASE_COST,
    ],
  },
  {
    section: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.AD_SCORE'),
    name: t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.AD_SCORE_META'),
    columns: [AD_DNA_COLUMNS.F_BETA_1, AD_DNA_COLUMNS.GEOM_M_1, AD_DNA_COLUMNS.F_BETA_2, AD_DNA_COLUMNS.GEOM_M_2],
  },
];

const DraggableMetric = ({
  metric,
  index,
  moveMetric,
  removeMetric,
  section,
  excluded,
}: {
  metric: AD_DNA_COLUMNS;
  index: number;
  moveMetric: (dragIndex: number, hoverIndex: number, section: string) => void;
  removeMetric: () => void;
  section: string;
  excluded?: boolean;
}) => {
  const { t } = useTranslation();

  const [{ isDragging }, dragRef] = useDrag({
    type: section,
    item: { index, section },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: section,
    hover: (item) => {
      const draggedItem = item as { index: number; section: string };
      if (draggedItem.index !== index) {
        moveMetric(draggedItem.index, index, draggedItem.section);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      style={{ pointerEvents: excluded ? 'none' : undefined }}
      className={`${styles.metricItem} ${isDragging ? styles.dragging : ''}`}
    >
      <span>
        <span style={{ paddingRight: '.5rem' }}>
          <DragAndDropIcon />
        </span>
        {t(`PRIVATE.USER.ADS_DNA.${metric}`)}
      </span>
      {!excluded && <CloseIcon onClick={removeMetric} />}
    </div>
  );
};

export default function CustomizeColumnsModal({
  columns,
  selectedMetaEvents,
  selectedTikTokEvents,
  onHide,
  onDone,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [doUpdateDataColumns] = useMutation<{ updateDataColumns?: User }>(updateDataColumns);

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumns>(() => {
    return categories(t).reduce((acc, category) => {
      const filteredColumns = category.columns.filter((col) => columns.includes(col));
      acc[category.name] = filteredColumns.sort((a, b) => columns.indexOf(a) - columns.indexOf(b));
      return acc;
    }, {} as SelectedColumns);
  });

  const [searchTerm, setSearchTerm] = useState<string>('');

  const groupedCategories = useMemo(() => {
    return categories(t).reduce((acc, category) => {
      acc[category.section] = acc[category.section] || [];
      acc[category.section].push(category);
      return acc;
    }, {} as Record<string, ReturnType<typeof categories>>);
  }, [t]);

  const [activeSection, setActiveSection] = useState<string>(Object.keys(groupedCategories)[0]);

  const [name, setName] = useState<string>('');

  const sectionCategories = useMemo(() => groupedCategories[activeSection] || [], [activeSection, groupedCategories]);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const formatMetric = useCallback((metric: AD_DNA_COLUMNS) => {
    return capitalizeFirstLetter(metric.toLowerCase().replaceAll('_', ' '));
  }, []);

  const filteredMetrics = useMemo(() => {
    return sectionCategories
      .map((category) => ({
        ...category,
        columns: category.columns.filter((metric) =>
          formatMetric(metric)?.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      }))
      .filter((category) => category.columns.length > 0);
  }, [formatMetric, searchTerm, sectionCategories]);

  const toggleMetric = useCallback((metric: AD_DNA_COLUMNS, section: string) => {
    if (metric === AD_DNA_COLUMNS.PGID) return; // PGID is mandatory

    setSelectedColumns((prev) => {
      const updatedSection = prev[section]?.includes(metric)
        ? prev[section].filter((m) => m !== metric)
        : [...prev[section], metric];

      return { ...prev, [section]: updatedSection };
    });
  }, []);

  const moveMetric = useCallback((dragIndex: number, hoverIndex: number, section: string) => {
    setSelectedColumns((prev) => {
      const updatedMetrics = [...prev[section]];

      // Prevent moving PGID
      if (updatedMetrics[dragIndex] === AD_DNA_COLUMNS.PGID || updatedMetrics[hoverIndex] === AD_DNA_COLUMNS.PGID) {
        return prev;
      }

      const [draggedItem] = updatedMetrics.splice(dragIndex, 1);
      updatedMetrics.splice(hoverIndex, 0, draggedItem);

      return { ...prev, [section]: updatedMetrics };
    });
  }, []);

  const toggleSelectAll = useCallback(() => {
    setSelectedColumns((prev) => {
      const allMetrics = categories(t).flatMap((category) => category.columns);

      // Filter out unavailable Meta and TikTok columns
      const availableMetrics = allMetrics.filter((metric) => {
        if (metric.startsWith('INSIGHTS_META_')) {
          return selectedMetaEvents.includes(metric.replace('INSIGHTS_META_', '').replace('_COST', '').toUpperCase());
        }
        if (metric.startsWith('INSIGHTS_TIKTOK_')) {
          return selectedTikTokEvents.includes(
            metric.replace('INSIGHTS_TIKTOK_', '').replace('_COST', '').toUpperCase(),
          );
        }
        return true;
      });

      // Check if all selectable metrics are already selected
      const allSelected = Object.values(prev).flat().length === availableMetrics.length;

      if (allSelected) {
        return prev;
      }

      // Select only available metrics
      return categories(t).reduce((acc, category) => {
        acc[category.name] = category.columns.filter((metric) => availableMetrics.includes(metric));
        return acc;
      }, {} as SelectedColumns);
    });
  }, [t, selectedMetaEvents, selectedTikTokEvents]);

  // const toggleSelectAll = useCallback(() => {
  //   setSelectedColumns((prev) => {
  //     const allMetrics = categories(t).flatMap((category) => category.columns);
  //     const allSelected = Object.values(prev).flat().length === allMetrics.length;

  //     if (allSelected) {
  //       return prev;
  //     }

  //     return categories(t).reduce((acc, category) => {
  //       acc[category.name] = [...category.columns];
  //       return acc;
  //     }, {} as SelectedColumns);
  //   });
  // }, [t]);

  const saveName = useCallback(() => {
    const modal = Modal.info({
      title: (
        <div style={{ color: black_0, fontSize: '1.25rem', paddingBottom: '2rem' }}>
          {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.SAVE_AS_COLUMN_PRESET')}
        </div>
      ),
      content: (
        <SaveNameContent
          t={t}
          initialValue={name}
          onSave={(value) => {
            if (value.trim()) {
              setName(value);
              modal.destroy();
            }
          }}
        />
      ),
      closable: true,
      icon: <></>,
      footer: null,
      width: '35rem',
      closeIcon: (
        <CloseOutlined
          rev={undefined}
          style={{
            color: black_0,
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = black_100)}
          onMouseLeave={(e) => (e.currentTarget.style.color = black_0)}
        />
      ),
    });
  }, [name, t]);

  const handleSubmit = useCallback(() => {
    // Retrieve the selected columns as a flat array
    const flattenedColumns: AD_DNA_COLUMNS[] = Object.values(selectedColumns).flat();
    const allMetrics = categories(t).flatMap((category) => category.columns);
    // Check if all possible columns are selected and if they are in the correct order
    const isAllSelected =
      flattenedColumns.length === allMetrics.length &&
      flattenedColumns.every((col, index) => col === allMetrics[index]);

    const columnsToSave = isAllSelected ? [] : flattenedColumns;

    doUpdateDataColumns({ variables: { name, columnNames: columnsToSave } })
      .then((result) => {
        const updatedUser = result?.data?.updateDataColumns;
        if (updatedUser) {
          showSuccessNotification(t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.SUCCESS'));
          dispatch(userLoaded(updatedUser));
          onDone(columnsToSave);
        } else {
          logger.error(`Error updating columns`);
          showErrorNotification();
        }
      })
      .catch((e) => {
        logger.error(`Error updating columns: ${e}`);
        showErrorNotification();
      });
  }, [selectedColumns, t, doUpdateDataColumns, name, showSuccessNotification, dispatch, onDone, showErrorNotification]);

  return (
    <Modal
      open={true}
      onCancel={onHide}
      className={styles.modal}
      footer={null}
      maskClosable={false}
      width={1100}
      closeIcon={
        <CloseOutlined
          rev={undefined}
          style={{
            color: black_0,
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = black_100)}
          onMouseLeave={(e) => (e.currentTarget.style.color = black_0)}
        />
      }
    >
      <div className={styles.title}>
        {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.TITLE')} {name.length ? `- ${name}` : undefined}
      </div>

      <div className={styles.body}>
        <div className={styles.sections}>
          {/* Sidebar */}
          <div className={styles.first} style={{ flexShrink: 0, position: 'sticky', top: 0, height: '100%' }}>
            {Object.keys(groupedCategories).map((section) => (
              <div key={section} className={styles.sidebarSection}>
                <div
                  className={`${styles.categoryItem} ${activeSection === section ? styles.active : ''}`}
                  onClick={() => setActiveSection(section)}
                >
                  {section}
                </div>

                {groupedCategories[section].map((category) => (
                  <div key={category.name} className={styles.categoryName}>
                    {category.name}
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/* Checkboxes */}
          <div className={styles.second}>
            <div className={styles.checkboxHeader}>
              <Input
                placeholder={t('COMMON.SEARCH')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div
                className={`${styles.allItems} ${
                  Object.values(selectedColumns).flat().length ===
                  categories(t)
                    .flatMap((category) => category.columns)
                    .filter(
                      (metric) =>
                        metric.startsWith('INSIGHTS_META_')
                          ? selectedMetaEvents.includes(
                              metric.replace('INSIGHTS_META_', '').replace('_COST', '').toUpperCase(),
                            )
                          : metric.startsWith('INSIGHTS_TIKTOK_')
                          ? selectedTikTokEvents.includes(
                              metric.replace('INSIGHTS_TIKTOK_', '').replace('_COST', '').toUpperCase(),
                            )
                          : true, // Keep all other columns
                    ).length
                    ? styles.disabled
                    : ''
                }`}
                onClick={toggleSelectAll}
              >
                {t('COMMON.SELECT_ALL')}
              </div>

              {/* <div
                className={`${styles.allItems} ${
                  Object.values(selectedColumns).flat().length ===
                  categories(t).flatMap((category) => category.columns).length
                    ? styles.disabled
                    : ''
                }`}
                onClick={toggleSelectAll}
              >
                {t('COMMON.SELECT_ALL')}
              </div> */}
            </div>

            <div className={styles.metricsTitle}>{t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.METRICS_TO_INCLUDE')}</div>

            <div className={styles.filteredMetricsWrapper}>
              {filteredMetrics.map((category) => (
                <div key={category.name} className={styles.filteredMetrics}>
                  <div className={styles.metricTitle}>{category.name}</div>

                  {category.columns.map((metric) => (
                    <div key={metric} className={styles.metricCheckboxes}>
                      <span className={styles.metricName}>{t(`PRIVATE.USER.ADS_DNA.${metric}`)}</span>
                      <Checkbox
                        checked={selectedColumns[category.name]?.includes(metric)}
                        onChange={() => toggleMetric(metric, category.name)}
                        disabled={
                          metric === AD_DNA_COLUMNS.PGID || // PGID is always disabled
                          (metric.startsWith('INSIGHTS_META_') &&
                            !selectedMetaEvents.includes(metric.replace('INSIGHTS_META_', '').replace('_COST', ''))) ||
                          (metric.startsWith('INSIGHTS_TIKTOK_') &&
                            !selectedTikTokEvents.includes(metric.replace('INSIGHTS_TIKTOK_', '').replace('_COST', '')))
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          {/* Drag & Drop */}
          <DndProvider backend={HTML5Backend}>
            <div className={styles.third}>
              <div>
                {Object.values(selectedColumns).reduce((acc, cols) => acc + cols.length, 0)}{' '}
                {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.COLUMNS_SELECTED')}
              </div>

              <div className={styles.categories}>
                {categories(t).map((category) => {
                  const filteredMetrics = selectedColumns[category.name] || [];

                  return (
                    <div key={category.name} style={{ marginBottom: '1rem' }}>
                      <div className={styles.metricItem} style={{ fontWeight: 600, cursor: 'default' }}>
                        {category.name}
                      </div>

                      {filteredMetrics.map((metric, index) => (
                        <DraggableMetric
                          key={metric}
                          metric={metric}
                          index={index}
                          section={category.name}
                          moveMetric={moveMetric}
                          removeMetric={() => toggleMetric(metric, category.name)}
                          excluded={metric === AD_DNA_COLUMNS.PGID}
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </DndProvider>
        </div>

        <div className={styles.buttons}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <Button buttonType="secondary" icon={<SaveIcon />} onClick={saveName}>
              {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.SAVE_COLUMN_PRESET')}
            </Button>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Button buttonType="secondary" onClick={onHide}>
              {t('COMMON.CANCEL')}
            </Button>
            <Button buttonType="primary" type="button" onClick={handleSubmit} disabled={!name.length}>
              {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.CTA')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
